import { Box, CardActionArea, CardContent, IconButton, Button as MUIBUTTON, Typography } from '@mui/material';
import { css, styled } from '@mui/system';
interface StyledCardProps {
    dir?: string;
    multiple?: boolean;
}
export const Label = styled(Typography)`
    font-size: 14px;
    margin-bottom: 0px;
    text-align: center;
    ${({ theme: { palette } }: any) => {
        return css`
            color: ${palette.secondary.main};
        `;
    }};
`;
export const Row = styled(Box)`
    display: flex;
    justify-content: center;
    &:last-child {
        width: 100%;
        min-width: 100%;
    }
`;
export const Title = styled(Typography)`
    font-weight: 600;
    position: relative;
    font-size: 16px;
    margin-bottom: 0px;
    ${({ theme: { palette } }: any) => {
        return css`
            color: ${palette.primary.main};
        `;
    }};
    width: 100%;
`;
export const Body = styled(CardActionArea)`
    overflow: hidden;
    display: flex;
    flex-direction: row;
`;
export const Content = styled(CardContent)`
    width: 100%;
    box-sizing: border-box;
    transition: 0.1s all ease-in;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    justify-content: center;
    svg {
        height: 20px;
    }
`;
export const StyledCard = styled(Box) <StyledCardProps>`
    ${(props) => {
        const { dir } = props;
        return css`
            box-sizing: border-box;
            box-shadow: 0px 4px 7px 2px rgba(0, 0, 0, 0.2);
            max-width: 100%;
            max-width: 360px;
            transition: 0.2s all ease;
            position: relative;
            height: 100%;
            display: block;
            margin: 12px auto 0;
            text-decoration: none;
            border: 2px solid #579b3f;
            padding: 12px 12px 0;
            border-radius: 12px;
            button {
                flex-direction: column;
                height: 100%;
                align-items: flex-start;
                justify-content: flex-start;
                bottom: 0;
                &:hover .MuiCardActionArea-focusHighlight.css-1v2exvi-MuiCardActionArea-focusHighlight {
                    display: none;
                }
            }
            svg {
                transform: rotate(${dir === 'rtl' ? '180deg' : '0'});
            }
            .mr-3 {
                margin: ${dir === 'rtl' ? '0 12px 0 0' : '0 0 0 12px'};
            }
            .mr-2 {
                margin: ${dir === 'rtl' ? '0 6px 0 0' : '0 0 0 6px'};
            }
        `;
    }}
`;

export const StyledCard2 = styled(Box) <StyledCardProps>`
    ${(props) => {
        const { multiple } = props;
        // only add width if there are multiple cards
        return css`
            display: ${multiple ? 'flex' : 'inline-flex'};
            width: ${multiple ? '312px' : 'auto'};
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            border-radius: 8px;
            border: 1px solid #DEDEDE;
            background: #FFF;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
           
        `;
    }}
`;

export const TitleBox = styled(Box) <{ multiple: boolean }>`
${(props) => {
        const { multiple } = props;
        return css`
            display: flex;
            width: ${multiple ? 'auto' : '303px'};
            height: 40px;
            padding: 10px 12px 10px 6px;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
            align-self: stretch;

            border-radius: 8px;
            background: #F2F7F1;
`
    }}`

export const TitlePic = styled(Box) <{ background: string }>`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 4px;
  background-image: url(${(props) => props.background});
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
`;

export const TitleText = styled(Typography)`
    flex: 1 0 0;
    color: #1E1F1E;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 191.667% */
`

export const TitleText2 = styled(Typography)`
    color: #1E1F1E;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 191.667% */
`

export const RowBoxDuration = styled(Box) <{ multiple: boolean }>`
${(props) => {
        const { multiple } = props;
        return css`
            display: flex;
            width: ${multiple ? 'auto' : '303px'};
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

`}}`

export const RowBoxDurationTitle = styled(Typography)`
    color: #898989;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 192.857% */
`


export const RowBoxDurationValue = styled(Typography)`
    color: #1E1F1E;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 192.857% */
`


export const RowBoxAction = styled(Box)`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;


`

export const RowBoxMeal = styled(MUIBUTTON) <{ multiple: boolean }>`
${(props) => {
        const { multiple } = props;
        return css`
            display: flex;
            width: ${multiple ? 'auto' : '239px'};
            height: 44px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            flex: 1 0 0;
            gap: 4px;
            border-radius: 8px;
            background: #579B3F;
            color: #fff;
            text-transform: capitalize;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
`}}`

export const RowBoxRenew = styled(IconButton)`
    display: flex;
    width: 52px;
    height: 44px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: #F2F7F1;

`


export const Button = styled(Typography) <{ component: string }>`
    position: relative;
    ${({ theme: { palette } }) => {
        return css`
            background: ${palette.primary.main};
            color: #fff;
            border: 2px solid ${palette.primary.main};
        `;
    }};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 8px;
    text-align: center;
    padding: 8px;
    display: block;
    margin: 0;
`;


export const HorizontalScrollBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    marginBottom: '16px',
    scrollbarWidth: 'none', /* For Firefox */
    '&::-webkit-scrollbar': {
        display: 'none', /* For Chrome, Safari, and Opera */
    },
    '-ms-overflow-style': 'none', /* For Internet Explorer and Edge */
    '& > *': {
        flex: '0 0 auto', // Prevent flex items from shrinking
        marginRight: '8px', // Manage spacing between cards
    },
    '& > :last-child': {
        marginRight: '0', // Remove right margin for the last card
    },
});

export const HorizontalScrollBoxCheckout = styled(Box) <{ isDates?: boolean }>`
  display: flex;
  overflow-x: scroll;
  margin-bottom: 12px;
  padding-bottom: 4px;

  ${({ theme, isDates }) => css`
    flex-direction: row;

    & > * {
    
      flex: ${isDates ? '0 0 auto' : '1'};
      margin-right: ${theme.direction === 'rtl' ? '8px' : '8px'};
      margin-left: ${theme.direction === 'rtl' ? '0' : '0'};
    }

    & > :first-of-type {
      margin-right: ${theme.direction === 'rtl' ? '0' : '8px'};
      margin-left: ${theme.direction === 'rtl' ? '0px' : '0'};
    }

    & > :last-child {
      margin-left: ${theme.direction === 'rtl' ? '0' : '0'};
      margin-right: ${theme.direction === 'rtl' ? '8px' : '0'};
    }

    &::-webkit-scrollbar {
      height: 4px;
      margin-top: 10px;
    }


    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a0a0a0;
      border-radius: 4px;
    }

    &::-webkit-overflow-scrolling: auto

  `};
`;





// export const HorizontalScrollBox = styled(Box)({
//     display: 'flex',
//     flexDirection: 'row',
//     overflowX: 'auto',
//     paddingRight: '32px', // Adjust this value to ensure part of the next card is visible
//     scrollbarWidth: 'none', // Hide scrollbar for Firefox
//     '&::-webkit-scrollbar': {
//         display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
//     },
//     '-ms-overflow-style': 'none', // Hide scrollbar for Internet Explorer and Edge
//     '& > *': {
//         marginRight: '12px', // Space between cards, as per Figma
//     },
//     '& > :last-child': {
//         marginRight: '0', // No right margin for the last card to maintain the padding of the container
//     },
// });