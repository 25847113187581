/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import placeHolderRestaurantLogo from "../../assets/illustrations/placeholder_restaurant_logo.png";

import { useNavigate } from "react-router-dom";

// Custom Styles Imports
import { addDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// Components

// Hooks and Utils
import { useIsAuthenticated } from "../../hooks";

// Store
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { executePaymentRequest } from "../../store/slices/payments";
import { getRestaurantPlan } from "../../store/slices/restaurants";
import { localStorageGet, localStorageSet } from "../../utils/localStorage";

// Types
import { ExecutePaymentRequestData } from "../../types/payments";

import { DeliveryTime, Package } from "../../types/restaurant";

// Loading Button

import dayjs from "dayjs";
import "dayjs/locale/ar"; // Import the Arabic locale

import { DateTime } from "luxon";
import { ReactComponent as NoteIcon } from "../../assets/icons/note_icon.svg";

import { Layout } from "../../layout/Layout2";

import { convertArabicToWestern } from "../../utils";
import isApplePaySupported from "../../utils/isApplePaySupported";

import { LoadingButton } from "@mui/lab";
import { ReactComponent as ApplePayIcon } from "../../assets/icons/apple_pay.svg";

import { ReactComponent as KnetPayIcon } from "../../assets/icons/knet_pay.svg";

import { ReactComponent as GooglePayIcon } from "../../assets/icons/google_pay_icon.svg";

import isBetween from "dayjs/plugin/isBetween";
import PAY_WITH_APPLE_PAY from "../../assets/icons/Apple_Pay_Btn.svg";
import PAY_WITH_GOOGLE_PAY from "../../assets/icons/Google_Pay_Btn.svg";
import calculateSubscriptionEndDate from "../../utils/calculateSubscriptionEndDate";
dayjs.extend(isBetween);

interface CheckoutFormProps {
  slug: string | undefined;
  isUserAreaVerified: boolean;
}

const days = [
  {
    id: 1,
    day: "Sunday",
    day_arabic: "الأحد",
    slug: "Sun",
  },
  {
    id: 2,
    day: "Monday",
    day_arabic: "الاثنين",
    slug: "Mon",
  },
  {
    id: 3,
    day: "Tuesday",
    day_arabic: "الثلاثاء",
    slug: "Tue",
  },
  {
    id: 4,
    day: "Wednesday",
    day_arabic: "الأربعاء",
    slug: "Wed",
  },
  {
    id: 5,
    day: "Thursday",
    day_arabic: "الخميس",
    slug: "Thu",
  },
  {
    id: 6,
    day: "Friday",
    day_arabic: "الجمعة",
    slug: "Fri",
  },
  {
    id: 7,
    day: "Saturday",
    day_arabic: "السبت",
    slug: "Sat",
  },
];

const PAYMENT_METHODS_HASH_MAP: { [key: string]: string } = {
  knet: "KNET",
  applepay: "APPLE PAY",
  googlepay: "GOOGLE PAY",
};

const BUTTON_ID = "00001";

const kuwaitDateTime = DateTime.now().setZone("Asia/Kuwait").toJSDate();
const todayDateInKuwait = kuwaitDateTime.toDateString();
const today = new Date(todayDateInKuwait);

const defaultDate = addDays(today, 3);

const formatTime = (time: string, isArabic: boolean): string => {
  const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));
  const date = dayjs().hour(hours).minute(minutes);
  const formatString = "h:mm A";

  if (isArabic) {
    // Format the time in English to get the AM/PM part
    const formattedTime = date.format(formatString);
    const [timePart, amPmPart] = formattedTime.split(" ");
    // Translate the AM/PM part to Arabic
    const amPmArabic = amPmPart === "AM" ? "ص" : "م";
    // Return the Arabic time with the translated AM/PM
    return `${timePart} ${amPmArabic}`;
  } else {
    // Format the time in English
    return date.format(formatString);
  }
};

const formatTimeWithSpan = (time: string, isArabic: boolean) => {
  const formattedTime = formatTime(time, isArabic);
  const [timePart, amPmPart] = formattedTime.split(" ");

  // Render the time with the AM/PM part (or its Arabic equivalent) in a span with bold weight
  return (
    <Typography
      sx={{
        color: "#556885",
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "160%",
        letterSpacing: "-0.28px",
      }}
    >
      {timePart}
      {amPmPart}
    </Typography>
  );
};

// Function to render a single delivery time slot
const renderDeliveryTimeSlot = (time: DeliveryTime, isArabic: boolean) => {
  if (!time) return "";
  const { start_time, end_time } = time;

  if (!start_time || !end_time) return "";
  const formattedStartTime = formatTimeWithSpan(start_time, isArabic);

  const formattedEndTime = formatTimeWithSpan(end_time, isArabic);

  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
      {formattedStartTime} {" - "} {formattedEndTime}
    </div>
  );
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  slug,
  isUserAreaVerified,
}) => {
  const user = useIsAuthenticated();
  const navigate = useNavigate();
  const { userData } = useAppSelector((state) => state.accounts);
  const checkoutDataFromLocalStorage = localStorageGet("checkoutData");
  const [focused, setFocused] = useState(false);

  const { i18n, t } = useTranslation();
  const isArabic = i18n.language === "ar";

  const currentURL = window.location.href;
  const currentDomain = currentURL.split("/")[2];

  const dispatch = useAppDispatch();

  const {
    planDetail,
    currentPackageIDInMenue,
    isLoading: restaurantStateLoading,
  } = useAppSelector((state) => state.restaurants);

  const [error, setError] = useState(false);

  const [notes, setNotes] = useState<string>(
    slug && planDetail?.plan.checkout_notes
      ? planDetail.plan.checkout_notes
      : checkoutDataFromLocalStorage
      ? checkoutDataFromLocalStorage.checkoutNotes
      : ""
  );

  const best_matched_package_id =
    checkoutDataFromLocalStorage.best_matched_package_id;

  const {
    initiateSessionLoading,
    executePaymentLoading,
    executePaymentResponse,
    paymentMethods,
    paymentMethodsLoading,
    executePaymentError,
  } = useAppSelector((state) => state.payments);

  // Payment Method Default Selection Logic
  // Step: 1/3 - Determine if user has preference about SelectedOption in Localstorage
  const selectedPaymentOptionFromLocalStorage =
    checkoutDataFromLocalStorage?.selectedPaymentOption || null;
  const isApplePay = isApplePaySupported();

  const [selectedOption, setSelectedOption] = useState<
    "applepay" | "googlepay" | "knet" | null
  >(selectedPaymentOptionFromLocalStorage);

  useEffect(() => {
    // Step: 2/3 Evaluate if selectedPaymentOptionFromLocalStorage is still a valid payment method
    const selectedPaymentOptionVal =
      PAYMENT_METHODS_HASH_MAP[selectedPaymentOptionFromLocalStorage];
    if (
      selectedPaymentOptionFromLocalStorage &&
      paymentMethods?.includes(selectedPaymentOptionVal)
    ) {
      setSelectedOption(selectedPaymentOptionFromLocalStorage);
    } else {
      const supportedMethods = paymentMethods?.map((method) =>
        method.toLowerCase()
      );

      // Step: 3/3 Pick the one by default using this Priority Order
      if (supportedMethods?.includes("apple pay") && isApplePay) {
        setSelectedOption("applepay");
      } else if (supportedMethods?.includes("knet")) {
        setSelectedOption("knet");
      } else if (supportedMethods?.includes("google pay") && !isApplePay) {
        setSelectedOption("googlepay");
      }
    }
  }, [paymentMethods, selectedPaymentOptionFromLocalStorage, isApplePay]);

  // Default Package Selection
  const [value, setValue] = useState<string>(() => {
    if (slug && planDetail?.plan?.best_matched_package_id) {
      return planDetail?.plan?.best_matched_package_id?.toString();
    } else if (currentPackageIDInMenue === 0 && best_matched_package_id) {
      return best_matched_package_id.toString();
    } else if (currentPackageIDInMenue) {
      return currentPackageIDInMenue.toString();
    } else {
      // First Available Package ID
      return planDetail?.plan.packages[0].id.toString();
    }
  });

  const restaurantName = isArabic
    ? planDetail?.plan?.restaurant_name_arabic
    : planDetail?.plan?.restaurant_name;
  const restaurantSlug = planDetail?.plan?.restaurant_slug;
  const planName = isArabic
    ? planDetail?.plan?.title_arabic
    : planDetail?.plan?.title;
  const packages = planDetail?.plan?.packages;

  const currentPackage = packages?.find(
    (item: Package) => item.id === parseInt(value)
  );
  // For BE compatibility executeDataOffDays should not have spaces while join
  const executeDataOffDays = currentPackage?.off_days
    .map((item) => item.day)
    .join(",");
  const off_days_calendar = currentPackage?.off_days.map((item) => item.day); // Array of day slugs ["Sun", "Mon"]
  const off_days_arabic = currentPackage?.off_days
    .map((item) => days.find((slug) => slug.slug === item.day)?.day_arabic)
    .join(", ");
  const off_days_full = currentPackage?.off_days
    .map((item) => days.find((slug) => slug.slug === item.day)?.day)
    .join(", ");

  const isOffDay = (date: Date) => {
    const daySlug = days[date.getDay()]?.slug; // Assuming 'days' is an array that maps day indices (0-6) to their slugs
    return off_days_calendar?.includes(daySlug);
  };

  const findNextAvailableDay = (date: Date): Date => {
    let nextAvailableDate = new Date(date);

    while (
      isOffDay(nextAvailableDate) ||
      dayjs(nextAvailableDate)
        .startOf("day")
        .isBetween(dayjs(today), dayjs(today).add(3, "day"))
    ) {
      nextAvailableDate = addDays(nextAvailableDate, 1); // Add a day and check again
    }
    return nextAvailableDate;
  };

  const initialDeliveryTimes =
    slug && planDetail?.plan?.deliverytimes
      ? planDetail.plan.deliverytimes
      : planDetail?.deliverytimes
      ? planDetail.deliverytimes
      : [];

  const [deliveryTimes, setDeliveryTimes] =
    useState<DeliveryTime[]>(initialDeliveryTimes);

  useEffect(() => {
    if (slug && planDetail?.plan?.deliverytimes) {
      setDeliveryTimes(planDetail.plan.deliverytimes);
    } else if (planDetail?.deliverytimes) {
      setDeliveryTimes(planDetail.deliverytimes);
    }
  }, [slug, planDetail]);

  const deliveryTimeFromLocalStorage =
    checkoutDataFromLocalStorage?.deliveryTime;

  const [selectedTime, setSelectedTime] = useState<number | null>(() => {
    if (!slug && deliveryTimeFromLocalStorage) {
      return deliveryTimeFromLocalStorage.id;
    } else {
      return deliveryTimes.length > 0 ? deliveryTimes[0].id : null;
    }
  });

  useEffect(() => {
    const determineSelectedTime = () => {
      if (slug && planDetail?.plan?.delivery_time) {
        const matched = deliveryTimes.find(
          (m) =>
            planDetail.plan.delivery_time.start_time === m.start_time &&
            planDetail.plan.delivery_time.end_time === m.end_time
        );
        return matched ? Number(matched.id) : deliveryTimes[0]?.id ?? null;
      } else if (slug) {
        return deliveryTimes[0]?.id ?? null;
      } else if (deliveryTimeFromLocalStorage) {
        return deliveryTimeFromLocalStorage.id;
      } else {
        return deliveryTimes[0]?.id ?? null;
      }
    };

    const newSelectedTime = determineSelectedTime();
    if (newSelectedTime !== selectedTime) {
      setSelectedTime(newSelectedTime);
    }
  }, [deliveryTimes]);

  useEffect(() => {
    if (slug && planDetail?.plan?.best_matched_package_id) {
      setValue(planDetail?.plan?.best_matched_package_id?.toString());
    }
    if (slug && planDetail?.plan?.checkout_notes) {
      setNotes(planDetail?.plan?.checkout_notes);
    }
  }, [
    slug,
    planDetail?.plan?.best_matched_package_id,
    planDetail?.plan?.checkout_notes,
  ]);

  useEffect(() => {
    if (!planDetail && !restaurantStateLoading) {
      // check if checkoutData is in localStorage
      const restaurant_slug = localStorage.getItem("restaurant_slug");
      const plan_id = localStorage.getItem("plan_id");
      if (restaurant_slug && plan_id) {
        dispatch(
          getRestaurantPlan(
            restaurant_slug as string,
            plan_id as string,
            userData?.token.access
          )
        );
      }
    }
  }, [userData]);

  const latestEndDate = userData?.customer?.latest_end_date
    ? addDays(new Date(userData?.customer?.latest_end_date), 1)
    : undefined;
  const startDateObject = latestEndDate || defaultDate;

  const [selectedDay, setSelectedDay] = useState<Date>(
    findNextAvailableDay(startDateObject)
  );

  const selectedDeliveryTime = deliveryTimes.find(
    (time) => time.id === selectedTime
  );

  useEffect(() => {
    if (value) {
      const adjustedStartDate = findNextAvailableDay(startDateObject);
      setSelectedDay(adjustedStartDate);
    }
  }, [value, userData, planDetail]);

  const handleNotesChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value.length <= 200) {
      setNotes(convertArabicToWestern(value));
    }
  };

  const handlePaymentChange = (
    paymentMethod: "applepay" | "googlepay" | "knet"
  ) => {
    setSelectedOption(paymentMethod);
    // set it in localStorage as well
    localStorageSet("checkoutData", {
      ...checkoutDataFromLocalStorage,
      selectedPaymentOption: paymentMethod,
    });
  };

  const checkoutPageViewID = sessionStorage.getItem("checkoutPageViewID");
  const executeRequestPaymentData: ExecutePaymentRequestData = {
    restaurant_slug: slug ? slug : (restaurantSlug as string),
    package: {
      period: currentPackage?.period as number,
      off_days: `${executeDataOffDays ? executeDataOffDays : ""}`,
      price: `${currentPackage?.price as string}`,
      discounted_price: currentPackage?.discounted_price
        ? (currentPackage?.discounted_price as string)
        : null,
      package_id: currentPackage?.id as number,
    },
    plan: {
      title: `${planDetail?.plan?.title}`,
      title_arabic: `${planDetail?.plan?.title_arabic}`,
      plan_id: planDetail?.plan?.id as number,
    },
    delivery_time: {
      id: selectedDeliveryTime?.id as number,
      start_time: selectedDeliveryTime?.start_time as string,
      end_time: selectedDeliveryTime?.end_time as string,
    },
    checkout_notes: notes,
    checkout_page_view_id: checkoutPageViewID,
    start_date: format(selectedDay, "yyyy-MM-dd"),
    payment_method: selectedOption as string,
    call_back_url: `https://${currentDomain}/payment-success`,
    error_url: `https://${currentDomain}/payment-error`,
  };

  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const handlePay = async () => {
    setIsRedirecting(true);

    localStorageSet("executeRequestPaymentData", executeRequestPaymentData);
    await dispatch(
      executePaymentRequest(
        user?.token?.access as string,
        executeRequestPaymentData
      )
    );
  };

  useEffect(() => {
    const handlePageShow = () => setIsRedirecting(false);

    window.addEventListener("pageshow", handlePageShow);

    return () => removeEventListener("pageshow", handlePageShow);
  }, []);

  useEffect(() => {
    if (executePaymentError) {
      setIsRedirecting(false);
    }
  }, [executePaymentError]);

  useEffect(() => {
    if (executePaymentResponse) {
      window.location.href = executePaymentResponse?.payment_url as string;
    }
  }, [executePaymentResponse]);

  const checkoutData = {
    currentPackageIDInMenue: value,
    restaurantName: restaurantName,
    restaurantSlug: restaurantSlug,
    planName: planName,
    planID: planDetail?.plan?.id,
    startDate: format(selectedDay, "yyyy-MM-dd"),
    selectedPaymentOption: selectedOption,
    checkoutNotes: notes,
    best_matched_package_id: value,
    deliveryTime: selectedDeliveryTime,
  };

  useEffect(() => {
    localStorageSet("checkoutData", checkoutData);
  }, [checkoutData]);

  const generateDays = () => {
    const days = [];
    for (let i = 0; i < 30; i++) {
      days.push(addDays(findNextAvailableDay(startDateObject), i));
    }
    return days;
  };

  const daysToShow = generateDays();

  // Handler for day selection
  const handleDaySelect = (selectedDate: Date) => {
    if (off_days_calendar?.includes(days[selectedDate.getDay()].slug)) return;
    setSelectedDay(selectedDate);
  };

  useEffect(() => {
    if (value) {
      const matchedPackage = packages?.find(
        (item) => item.id === parseInt(value)
      );
      if (matchedPackage) {
        handleDaySelect(findNextAvailableDay(selectedDay));
      }
    }
  }, [value]);

  const endDate = calculateSubscriptionEndDate(
    selectedDay,
    currentPackage?.period as number,
    off_days_calendar as string[]
  );

  const logo = planDetail?.plan.restaurant_logo || placeHolderRestaurantLogo;

  useEffect(() => {
    if (!isUserAreaVerified) {
      navigate("/area-verification-error");
    }
  }, [isUserAreaVerified]);

  const requestingPaymentOrRedirecting = executePaymentLoading || isRedirecting;

  let buttonBackgroundImage = "";
  if (selectedOption === "applepay") {
    buttonBackgroundImage = PAY_WITH_APPLE_PAY;
  } else if (selectedOption === "googlepay") {
    buttonBackgroundImage = PAY_WITH_GOOGLE_PAY;
  }
  return (
    <Layout
      title={t("CHECKOUT")}
      isLoading={
        initiateSessionLoading ||
        restaurantStateLoading ||
        paymentMethodsLoading
      }
      subTitle={`${planName || ""} - ${restaurantName || ""}`}
      restaurantLogo={logo}
      footer={
        <Paper
          sx={{
            backgroundColor: "#ffffff",
            width: "100vw", // Subtracting 24px padding from each side
            padding: "12px 24px 16px 24px",
            display: "flex",
            justifyContent: "center",
            zIndex: 1000,
            border: "none",
            borderRadius: "0 !important",
            boxShadow:
              "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
          }}
        >
          {selectedOption === "knet" && (
            <LoadingButton
              id={BUTTON_ID}
              color="primary"
              type="submit"
              fullWidth
              disabled={requestingPaymentOrRedirecting}
              loading={requestingPaymentOrRedirecting}
              onClick={handlePay}
              variant="contained"
              size="large"
              sx={{
                padding: "16px auto",
                textAlign: "center",
                height: "var(--7, 56px)",
                borderRadius: "var(--6, 48px)",
                backgroundColor: `#10a67b`,
                fontSize: "17px",
                fontWeight: 700,
                letterSpacing: "0.2px",
                maxWidth: "380px",
              }}
            >
              {t("PAYMENT_SUBSCRIBE")}
            </LoadingButton>
          )}

          {selectedOption != "knet" && (
            <LoadingButton
              id={BUTTON_ID}
              disabled={requestingPaymentOrRedirecting}
              loading={requestingPaymentOrRedirecting}
              onClick={handlePay}
              disableRipple
              disableFocusRipple
              disableTouchRipple
              disableElevation
              variant="contained"
              sx={{
                borderRadius: "10px",
                minWidth: "320px",
                maxWidth: "380px",
                height: "56px",
                backgroundColor: "transparent",
                backgroundImage: requestingPaymentOrRedirecting
                  ? "none"
                  : `url(${buttonBackgroundImage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                "&:active": {
                  backgroundColor: "transparent",
                },
              }}
            ></LoadingButton>
          )}
        </Paper>
      }
    >
      <Box dir={i18n.dir()}>
        <Helmet>
          <title>{t("HOME_META_TITLE")}</title>
          <meta name="description" content={t("HOME_META_DESCRIPTION") || ""} />
        </Helmet>
        <Card
          sx={{
            backgroundColor: "#FFF",
            borderRadius: "16px",
            height: "122px",
            paddingX: "16px",
          }}
        >
          <CardHeader
            title={
              <Stack direction="row" spacing="5px">
                <Typography color="#272D2D" fontSize="16px" fontWeight={700}>
                  {t("SUBSCRIPTION_START_DAYS")}
                </Typography>{" "}
                <Typography color="#556885" fontSize="14px">
                  ({t("DELIVERY_DAY")})
                </Typography>
              </Stack>
            }
          />
          <Box
            sx={{
              display: "flex",
              gap: "1px",
              flexDirection: "row",
              overflowX: "scroll",
              overflowY: "hidden",
            }}
          >
            {daysToShow.map((day, index) => {
              const formattedDaySlug = format(day, "EEE");
              const formattedDay = isArabic
                ? dayjs(day).locale("ar").format("dddd")
                : format(day, "EEE");
              const formattedDate = isArabic
                ? dayjs(day).locale("ar").format("DD")
                : format(day, "dd");
              const disabled = Boolean(
                off_days_calendar?.includes(formattedDaySlug)
              );

              return (
                <Box
                  sx={{ marginTop: "20px" }}
                  onClick={() => handleDaySelect(day)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "46px",
                      height: "54px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "11px",
                      flexShrink: 0,
                      cursor: disabled ? "not-allowed" : "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        width: "28px",
                        height: "28px",
                        borderRadius: "60%",
                        backgroundColor:
                          selectedDay.toDateString() === day.toDateString()
                            ? "#10A67B"
                            : disabled
                            ? "#EDF0F3"
                            : "#D8EEE9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            selectedDay.toDateString() === day.toDateString()
                              ? "#FFFFFF"
                              : disabled
                              ? "#556885"
                              : "#10A67B",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {formattedDate}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "46px",
                        height: "15px",
                        flexDirection: "column",
                        justifyContent: "center",
                        flexShrink: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            selectedDay.toDateString() === day.toDateString()
                              ? "#272D2D"
                              : "#556885",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight:
                            selectedDay.toDateString() === day.toDateString()
                              ? 700
                              : 400,
                        }}
                      >
                        {formattedDay}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Card>

        <Card
          sx={{
            backgroundColor: "#FFF",
            borderRadius: "16px",
            height: "154px",
            paddingX: "16px",
            marginTop: "14px",
          }}
        >
          <Grid
            container
            columnSpacing={1}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={6}>
              <CardHeader title={t("PACKAGE")} />
              <Select
                fullWidth
                onClick={(e) => e.stopPropagation()}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                sx={(theme) => ({
                  marginTop: "6px",
                  color: theme.palette.text.secondary,
                })}
                renderValue={() => {
                  const selectedPackage = packages?.find(
                    (pack) => pack.id === parseInt(value)
                  );
                  return (
                    <Typography
                      sx={{
                        color: "#556885",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "160%",
                        letterSpacing: "-0.28px",
                      }}
                    >
                      {selectedPackage?.period} {t("DAY")}
                    </Typography>
                  );
                }}
              >
                {packages?.map((pack: Package) => (
                  <MenuItem value={pack.id}>
                    {pack.period} {t("DAY")}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <CardHeader title={t("DELIVERY_TIME")} />
              <Select
                fullWidth
                onClick={(e) => e.stopPropagation()}
                value={selectedTime || ""}
                onChange={(e) => setSelectedTime(Number(e.target.value))}
                sx={(theme) => ({
                  marginTop: "6px",
                  color: theme.palette.text.secondary,
                })}
                renderValue={() => {
                  const selectedtime =
                    deliveryTimes.find(
                      (timeRange) => selectedTime === timeRange.id
                    ) || deliveryTimes[0];
                  return renderDeliveryTimeSlot(selectedtime, isArabic);
                }}
              >
                {deliveryTimes.map((timeRange: DeliveryTime) => (
                  <MenuItem key={timeRange.id} value={timeRange.id}>
                    {renderDeliveryTimeSlot(timeRange, isArabic)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container columnSpacing="1px" rowGap="8px" mt="12px">
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#556885",
                  fontSize: "12px",
                  fontWeight: 700,
                  lineHeight: "160%",
                  letterSpacing: "-0.24px",
                }}
              >
                {t("SUBSCRIPTION_END_DATE")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {endDate && (
                <Typography
                  sx={{
                    color: "#556885",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "160%",
                    letterSpacing: "-0.24px",
                    textAlign: "right",
                  }}
                >
                  {isArabic
                    ? dayjs(endDate).locale("ar").format("DD MMM")
                    : format(endDate, "MMM dd")}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#556885",
                  fontSize: "12px",
                  fontWeight: 700,
                  lineHeight: "160%",
                  letterSpacing: "-0.24px",
                }}
              >
                {t("OFF_DAYS")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#556885",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "160%",
                  letterSpacing: "-0.24px",
                  textAlign: "right",
                }}
              >
                {off_days_full
                  ? isArabic
                    ? off_days_arabic
                    : off_days_full
                  : t("WITHOUT_OFF")}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        {paymentMethods && (
          <Card
            sx={{
              backgroundColor: "#FFF",
              borderRadius: "16px",
              height: "122px",
              paddingX: "16px",
              marginTop: "14px",
            }}
          >
            <CardHeader title={t("PAYMENT_METHOD")} />

            <Grid container columnSpacing="8px" mt="12px">
              {isApplePay && paymentMethods?.includes("APPLE PAY") && (
                <Grid item flex={1}>
                  <Box
                    onClick={() => handlePaymentChange("applepay")}
                    sx={{
                      backgroundColor: "#F4F6F9",
                      height: "56px",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "8px",
                      padding: "3px",
                      justifyContent: "space-between",
                      paddingLeft: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        fontSize="12px"
                        fontWeight={700}
                        color={
                          selectedOption === "applepay" ? "#10A67B" : "#516480"
                        }
                      >
                        Apple Pay
                      </Typography>
                      <Radio
                        sx={{ p: 0, pt: "5px" }}
                        checked={selectedOption === "applepay"}
                        onChange={() => handlePaymentChange("applepay")}
                      />
                    </div>
                    <ApplePayIcon style={{ width: "63px", height: "50px" }} />
                  </Box>
                </Grid>
              )}
              {paymentMethods?.includes("KNET") && (
                <Grid item flex={1}>
                  <Box
                    onClick={() => handlePaymentChange("knet")}
                    sx={{
                      backgroundColor: "#F4F6F9",
                      height: "56px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "8px",
                      padding: "3px",
                      paddingLeft: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        fontSize="12px"
                        fontWeight={700}
                        color={
                          selectedOption === "knet" ? "#10A67B" : "#516480"
                        }
                      >
                        K-net
                      </Typography>
                      <Radio
                        sx={{ p: 0, pt: "5px" }}
                        checked={selectedOption === "knet"}
                        onChange={() => handlePaymentChange("knet")}
                      />
                    </div>
                    <KnetPayIcon
                      style={{
                        width: "38px",
                        height: "34px",
                      }}
                    />
                  </Box>
                </Grid>
              )}

              {!isApplePay && paymentMethods?.includes("GOOGLE PAY") && (
                <Grid item flex={1}>
                  <Box
                    onClick={() => handlePaymentChange("googlepay")}
                    sx={{
                      backgroundColor: "#F4F6F9",
                      height: "56px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "8px",
                      padding: "3px",
                      paddingLeft: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        fontSize="12px"
                        fontWeight={700}
                        color={
                          selectedOption === "googlepay" ? "#10A67B" : "#516480"
                        }
                      >
                        Google Pay
                      </Typography>
                      <Radio
                        sx={{ p: 0, pt: "5px" }}
                        checked={selectedOption === "googlepay"}
                        onChange={() => handlePaymentChange("googlepay")}
                      />
                    </div>
                    <GooglePayIcon
                      style={{
                        width: "61px",
                        height: "50px",
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Card>
        )}
        <TextField
          variant="outlined"
          fullWidth
          label={`${t("ADD_NOTE")} ${!notes && !focused ? "..." : ""}`}
          value={notes}
          onChange={handleNotesChange}
          helperText={error ? "Note cannot exceed 200 characters" : ""}
          error={!!error}
          multiline
          maxRows={1}
          sx={{
            marginTop: "14px",
            marginBottom: "23px",
            borderRadius: "8px",
          }}
          inputProps={{
            style: { fontSize: "16px", color: "#556885", fontWeight: 400 },
          }}
          InputLabelProps={{
            variant: "outlined",
            style: {
              padding:
                !notes && !focused
                  ? isArabic
                    ? "3px 28px 0px 0px"
                    : "3px 0px 0px 28px"
                  : "0px",
            },
            shrink: Boolean(notes) || focused, // This will shrink the label only if there is no text in the TextField
          }}
          InputProps={{
            onFocus: () => setFocused(true), // Add onFocus event to set focused state to true
            onBlur: () => setFocused(false), // Add onBlur event to set focused state to false
            startAdornment: (
              <InputAdornment position="start">
                <NoteIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* <TextField
          variant="outlined"
          fullWidth
          label={t("ADD_NOTE")}
          value={notes}
          onChange={handleNotesChange}
          helperText={error ? "Note cannot exceed 200 characters" : ""}
          error={!!error}
          sx={{
            marginTop: "14px",
            marginBottom: "23px",
            borderRadius: "8px",
          }}
          inputProps={{
            style: { fontSize: "16px", color: "#556885", fontWeight: 400 },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <NoteIcon />
              </InputAdornment>
            ),
          }}
        /> */}

        <Divider
          sx={{
            borderStyle: "dashed",
            color: "#C4C9D6",
            fontWeight: 700,
            borderWidth: "2px",
          }}
        />

        <Grid
          container
          mt="13px"
          pb="27px"
          columnSpacing={1}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={6}>
            <Typography
              sx={{
                color: "#272D2D",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "145%",
                letterSpacing: "-0.066px",
              }}
            >
              {t("TOTAL_PRICE")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                justifyContent: "flex-end",
              }}
            >
              {currentPackage?.discounted_price && (
                <Typography
                  sx={{
                    color: "#556885",
                    fontWeight: 400,
                    fontSize: "16px",
                    letterSpacing: "-0.066px",
                    textDecoration: "line-through",
                  }}
                >
                  ({String(parseFloat(currentPackage?.price || "0"))})
                </Typography>
              )}
              <Typography
                sx={{
                  color: "#10A67B",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "145%",
                  letterSpacing: "-0.066px",
                }}
              >
                {" "}
                {currentPackage?.discounted_price
                  ? String(parseFloat(currentPackage?.discounted_price))
                  : String(parseFloat(currentPackage?.price || "0"))}
              </Typography>
              <Typography
                sx={{
                  color: "#10A67B",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "145%",
                  letterSpacing: "-0.066px",
                }}
              >
                {t("KWD")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* make space for the footer */}
      <Box minHeight="106px">&nbsp;</Box>
    </Layout>
  );
};

export default CheckoutForm;
