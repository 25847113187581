import { styled, css } from '@mui/system'
import { AppBar, Toolbar, LinearProgress } from '@mui/material'

interface HeaderProps {
  propName?: string
}

interface LinearProgressProps {
  isArabic?: boolean
}

export const Header = styled(AppBar)<HeaderProps>`
  background: ${({ theme: { palette } }) => palette.primary.light};
  display: flex;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
`
export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  ${({ dir }) => css`
    flex-direction: ${dir === 'rtl' ? 'row' : ' row'};
  `}
`

export const Logo = styled('img')`
  height: 44px;
  width: 44px;
  border-radius: 50%;
`

// Define a custom style for the Linear Progress
export const CustomLinearProgress = styled(LinearProgress)<LinearProgressProps>`
  background-color: '#F1E9E7';
  height: 1px; // Set the height as desired
`
