import { createSlice } from "@reduxjs/toolkit";

import { format } from "date-fns";
import { AppDispatch } from "../";
import { SELECTIONS } from "../../layout/config";
import i18n from "../../locale";
import { NotFoundError } from "../../types/errors";
import {
  Food,
  Meal,
  Plan,
  PlanDetail,
  PlanDetailResponse,
  PlanMenu,
  PlanSelectedPackage,
  RestaurantPrivateInfo,
  RestaurantWithPlans,
} from "../../types/restaurant";
import { randomElement } from "../../utils";
import axios from "../../utils/axios";
import { calculateIfLockedDay } from "../../utils/calculateIfLockedDay";

// Define a type for the slice state
interface State {
  isLoading: boolean;
  error: NotFoundError | null;
  success: boolean;
  restaurants: RestaurantWithPlans[];
  restaurant: RestaurantWithPlans | null;
  plan: Plan | null;
  plans: Plan[] | null;
  planDetail: PlanDetail | null;
  currentMealIndexInMenue: number;
  currentPackageIDInMenue: number;
  currentDayIndexInMenuePlan: number;
  currentDayIndexInMenueSelectFood: number;
  loadingCurrentDayIndexInMenuePlan: boolean;
  loadingCurrentDayIndexInMenueSelectFood: boolean;
  planSelectedPackages: PlanSelectedPackage[];
  restaurantPrivateInfo: RestaurantPrivateInfo | null;
  planMenu: PlanMenu | null;
}

const initialState: State = {
  isLoading: false,
  error: null,
  success: false,
  restaurants: [],
  restaurant: null,
  plan: null,
  plans: [],
  planDetail: null,
  loadingCurrentDayIndexInMenuePlan: true,
  loadingCurrentDayIndexInMenueSelectFood: true,
  currentDayIndexInMenuePlan: 0,
  currentDayIndexInMenueSelectFood: 0,
  currentMealIndexInMenue: 0,
  currentPackageIDInMenue: 0,

  planSelectedPackages: [],
  restaurantPrivateInfo: null,
  planMenu: null,
};

const slice = createSlice({
  name: "restaurants",
  initialState,
  reducers: {
    // Get Restaurants List (Plan[] are nested)
    getRestaurantsStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    },
    getRestaurantsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.success = true;
      state.restaurants = payload;
    },

    clearRestaurants: (state) => {
      state.restaurants = [];
    },

    getRestaurantsFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.success = false;
    },

    setPlan: (state, { payload }) => {
      state.plan = payload;
    },

    setRestaurant: (state, { payload }) => {
      state.restaurant = payload;
    },

    setPlanDetail: (state) => {
      state.planDetail = null;
    },

    // Get Restaurant Detail (PlanDetail[] are not nested)
    // Not reqequired on initial load but required if data set is more than 10
    getRestaurantPlansStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    },

    getRestaurantPlansSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.success = true;
      state.restaurant = payload;
    },

    getRestaurantPlansFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.success = false;
    },

    // Get Restaurant Plan Detail
    getRestaurantPlanStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
      state.planDetail = null;
    },
    getRestaurantPlanSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.success = true;
      state.planDetail = payload;
    },
    getRestaurantPlanFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.success = false;
    },

    setPlanSelectedPackages: (state, { payload }) => {
      state.planSelectedPackages = payload;
    },

    updatePlanSelectedPackages: (state, { payload }) => {
      const { plan_id, package_value } = payload;
      const index = state.planSelectedPackages.findIndex(
        (p) => p.plan_id === plan_id
      );
      if (index === -1) {
        state.planSelectedPackages.push(payload);
      } else {
        state.planSelectedPackages[index].package_value = package_value;
      }
    },

    setRestaurantPrivateInfo: (state, { payload }) => {
      state.restaurantPrivateInfo = payload;
    },

    setCurrentMealIndex: (state, { payload }) => {
      state.currentMealIndexInMenue = payload;
    },

    setCurrentPackageID: (state, { payload }) => {
      state.currentPackageIDInMenue = payload;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },

    setCurrentDayIndexPlan: (state, { payload }) => {
      state.currentDayIndexInMenuePlan = payload;
      state.loadingCurrentDayIndexInMenuePlan = false;
    },

    setCurrentDayIndexSelectFood: (state, { payload }) => {
      state.currentDayIndexInMenueSelectFood = payload;
      state.loadingCurrentDayIndexInMenueSelectFood = false;
    },
    getPlanMenuStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    },
    getPlanMenuSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.success = true;
      state.planMenu = payload;
    },
    getPlanMenuFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.success = false;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// Actions

export const getRestaurants =
  (token: string | null) => async (dispatch: AppDispatch) => {
    dispatch(actions.getRestaurantsStart());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };
      const response = await axios.get(`api/v1/restaurants`, config);
      const newData = response.data;
      dispatch(actions.getRestaurantsSuccess(newData));

      // Check if there are more pages
    } catch (error) {
      dispatch(actions.getRestaurantsFailure(error));
    }
  };

export const getRestaurantPlanMenu =
  (slug: string, planID: string, access_token: string | undefined) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.getPlanMenuStart());

    const config = {
      headers: {
        "Content-type": "application/json",
        ...(access_token && { Authorization: `Bearer ${access_token}` }),
      },
    };

    try {
      const response = await axios.get(
        `api/v1/restaurants/${slug}/${planID}/menu`,
        config
      );
      dispatch(actions.getPlanMenuSuccess(response.data));
    } catch (error) {
      dispatch(actions.getPlanMenuFailure(error));
    }
  };

export const getRestaurantDetail =
  (slug: string, access_token: string | undefined) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.getRestaurantPlansStart());

    const config = {
      headers: {
        "Content-type": "application/json",
        ...(access_token && { Authorization: `Bearer ${access_token}` }),
      },
    };

    try {
      const response = await axios.get(`api/v1/restaurants/${slug}`, config);
      dispatch(actions.getRestaurantPlansSuccess(response.data));
    } catch (error) {
      dispatch(actions.getRestaurantPlansFailure(error));
    }
  };

export const getRestaurantPlan =
  (slug: string, planID: string, access_token: string | undefined) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.getRestaurantPlanStart());

    const config = {
      headers: {
        "Content-type": "application/json",
        ...(access_token && { Authorization: `Bearer ${access_token}` }),
      },
    };

    try {
      const [response, foodsResponse] = await Promise.all([
        axios.get(`api/v1/restaurants/${slug}/${planID}/optimised`, config),
        axios.get(`/api/v1/restaurants/${slug}/${planID}/foods/`, config),
      ]);

      const data: PlanDetailResponse = response.data;
      const foods: Food[] = foodsResponse.data;
      const mealOrder: { [key: string]: number } = data.plan.meals.reduce(
        (acc, meal, index) => ({ ...acc, [meal.title]: index + 1 }),
        {}
      );

      const processedData: PlanDetail = {
        ...data,
        dates: data.dates.map((day) => {
          const date = new Date(day.date);
          const weekday = format(date, "EEE");
          const month = format(date, "MMM");
          const t = i18n.getFixedT("ar");
          const isLock = calculateIfLockedDay(day.date);

          return {
            ...day,
            date: {
              day: day.date.split("-")[2], // 5
              month, // Mar
              month_arabic: t(month),
              weekday, // Mon,
              weekday_arabic: t(weekday),
              date: day.date,
            },
            lock: isLock,
            meals: day.meals
              .map((meal) => {
                const mealDetail = data.plan.meals.find(
                  (m) => m.id === meal.id
                ) as Meal;

                if (isLock) {
                  const randomFoodId = randomElement(meal.foods);
                  const randomFood = foods.find(
                    (f) => f.id === randomFoodId
                  ) as Food;

                  return {
                    ...mealDetail,
                    foods: [randomFood],
                  };
                }

                return {
                  ...mealDetail,
                  foods: meal.foods?.map(
                    (foodId) => foods.find((f) => f.id === foodId) as Food
                  ),
                };
              })
              .sort((a, b) => mealOrder[a.title] - mealOrder[b.title]),
          };
        }),
      };

      dispatch(actions.getRestaurantPlanSuccess(processedData));
    } catch (error) {
      dispatch(actions.getRestaurantPlanFailure(error));
    }
  };

export const getRestaurantPlanSubscription =
  (subscription_id: string, access_token: string | undefined) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.getRestaurantPlanStart());
    const config = {
      headers: {
        "Content-type": "application/json",
        ...(access_token && { Authorization: `Bearer ${access_token}` }),
      },
    };
    try {
      const response = await axios.get(
        `api/v1/subscriptions/plan/${subscription_id}`,
        config
      );
      dispatch(
        actions.getRestaurantPlanSuccess({ plan: response.data, dates: [] })
      );
    } catch (error) {
      dispatch(actions.getRestaurantPlanFailure(error));
    }
  };

export const setCurrentDayIndex =
  (index: number, selection: string) => async (dispatch: AppDispatch) => {
    if (selection === SELECTIONS.PLAN_SELECTION) {
      dispatch(actions.setCurrentDayIndexPlan(index));
    } else {
      dispatch(actions.setCurrentDayIndexSelectFood(index));
    }
  };
// Setters // Helps to preload the next page data
export const setRestaurant =
  (restaurant: RestaurantWithPlans) => async (dispatch: AppDispatch) => {
    dispatch(actions.setRestaurant(restaurant));
  };

export const setPlan = (plan: Plan) => async (dispatch: AppDispatch) => {
  dispatch(actions.setPlan(plan));
};

export const setCurrentMealIndex =
  (index: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.setCurrentMealIndex(index));
  };

export const setCurrentPackageID =
  (index: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.setCurrentPackageID(index));
  };

export const updatePlanSelectedPackages =
  (plan_id: number, package_value: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.updatePlanSelectedPackages({ plan_id, package_value }));
  };
