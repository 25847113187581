import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { localStorageSet } from '../../utils/localStorage'

import { useAppDispatch } from '../../store/hooks'
import { getTermsAndConditions } from '../../store/slices/terms'

import Arabic from './Arabic'
import English from './English'


const TermsPolicy = () => {
  // Get user language
  const { i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const isEnglish = location.pathname.includes('/en/')
  const dispatch = useAppDispatch()

  if (isEnglish) {
    localStorageSet('i18nextLng', 'en')
  }

  useEffect(() => {
    dispatch(getTermsAndConditions())
  }, [])

  return isArabic ? <Arabic /> : <English />
}

export default TermsPolicy
