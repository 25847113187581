import { Container } from '@mui/material';
import { Component, ErrorInfo, ReactNode } from 'react';
import PageError from './error';

interface Props {
  children: ReactNode
  name: string
}

interface State {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

/**
 * Error boundary wrapper to save Application parts from falling
 * @component ErrorBoundary
 * @param {string} [props.name] - name of the wrapped segment, "Error Boundary" by default
 */
class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    name: 'Error Boundary',
  }

  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // The next render will show the Error UI
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Save information to help render Error UI
    this.setState({ error, errorInfo })
    // TODO: Add log error messages to an error reporting service here
    console.error("ErrorBoundary -> componentDidCatch -> error", error)
  }

  handleRefreshPage = () => {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      // Error UI rendering
      return (
        <Container sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <PageError />
        </Container>
      )
    }

    // Normal UI rendering
    return this.props.children
  }
}

export default ErrorBoundary
