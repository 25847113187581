import { useEffect } from 'react'
import { UserData } from '../types/accounts'
import { useAppDispatch } from '../store/hooks'
import { refillUserData } from '../store/slices/accounts'

export function useRefill() {
  const dispatch = useAppDispatch()

  /**
   * Effect to run when the component mounts.
   */
  useEffect(() => {
    // Retrieve user data from localStorage
    const userString = localStorage.getItem('user')
    const user: UserData = userString ? JSON.parse(userString) : {}

    // Dispatch the refillUserData action if the user has a token
    if (user && user.token) {
      dispatch(refillUserData(user))
    }
  }, []) // Empty dependency array ensures this effect runs only on mount

  return null
}
