import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationsEN from './en/common.json'
import translationsAR from './ar/common.json'

const resources = {
  ar: {
    translation: translationsAR,
  },
  en: {
    translation: translationsEN,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // Language detector to determine the user language preference from the browser
  .init({
    resources,
    fallbackLng: 'ar',
    lng: localStorage.getItem('i18nextLng') || 'ar',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
