import { createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import jwtDecode from "jwt-decode";

import { AppDispatch } from "../";
import {
  ActiveSubscription,
  Error,
  LoginError,
  LoginParams,
  PersonalDetailUpdateParams,
  SignupParams,
  SignupVerificationParams,
  TemoraryUserData,
  UserData,
} from "../../types/accounts";
import axios from "../../utils/axios";

// Define a type for the slice state
interface AccountsState {
  // General
  isloading: boolean;
  error: Error | null;
  error_action: unknown | null;
  error_ar: string | null;
  error_code: string | null;
  success: boolean;

  // Login
  isLoginLoading: boolean;
  loginError: LoginError | null;
  loginSuccess: boolean;

  // OTP Send
  otpSendloading: boolean;
  otpSendError: Error | null;
  otpSendSuccess: boolean;

  // Reset Password
  resetPassLoading: boolean;
  resetPassError: Error | null;
  resetPassSuccess: boolean;

  // Delete Account
  deleteAccountLoading: boolean;
  deleteAccountError: Error | null;
  deleteAccountSuccess: boolean;

  phone_number: string | null;
  userData: UserData | null;
  userHasActiveSubscription: boolean;

  // Signup Personal Detail
  temporaryUserData: TemoraryUserData | null;
  isSignupRequestLoading: boolean;
  signupRequestError: string | null;
  signupRequestSuccess: boolean;

  // Signup Verification
  isSignupVerificationLoading: boolean;
  signupVerificationError: string;
  signupVerificationSuccess: boolean;

  previousPath: string | null;
  signupParams: SignupParams | null;
  userAgreement: boolean;

  throttleError: string | null;

  success_action: boolean;
  isloading_action: boolean;

  payloadSubscriptionAction: ActiveSubscription | null;

  redirectToCheckoutOnSuccess: boolean; // TODO : Unused Code Remove this after new UI
}

const initialState: AccountsState = {
  isloading: false,
  error: null,
  error_ar: null,
  error_code: null,
  success: false,

  error_action: null,
  success_action: false,
  isloading_action: false,

  isLoginLoading: false,
  loginError: null,
  loginSuccess: false,

  resetPassLoading: false,
  resetPassError: null,
  resetPassSuccess: false,

  otpSendloading: false,
  otpSendError: null,
  otpSendSuccess: false,

  deleteAccountLoading: false,
  deleteAccountError: null,
  deleteAccountSuccess: false,

  phone_number: null, // being used for password Reset
  userData: null,
  userHasActiveSubscription: false,

  payloadSubscriptionAction: null,

  temporaryUserData: null,

  isSignupRequestLoading: false,
  signupRequestError: null,
  signupRequestSuccess: false,

  isSignupVerificationLoading: false,
  signupVerificationError: "",
  signupVerificationSuccess: false,

  previousPath: null,
  signupParams: null,
  userAgreement: false,

  throttleError: null,
  redirectToCheckoutOnSuccess: false, // TODO : Unused Code Remove this after new UI
};

const slice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    startLoginLoading(state) {
      state.isLoginLoading = true;
      state.loginError = null;
      state.loginSuccess = false;
    },
    // LOGIN
    loginSuccess(state, action) {
      state.isLoginLoading = false;
      state.loginSuccess = true;

      state.userData = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.throttleError = null;
    },

    refillUserData(state, action) {
      // If userData is {} then make it null
      if (Object.keys(action.payload).length === 0) {
        state.userData = null;
      } else {
        state.userData = action.payload;
      }
    },

    loginFail(state, action) {
      state.isLoginLoading = false;
      state.loginError = action.payload.detail || "Login failed";
      state.loginSuccess = false;
      // Remove user from localStorage
      localStorage.removeItem("user");
    },

    profileUpdateStart(state) {
      state.isloading = true;
      state.error = null;
      state.success = false;
    },

    profileUpdateSuccess(state, action) {
      state.isloading = false;
      state.success = true;
      state.userData = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },

    profileUpdateFail(state, action) {
      state.isloading = false;
      state.error_code = action.payload.status_code;
      state.error = action.payload.detail;
      state.error_ar = action.payload.detail_ar;
      state.success = false;
    },

    // Send Verification Code/OTP
    sendVerificationCodeStart(state) {
      state.otpSendloading = true;
      state.otpSendError = null;
      state.otpSendSuccess = false;
      state.throttleError = null;
    },

    sendVerificationCodeSuccess(state, action) {
      state.otpSendloading = false;
      state.otpSendSuccess = true;
      state.phone_number = action.payload.phone_number;
      state.throttleError = null;
    },

    sendVerificationCodeFail(state, action) {
      state.otpSendloading = false;
      state.otpSendError = action.payload.error;
      state.otpSendSuccess = false;
    },

    // Verify OTP for Password Reset
    verifyOTPForPassResetStart(state) {
      state.isloading = true;
      state.error = null;
      state.success = false;
      state.userData = null;
      state.throttleError = null;
    },

    verifyOTPForPassResetSuccess(state, action) {
      state.isloading = false;
      state.success = true;
      state.userData = action.payload;
      state.throttleError = null;
    },

    verifyOTPForPassResetFail(state, action) {
      state.isloading = false;
      state.error = action.payload.error;
      state.success = false;
    },

    // Resetting Password
    resetPasswordStart(state) {
      state.resetPassLoading = true;
      state.resetPassError = null;
      state.resetPassSuccess = false;
    },

    resetPasswordSuccess(state, action) {
      state.resetPassLoading = false;
      state.resetPassSuccess = true;
      state.userData = action.payload;
      // Set in localStorage
      localStorage.setItem("user", JSON.stringify(action.payload));
    },

    resetPasswordFail(state, action) {
      state.resetPassLoading = false;
      state.resetPassError = action.payload.error;
      state.resetPassSuccess = false;
    },

    // Delete Account
    deleteAccountStart(state) {
      state.deleteAccountLoading = true;
      state.deleteAccountError = null;
      state.deleteAccountSuccess = false;
      state.throttleError = null;
    },

    deleteAccountSuccess(state) {
      state.deleteAccountLoading = false;
      state.deleteAccountSuccess = true;
      state.userData = null;

      localStorage.removeItem("user");
      state.throttleError = null;
    },

    deleteAccountFail(state, action) {
      state.deleteAccountLoading = false;
      state.deleteAccountError = action.payload.error;
      state.deleteAccountSuccess = false;
    },

    resetDeleteAccountOperation(state) {
      state.deleteAccountLoading = false;
      state.deleteAccountError = null;
      state.deleteAccountSuccess = false;
    },

    // Signup
    updateTemporaryPersonalDetails(state, action) {
      state.temporaryUserData = action.payload;
    },

    signupRequestStart(state) {
      state.isSignupRequestLoading = true;
      state.signupRequestError = null;
      state.signupRequestSuccess = false;
      state.throttleError = null;
    },

    setSignUpParams(state, action) {
      state.signupParams = action.payload;
    },

    signupRequestSuccess(state, action) {
      state.isSignupRequestLoading = false;
      state.signupRequestSuccess = true;
      state.userData = action.payload;

      if (action.payload.status === "verified") {
        // We are checking if user is already verified and exists in DB, if yes then we will login the user instead of OTP verification
        localStorage.setItem("user", JSON.stringify(action.payload));
      }

      state.throttleError = null;
    },

    signupRequestFail(state, action) {
      state.isSignupRequestLoading = false;
      state.signupRequestError = action.payload.error;
      state.signupRequestSuccess = false;
    },

    signupVerificationStart(state) {
      state.isSignupVerificationLoading = true;
      state.signupVerificationError = "";
      state.signupVerificationSuccess = false;
      state.throttleError = null;
    },

    signupVerificationSuccess(state, action) {
      state.isSignupVerificationLoading = false;
      state.signupVerificationSuccess = true;
      state.userData = action.payload;
      // Set user in localStorage
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.temporaryUserData = null;
      state.throttleError = null;
    },

    signupVerificationFail(state) {
      state.isSignupVerificationLoading = false;
      state.signupVerificationError = "OTP verification failed";
      state.signupVerificationSuccess = false;
    },

    setPreviousPath(state, action) {
      state.previousPath = action.payload;
    },

    setUserAgreement(state, action) {
      state.userAgreement = action.payload;
    },

    // refil

    resetSuccess(state) {
      state.isloading = false;
      state.error = null;
      state.success = false;
    },

    pauseActiveSubscriptionStart: (state) => {
      state.isloading_action = true;
      state.error_action = null;
      state.success_action = false;
    },

    resumeActiveSubscriptionStart: (state) => {
      state.isloading_action = true;
      state.error_action = null;
      state.success_action = false;
    },

    pauseActiveSubscriptionSuccess: (state, { payload }) => {
      state.payloadSubscriptionAction = payload;
      state.isloading_action = false;
      state.error_action = null;
      state.success_action = true;

      const active_subscriptions: ActiveSubscription[] =
        state.userData?.customer.active_subscriptions.map((subscription) => {
          if (subscription.id === payload.id) {
            return payload as ActiveSubscription;
          }
          return subscription;
        }) || [];

      if (state.userData && active_subscriptions) {
        const new_user_data = {
          ...state.userData,
          customer: {
            ...state.userData.customer,
            active_subscriptions: active_subscriptions,
          },
        };
        state.userData = new_user_data;
      }
    },

    resumeActiveSubscriptionSuccess: (state, { payload }) => {
      state.payloadSubscriptionAction = payload;
      state.isloading_action = false;
      state.error_action = null;
      state.success_action = true;
      const active_subscriptions: ActiveSubscription[] =
        state.userData?.customer.active_subscriptions.map((subscription) => {
          if (subscription.id === payload.id) {
            return payload as ActiveSubscription;
          }
          return subscription;
        }) || [];

      if (state.userData && active_subscriptions) {
        const new_user_data = {
          ...state.userData,
          customer: {
            ...state.userData.customer,
            active_subscriptions: active_subscriptions,
          },
        };

        state.userData = new_user_data;
      }
    },

    resumeActiveSubscriptionFailure: (state, { payload }) => {
      state.success_action = false;
      state.error_action = payload;
      state.isloading_action = false;
    },

    pauseActiveSubscriptionFailure: (state, { payload }) => {
      state.success_action = false;
      state.error_action = payload;
      state.isloading_action = false;
    },

    // Resetting required State

    cleanUserData(state) {
      state.userData = null;
      // Remove user from localStorage
      localStorage.removeItem("user");
      localStorage.removeItem("executeRequestPaymentData");
      localStorage.removeItem("checkoutData");
      state.isLoginLoading = false;
      state.loginError = null;
      state.loginSuccess = false;
      state.temporaryUserData = null;
      state.previousPath = null;
      state.signupParams = null;
      state.userAgreement = false;
      state.isloading_action = false;
      state.error_action = null;
      state.redirectToCheckoutOnSuccess = false; // TODO : Unused Code Remove this after new UI
    },

    resetSignupRequestsState(state) {
      state.isSignupRequestLoading = false;
      state.signupRequestError = null;
      state.signupRequestSuccess = false;

      state.isSignupVerificationLoading = false;
      state.signupVerificationError = "";
      state.signupVerificationSuccess = false;
      state.throttleError = null;
      state.previousPath = null;
    },

    // TODO : Unused Code Remove this after new UI
    setRedirectToCheckoutOnSuccess(state, action) {
      state.redirectToCheckoutOnSuccess = action.payload;
    },

    resetFormsState(state) {
      state.isloading = false;
      state.error = null;
      state.success = false;

      state.isloading_action = false;
      state.error_action = null;

      state.otpSendloading = false;
      state.otpSendError = null;
      state.otpSendSuccess = false;

      state.phone_number = null;

      state.resetPassLoading = false;
      state.resetPassError = null;
      state.resetPassSuccess = false;

      state.deleteAccountLoading = false;
      state.deleteAccountError = null;
      state.deleteAccountSuccess = false;

      state.isLoginLoading = false;
      state.loginError = null;
      state.loginSuccess = false;

      state.isSignupRequestLoading = false;
      state.signupRequestError = null;
      state.signupRequestSuccess = false;

      state.isSignupVerificationLoading = false;
      state.signupVerificationError = "";
      state.signupVerificationSuccess = false;

      state.temporaryUserData = null;
      state.throttleError = null;
      state.previousPath = null;

      state.signupParams = null;
      state.userAgreement = false;
    },
    setThrottleError(state, action) {
      state.throttleError = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// Actions

export const login = (params: LoginParams) => async (dispatch: AppDispatch) => {
  dispatch(actions.startLoginLoading());
  try {
    const modifiedPhoneNumber = `+965${params.phone_number}`;
    const modifiedParams = { ...params, phone_number: modifiedPhoneNumber };
    const response = await axios.post("/api/token/", modifiedParams);
    dispatch(actions.loginSuccess(response.data));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response && error.response.status === 429) {
      dispatch(actions.setThrottleError(error.response.data.detail));
      return; // Stop further execution
    }
    dispatch(actions.loginFail(error));
  }
};

export const me = (token: string) => async (dispatch: AppDispatch) => {
  dispatch(actions.startLoginLoading());

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get("/api/v1/accounts/me/", config);
    dispatch(actions.loginSuccess(response.data));
  } catch (error: any) {
    if (error.code === "ERR_NETWORK") {
      console.log("Network Error issues");
    } else {
      dispatch(actions.loginFail(error));
    }
  }
};

export const updateProfile =
  (token: string, params: PersonalDetailUpdateParams) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.profileUpdateStart());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      // Modify Form data to match the API requirements
      const data = {
        name: params.name,
        block: params.block,
        street: params.street,
        avenue: params.avenue,
        house: params.house,
        area: params.area?.value,
      };

      const response = await axios.put(
        "/api/v1/accounts/me/update/",
        data,
        config
      );
      dispatch(actions.profileUpdateSuccess(response.data));
    } catch (error: any) {
      dispatch(actions.profileUpdateFail(error));
    }
  };

export const sendVerificationCode =
  (phone_number: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.sendVerificationCodeStart());
    try {
      // Check if phone_number has +965 prefix, if not then add it
      if (!phone_number.startsWith("+965")) {
        phone_number = `+965${phone_number}`;
      }

      await axios.post("/api/v1/accounts/send-verification-code/", {
        phone_number,
      });
      dispatch(
        actions.sendVerificationCodeSuccess({
          phone_number: phone_number,
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error && error.detail) {
        // and starts with "Request was throttled"
        if (error.detail.startsWith("Request was throttled")) {
          dispatch(actions.setThrottleError(error.detail));
        }
      }
      dispatch(actions.sendVerificationCodeFail(error));
    }
  };

export const sendRecoveryPassCode =
  (phone_number: string) => async (dispatch: AppDispatch) => {
    // Same as sendVerificationCode but different API endpoint to trigger different Slack Notification from backend
    // No change in functionality, same as sendVerificationCode
    dispatch(actions.sendVerificationCodeStart());
    try {
      // Check if phone_number has +965 prefix, if not then add it
      if (!phone_number.startsWith("+965")) {
        phone_number = `+965${phone_number}`;
      }

      await axios.post("/api/v1/accounts/send-recovery-pass-code/", {
        phone_number,
      });
      dispatch(
        actions.sendVerificationCodeSuccess({
          phone_number: phone_number,
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error && error.detail) {
        // and starts with "Request was throttled"
        if (error.detail.startsWith("Request was throttled")) {
          dispatch(actions.setThrottleError(error.detail));
        }
      }
      dispatch(actions.sendVerificationCodeFail(error));
    }
  };

export const verifyOTPForPassReset =
  (phone_number: string, otp: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.verifyOTPForPassResetStart());
    try {
      if (!phone_number.startsWith("+965")) {
        phone_number = `+965${phone_number}`;
      }
      const modifiedParams = {
        phone_number: phone_number,
        otp,
      };

      const response = await axios.post(
        "/api/v1/accounts/verify-reset-password-otp/",
        modifiedParams
      );
      dispatch(actions.verifyOTPForPassResetSuccess(response.data));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error && error.detail) {
        // and starts with "Request was throttled"
        if (error.detail.startsWith("Request was throttled")) {
          dispatch(actions.setThrottleError(error.detail));
        }
      }
      dispatch(actions.verifyOTPForPassResetFail(error));
    }
  };

export const resetPassword =
  (token: string, password: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.resetPasswordStart());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        "/api/v1/accounts/reset-password/",
        { password },
        config
      );
      dispatch(actions.resetPasswordSuccess(response.data));
    } catch (error: unknown) {
      dispatch(actions.resetPasswordFail(error));
    }
  };

export const deleteAccount =
  (token: string, password: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.deleteAccountStart());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post(
        "/api/v1/accounts/delete-account/",
        { password },
        config
      );
      dispatch(actions.deleteAccountSuccess());
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error && error.detail) {
        // and starts with "Request was throttled"
        if (error.detail.startsWith("Request was throttled")) {
          dispatch(actions.setThrottleError(error.detail));
        }
      }
      dispatch(actions.deleteAccountFail(error));
    }
  };

export const signupRequest =
  (params: SignupParams) => async (dispatch: AppDispatch) => {
    dispatch(actions.signupRequestStart());

    const nonModifiedParams = { ...params };
    const modifiedPhoneNumber = `+965${params.phone_number}`;
    const modifiedParams = { ...params, phone_number: modifiedPhoneNumber };

    try {
      const response = await axios.post(
        "/api/v1/accounts/register/",
        modifiedParams
      );
      dispatch(actions.signupRequestSuccess(response.data));
      dispatch(actions.setSignUpParams(nonModifiedParams));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error && error.detail) {
        // and starts with "Request was throttled"
        if (error.detail.startsWith("Request was throttled")) {
          dispatch(actions.setThrottleError(error.detail));
        }
      }
      dispatch(actions.signupRequestFail(error));
    }
  };

export const signupVerification =
  (token: string, params: SignupVerificationParams) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.signupVerificationStart());

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        "/api/v1/accounts/verify/",
        params,
        config
      );
      dispatch(actions.signupVerificationSuccess(response.data));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error && error.detail) {
        // and starts with "Request was throttled"
        if (error.detail.startsWith("Request was throttled")) {
          dispatch(actions.setThrottleError(error.detail));
        }
      }
      dispatch(actions.signupVerificationFail());
    }
  };

export const setPreviousPathInStore =
  (path: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.setPreviousPath(path));
  };

export const setUserAgreementInStore =
  (agreement: boolean) => async (dispatch: AppDispatch) => {
    dispatch(actions.setUserAgreement(agreement));
  };

export const refillUserData =
  (data: UserData) => async (dispatch: AppDispatch) => {
    dispatch(actions.refillUserData(data));
  };

export const resetAccountsFormsState = () => async (dispatch: AppDispatch) => {
  dispatch(actions.resetFormsState());
};

export const updateTemporaryPersonalDetails =
  (data: TemoraryUserData) => async (dispatch: AppDispatch) => {
    dispatch(actions.updateTemporaryPersonalDetails(data));
  };

export const cleanUserData = () => async (dispatch: AppDispatch) => {
  dispatch(actions.cleanUserData());
};

export const resetSuccess = () => async (dispatch: AppDispatch) => {
  dispatch(actions.resetSuccess());
};

export const resetSignupRequestsState = () => async (dispatch: AppDispatch) => {
  dispatch(actions.resetSignupRequestsState());
};

export const setRedirectToCheckoutOnSuccess =
  (value: boolean) => async (dispatch: AppDispatch) => {
    dispatch(actions.setRedirectToCheckoutOnSuccess(value));
  };

export const resetDeleteAccountOperation =
  () => async (dispatch: AppDispatch) => {
    dispatch(actions.resetDeleteAccountOperation());
  };
export const verifyAccessTokenAndRefil =
  (userData: UserData) => async (dispatch: AppDispatch) => {
    if (
      userData &&
      userData.token &&
      userData.token.access &&
      userData.token.refresh
    ) {
      try {
        const decodedToken: { exp: number } = jwtDecode(userData.token.access);

        const accessTokenExp = new Date(decodedToken.exp * 1000); // Convert to milliseconds
        const twoDaysFromNow = new Date();
        twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);

        if (accessTokenExp <= twoDaysFromNow) {
          // Token is about to expire, refresh it
          const response = await axios.post("/api/token/refresh/", {
            refresh: userData.token.refresh,
          });

          const data = {
            ...userData,
            token: {
              ...userData.token,
              access: response.data.access,
              refresh: response.data.refresh,
            },
          };

          localStorage.setItem("user", JSON.stringify(data));
          dispatch(actions.refillUserData(data));
        }
      } catch (error) {
        // Handle decoding error or refresh token error
        console.error("Error refreshing token:", error);
      }
    } else {
      Sentry.captureMessage(
        `User Data Incomplete: ${JSON.stringify(userData)} `,
        "warning"
      );
    }
  };

export const pauseActiveSubscription =
  (
    params: { uuid: string; start_date: string; end_date: string },
    token: string
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.pauseActiveSubscriptionStart());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };
      const { data } = await axios.post(
        `/api/v1/subscriptions/${params.uuid}/pause/`,
        { start_date: params.start_date, end_date: params.end_date },
        config
      );
      dispatch(
        actions.pauseActiveSubscriptionSuccess(data as ActiveSubscription)
      );
    } catch (error) {
      console.log(error);
      dispatch(actions.pauseActiveSubscriptionFailure(error));
    }
  };

export const resumeActiveSubscription =
  (params: { uuid: string; date_to_resume: string }, token: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.resumeActiveSubscriptionStart());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };
      const { data } = await axios.post(
        `/api/v1/subscriptions/${params.uuid}/resume/`,
        { date_to_resume: params.date_to_resume },
        config
      );

      dispatch(actions.resumeActiveSubscriptionSuccess(data));
    } catch (error) {
      console.log(error);
      dispatch(actions.resumeActiveSubscriptionFailure(error));
    }
  };
