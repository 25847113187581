import { FunctionComponent, ReactElement } from "react";
import { AlertProps as MuiAlertProps } from "@mui/material/Alert";
import { Alert, Box, Snackbar as MuiSnakbar, SnackbarOrigin, SnackbarProps, styled } from "@mui/material";

interface SnackbarProviderProps extends MuiAlertProps {
  handleOpen: boolean;
  autoHideDuration?: number;
  message?: Node | string | ReactElement<any> | any;
  onClose?: () => void;
  anchorOrigin?: SnackbarOrigin;
  hasActionIcon?: boolean;
  withIcon?: boolean;
  infoIcon?:  ReactElement<any> | null
}

interface ISnakbarProps extends SnackbarProps  {
  hasactionicon?: string,
  withicon?: string
  infoicon?: string
  
}

const StyledAlert = styled(Alert)(({hasactionicon, withicon, infoicon} :ISnakbarProps)=>{
  const withIcon = withicon === "true";
  const infoIcon = infoicon === "true";
  const hasActionIcon = hasactionicon === "true"
  return ({
  width: "100%", 
  backgroundColor: "#579b3f", 
  color: "white" ,
  ".MuiAlert-icon .MuiSvgIcon-root": {
    color: "white"
  },
  ".MuiAlert-action": {
    display: hasActionIcon ? "flex":"none"
  },

  ".MuiAlert-icon": {
    display: withIcon ? "block" : "none",
    opacity: infoIcon ? 0 : 1
  }
})})
const Snackbar: FunctionComponent<SnackbarProviderProps> = ({
  severity,
  handleOpen,
  autoHideDuration=3000,
  message,
  onClose,
  anchorOrigin,
  hasActionIcon=false,
  withIcon=true,
  infoIcon=null
}) => {
  return (
    <MuiSnakbar
      open={handleOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      >
        <StyledAlert
          onClose={onClose}
          severity={severity}
          hasactionicon={hasActionIcon.toString()}
          withicon={withIcon.toString()}
          infoicon={Boolean(infoIcon).toString()}
          >
            <Wrapper>
              {infoIcon}
              {message}
            </Wrapper>
        </StyledAlert>
    </MuiSnakbar>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  `

export default Snackbar;
