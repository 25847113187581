import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowCircle } from "../../assets/icons/left_arrow.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setRedirectToCheckoutOnSuccess } from "../../store/slices/accounts";
import { fetchPaymentMethods } from "../../store/slices/payments";
import { Package } from "../../types/restaurant";
import axios from "../../utils/axios";
import removeTrailingZeros from "../../utils/removeTrailingZeros";

const CheckoutFooter = ({
  planId,
  packs,
  restaurantSlug,
  setSelectedPackageId,
  selectedPackageId,
}: {
  planId: string;
  packs: Package[];
  restaurantSlug: string;
  setSelectedPackageId: (id: number) => void;
  selectedPackageId: number;
}) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const packageTitle = (period: number) =>
    isArabic ? `${period} يوم` : `${period} days`;
  const offDays = (pack: Package) =>
    isArabic
      ? `راحة ${pack.off_days.map((d) => d.day_arabic).join(" و ")}`
      : `Off ${pack.off_days.map((d) => d.day).join(" and ")}`;

  const selectedPack = packs.find((p) => p.id == selectedPackageId);
  const selectedPackPrice =
    selectedPack?.discounted_price || selectedPack?.price || "";

  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.accounts);
  const isAuthenticated = !!userData?.token?.access;
  const token = userData?.token?.access;

  const navigate = useNavigate();

  const handleProceed = () => {
    if (restaurantSlug) {
      localStorage.setItem("restaurant_slug", restaurantSlug as string);
    }
    if (planId) {
      localStorage.setItem("plan_id", planId);
    }

    navigate(isAuthenticated ? "/checkout" : "/personal-details");

    if (!isAuthenticated) {
      dispatch(setRedirectToCheckoutOnSuccess(true));
    }

    const interactionIDKey = `${restaurantSlug}/interaction_id`;
    const interactionID = sessionStorage.getItem(interactionIDKey);

    const config = {
      headers: {
        "Content-type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };

    axios.post(
      `/api/v1/impressions/restaurants/${restaurantSlug}/subscribe-button-click/`,
      { plan: planId, interaction_id: interactionID },
      config
    );
  };

  useEffect(() => {
    if (restaurantSlug && userData) {
      dispatch(fetchPaymentMethods(userData?.token.access, restaurantSlug));
    }
  }, [restaurantSlug, userData?.token.access]);

  useEffect(() => {
    const selectedPackageId = localStorage.getItem("selected_package_id");
    setSelectedPackageId(Number(selectedPackageId) || packs[0]?.id);
  }, []);

  return (
    <Paper
      sx={{
        background: "white",
        width: "100%",
        p: "24px",
        pb: "16px",
        pt: "12px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      elevation={6}
      square
    >
      <Button
        variant="contained"
        sx={{ p: "17px", display: "flex", alignContent: "center", gap: "20px" }}
        onClick={handleProceed}
      >
        <Typography variant="h2" component="span">
          {`${t("PROCEED")}`} &nbsp;
          {`(${removeTrailingZeros(selectedPackPrice)} ${t("KWD")})`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: isArabic ? "rotate(0)" : "rotate(180deg)",
            background: "rgba(255, 255, 255, 0.24)",
            borderRadius: "50%",
            width: "24px",
            height: "24px",
          }}
        >
          <ArrowCircle />
        </Box>
      </Button>
      <Select
        value={selectedPackageId}
        onChange={(e) => setSelectedPackageId(Number(e.target.value))}
        variant="standard"
        disableUnderline
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
        renderValue={() => {
          return (
            <Box sx={{ marginInlineEnd: "6px" }}>
              <Typography component="div">
                {packageTitle(selectedPack?.period || 0)}
              </Typography>
            </Box>
          );
        }}
      >
        {packs.map((pack) => (
          <MenuItem key={pack.id} value={pack.id}>
            {packageTitle(pack.period) + " - " + offDays(pack)}
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
};

export default CheckoutFooter;
