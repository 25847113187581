import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { cleanUserData } from '../store/slices/accounts';

const useForceLogoutUnverifiedUser = () => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.accounts);

  useEffect(() => {
    if (userData && userData.status === 'unverified') {
      dispatch(cleanUserData());
      // TODO: Later on, Instead of clearUserData enable OTP verification flow
    }
  }, [userData, dispatch]);

  return null;
};

export default useForceLogoutUnverifiedUser;