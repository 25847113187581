interface Props {
  message: string
}

function getContactUsLink({ message }: Props): string {
  const phoneNumber = '+96597002008'
  const encodedMessage = encodeURIComponent(message)
  return `https://wa.me/${phoneNumber}?text=${encodedMessage}`
}

export default getContactUsLink
