import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Grid, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Components

// Utilities and Hooks
import { useIsAuthenticated } from "../../hooks";
import { SHARED_CONTROL_PROPS } from "../../utils";
// Styles

import { ReactComponent as SymbolsEditIcon } from "../../assets/icons/symbols_edit.svg";

import { getCitiesOptions } from "../../utils/getCitiesOptions";

// Redux
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { resetSuccess, updateProfile } from "../../store/slices/accounts";
import { resetInitialState } from "../../store/slices/globals";

import { getCities } from "../../store/slices/cities";

import { RHFAutocomplete, RHFTextField } from "../../components/hook-form";
import FormProvider from "../../components/hook-form/form-provider";
import { Layout } from "../../layout/Layout2";
import { PersonalDetailUpdateParams } from "../../types/accounts";

const Profile = () => {
  const user = useIsAuthenticated();

  const { t, i18n } = useTranslation();
  const isArabicMode = i18n.language === "ar";
  const dispatch = useAppDispatch();

  const { cities, success: citiesloadsuccess } = useAppSelector(
    (state) => state.cities
  );

  useEffect(() => {
    if (!citiesloadsuccess) {
      dispatch(getCities());
    }
  }, [cities]);

  useEffect(() => {
    dispatch(resetInitialState());
    dispatch(resetSuccess());
  }, []);

  const { isloading, success, error, error_code } = useAppSelector(
    (state) => state.accounts
  );

  const defaultValues = useMemo(() => {
    return {
      name: user?.name || "",
      block: user?.personal_detail?.block || "",
      street: user?.personal_detail?.street || "",
      avenue: user?.personal_detail?.avenue || "",
      house: user?.personal_detail?.house || "",
      area: user?.personal_detail?.area || 0,
    };
  }, [user]);

  const UpdateProfileSchema = Yup.object().shape({
    name: Yup.string()
      .required(`${t("NAME")} ${t("ISREQUIRED")}`)
      .matches(/^[a-zA-Z\u0600-\u06FF\s]*$/, `${t("INVALID_NAME")}`)
      .min(2, `${t("NAME")} ${t("ERROR.NAME_RANGE")}`)
      .max(40, `${t("NAME")} ${t("ERROR.NAME_RANGE")}`),
    street: Yup.string()
      .required(`${t("PERSONAL_DETAILS.STREET")} ${t("ISREQUIRED")}`)
      .min(
        1,
        `${t("PERSONAL_DETAILS.STREET")} ${t("ERROR.PREFIX")} 60 ${t(
          "ERROR.DIGITS"
        )}`
      )
      .max(
        60,
        `${t("PERSONAL_DETAILS.STREET")} ${t("ERROR.PREFIX")} 60 ${t(
          "ERROR.DIGITS"
        )}`
      ),
    avenue: Yup.string().max(
      30,
      `${t("AVENUE")} ${t("ERROR.PREFIX")} 30 ${t("ERROR.DIGITS")}`
    ), // Optional field
    block: Yup.string()
      .required(`${t("PERSONAL_DETAILS.BLOCK")} ${t("ISREQUIRED")}`)
      .min(
        1,
        `${t("PERSONAL_DETAILS.BLOCK")} ${t("ERROR.PREFIX")} 4 ${t(
          "ERROR.DIGITS"
        )}`
      )
      .max(
        4,
        `${t("PERSONAL_DETAILS.BLOCK")} ${t("ERROR.PREFIX")} 4 ${t(
          "ERROR.DIGITS"
        )}`
      ),
    house: Yup.string()
      .required(`${t("PERSONAL_DETAILS.HOUSE")} ${t("ISREQUIRED")}`)
      .min(
        1,
        `${t("PERSONAL_DETAILS.HOUSE")} ${t("ERROR.PREFIX")} 5 ${t(
          "ERROR.DIGITS"
        )}`
      )
      .max(
        5,
        `${t("PERSONAL_DETAILS.HOUSE")} ${t("ERROR.PREFIX")} 5 ${t(
          "ERROR.DIGITS"
        )}`
      ),
    area: Yup.number()
      .integer()
      .required(`${t("PERSONAL_DETAILS.AREA")} ${t("ISREQUIRED")}`),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(UpdateProfileSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = methods;

  const onSubmit = handleSubmit(async (data, event) => {
    event?.preventDefault();
    const token = user?.token?.access || "";
    const details = {
      name: data.name,
      block: data.block,
      street: data.street,
      avenue: data.avenue,
      house: data.house,
      area: {
        value: data.area,
      },
    };

    dispatch(
      updateProfile(
        token as string,
        details as unknown as PersonalDetailUpdateParams
      )
    );
  });

  return (
    <Layout title={`${t("ADDRESS_DETAILS")}`}>
      <Helmet>
        <title>
          {isArabicMode
            ? "المعلومات الشخصية | اشتراكات للمطاعم الصحية"
            : "Personal Details | Eshtrakaat for Subscriptions Restaurants"}
        </title>
        <meta
          name="description"
          content={
            isArabicMode
              ? "قم بمراجعة معلوماتك الشخصية و قم بتعديلها في حال الحاجة الى ذلك  | اشتراكات للمطاعم الصحية"
              : "Check your address & change if you need | Eshtrakaat for Subscriptions Restaurants"
          }
        />
      </Helmet>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
              height: "100%",
              maxWidth: "600px",
            }}
            paddingTop="20px"
            rowGap="40px"
          >
            <Grid
              container
              flex={1}
              justifyContent="space-between"
              flexDirection="column"
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RHFTextField
                    required
                    label={t("NAME")}
                    name="name"
                    {...SHARED_CONTROL_PROPS}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SymbolsEditIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <RHFAutocomplete
                    noOptionsText={t("PERSONAL_DETAILS.NO_CITIES")}
                    loadingText="Loading Area Name..."
                    sx={{ backgroundColor: "transparent" }}
                    name="area"
                    label={t("PERSONAL_DETAILS.AREA") || ""}
                    options={getCitiesOptions(cities, isArabicMode)}
                    getOptionLabel={(option: any) =>
                      typeof option === "string" ? option : option?.label ?? ""
                    }
                    isOptionEqualToValue={(option: any, value: any) => {
                      return option.label === value;
                    }}
                    onChange={(event: any, value: any) => {
                      if (!value) return;
                      methods.setValue(
                        "area",
                        typeof value === "object" ? value.value : value,
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                    value={
                      getCitiesOptions(cities, isArabicMode)?.find(
                        (city) => city.value === methods.watch("area")
                      )?.label || ""
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField
                    required
                    label={t("PERSONAL_DETAILS.STREET")}
                    name="street"
                    {...SHARED_CONTROL_PROPS}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SymbolsEditIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RHFTextField
                    required
                    label={t("PERSONAL_DETAILS.BLOCK")}
                    name="block"
                    {...SHARED_CONTROL_PROPS}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SymbolsEditIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RHFTextField
                    label={t("PERSONAL_DETAILS.AVENUE")}
                    name="avenue"
                    autoComplete="off"
                    {...SHARED_CONTROL_PROPS}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SymbolsEditIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RHFTextField
                    required
                    label={t("PERSONAL_DETAILS.HOUSE")}
                    name="house"
                    autoComplete="off"
                    {...SHARED_CONTROL_PROPS}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SymbolsEditIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  {success && (
                    <Grid item xs={12}>
                      <Alert
                        severity="success"
                        sx={{
                          mt: "22px",
                          // Half lenght on desktop
                        }}
                      >
                        {t("PERSONAL_DETAILS.SUCCESS")}
                      </Alert>
                    </Grid>
                  )}

                  {error && error_code === "406" && (
                    <Grid item xs={12}>
                      <Alert
                        severity="error"
                        sx={{
                          mt: "22px",
                        }}
                      >
                        {t("ERRORS.RESTAURANT_DONT_DELIVER")}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <LoadingButton
                    type="submit"
                    loading={isloading || isSubmitting}
                    disabled={!(isValid && isDirty)}
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={(theme) => ({
                      padding: "var(--1, 15px) var(--2, 0px)",
                      height: "var(--7, 56px)",
                      borderRadius: "var(--6, 48px)",
                      backgroundColor: `${theme.palette.primary.main}`,
                      fontSize: "17px",
                      fontWeight: 700,
                      letterSpacing: "0.2px",
                    })}
                  >
                    {t("PERSONAL_DETAILS.SAVE")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </div>
    </Layout>
  );
};

export default Profile;
