import { combineReducers } from 'redux'
// slices
import globalsReducer from './slices/globals'
import accountsReducer from './slices/accounts'
import citiesReducer from './slices/cities'
import restaurantsReducer from './slices/restaurants'
import subscriptionsReducer from './slices/subscriptions'
import paymentsReducer from './slices/payments'
import foodselectionReducer from './slices/foodselection'
import termsReducer from './slices/terms'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  keyPrefix: 'redux-',
  whitelist: [],
}

const rootReducer = combineReducers({
  // slices
  globals: globalsReducer,
  accounts: accountsReducer,
  cities: citiesReducer,
  restaurants: restaurantsReducer,
  subscriptions: subscriptionsReducer,
  payments: paymentsReducer,
  foodselection: foodselectionReducer,
  terms: termsReducer,
})

export { rootPersistConfig, rootReducer }
