import { DateTime } from 'luxon'

/**
 * Calculate if a given date is a locked day based on the specified rules.
 *
 * @param {string} date - The date string in the format 'YYYY-MM-DD' to be checked.
 * @returns {boolean} Returns true if the date is a locked day, otherwise false.
 */
export const calculateIfLockedDay = (date: string): boolean => {
  const kuwaitDateTime = DateTime.now().setZone('Asia/Kuwait').startOf('day').toJSDate()

  const selectedDate = DateTime.fromISO(date, { zone: 'Asia/Kuwait' }).toJSDate()

  // Check if the date is today
  if (selectedDate.toDateString() === kuwaitDateTime.toDateString()) {
    return true
  }

  // Calculate the difference in days between selectedDate and kuwaitDateTime
  const diffTime = Math.abs(selectedDate.getTime() - kuwaitDateTime.getTime())
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

  // Check if the date is tomorrow, after tomorrow, or after after tomorrow
  if (diffDays >= 1 && diffDays <= 2) {
    return true
  }

  // Check if the date is in the past from today
  if (selectedDate < kuwaitDateTime) {
    return true
  }

  // If none of the above conditions are met, return false
  return false
}
