import { TFunction } from "i18next";
export const appValidation = (t: TFunction) => {
  const startsWithDigits = process.env.REACT_APP_PHONE_REGEX_PATTERN || "4569";
  const startsWithDigitsNumber = Number(startsWithDigits);
  const pattern = new RegExp(`^[${startsWithDigitsNumber}][0-9]{7}$`);

  return {
    profile: {
      name: {
        type: "string",
        presence: { allowEmpty: false, message: t("ISREQUIRED") },
        format: {
          pattern: /^[a-zA-Z\u0600-\u06FF\s]*$/, // Note: Allow only alphabets
          message: "INVALID_NAME",
        },
        length: {
          minimum: 2,
          maximum: 40,
          message: t("ERROR.NAME_RANGE"),
        },
      },
      street: {
        type: "string",
        presence: { allowEmpty: false, message: t("ISREQUIRED") },
        length: {
          minimum: 1,
          maximum: 60,
          message: t("ERROR.PREFIX") + " 60 " + t("ERROR.DIGITS"),
        },
      },
      avenue: {
        presence: { allowEmpty: true, message: t("ISREQUIRED") },
        type: "string",
        length: {
          maximum: 30,
          message: t("ERROR.PREFIX") + " 30 " + t("ERROR.DIGITS"),
        },
      },
      block: {
        presence: { allowEmpty: false, message: t("ISREQUIRED") },
        type: "string",
        length: {
          minimum: 1,
          maximum: 4,
          message: t("ERROR.PREFIX") + " 4 " + t("ERROR.DIGITS"),
        },
      },
      house: {
        presence: { allowEmpty: false, message: t("ISREQUIRED") },
        type: "string",
        length: {
          minimum: 1,
          maximum: 5,
          message: t("ERROR.PREFIX") + " 5 " + t("ERROR.DIGITS"),
        },
      },
      area: {
        presence: {
          allowEmpty: false,
          message: t("PERSONAL_DETAILS.PLEASE_SELECT_CITY"),
        },
      },
    },
    recoverPassword: {
      password: {
        type: "string",
        presence: true,
        length: {
          minimum: 3,
          maximum: 50,
          message: "Password must be at 3 digits",
        },
      },
    },
    forgetPassword: {
      phoneNumber: {
        presence: true,
        type: "string",
        length: {
          is: 8,
          message: t("PHONE_NUMBER_ERROR"),
        },
        format: {
          pattern: pattern,
          message: t("INVALID_PHONE_NUMBER_ERROR"),
        },
      },
    },
    signUp: {
      phoneNumber: {
        type: "string",
        format: {
          pattern: pattern,
        },
        length: {
          minimum: 8,
          maximum: 8,
          message: "field must be 8 numbers",
        },
      },
      password: {
        type: "string",
        presence: true,
        length: {
          minimum: 3,
          maximum: 50,
          message: "Password must be at 3 digits",
        },
      },
    },
    signIn: {
      phoneNumber: {
        presence: true,
        type: "string",
        length: {
          is: 8,
          message: t("PHONE_NUMBER_ERROR"),
        },
        format: {
          pattern: pattern,
          message: t("INVALID_PHONE_NUMBER_ERROR"),
        },
      },
      password: {
        type: "string",
        presence: true,
        length: {
          minimum: 3,
          maximum: 50,
          message: t("PASSWORD_ERROR"),
        },
      },
    },
  };
};
