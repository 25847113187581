/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Dialog, DialogActions, FormControl, Stack, Tab, Typography, css, styled } from '@mui/material'

export const Navigation = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  ${({ theme: { palette, direction } }) => {
    return css`
      color: ${palette.secondary.main};
      .pointer {
        transform: rotate(${direction === 'rtl' ? '180deg' : '0deg'});
        cursor: pointer;
      }
    `
  }}
`

export const Title = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  color: #000;
`

export const Main = styled(Stack) <any>`
  min-height: calc(100vh - 64px);
  position: relative;
  .MuiFormHelperText-root:not(.Mui-error) {
    display: none;
  }
  .MuiTextField-root {
    margin-top: 20px;
  } 
`

export const PageWrapper = styled(Stack)`
  // overflow: auto;
`
interface IContent {
  contentheight: number
  isIndexPage: number
}
export const Content = styled(Box)<IContent>(({ contentheight, isIndexPage }) => ({
  position: 'relative',
  minHeight: `calc(100vh - ${contentheight}px)`,
  paddingBottom: isIndexPage ? '0' : '40px',
}))

export const PriceWrapper = styled(Box) <any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 88px;
  min-height: 88px;
  max-width: 100%;
  z-index: 1;
  ${({ theme: { palette, direction }, onMobile }) => css`
    min-height: ${onMobile ? '130px' : '88px'};
    height: ${onMobile ? '130px' : '88px'};
    border-top: 1px solid ${palette.secondary.light};
    background: #f3f3f3;
    & label {
      background: ${palette.primary.light};
      color: ${palette.primary.main};

      &.Mui-focused {
        color: ${palette.primary.main};
      }
    }
    .MuiOutlinedInput-notchedOutline legend {
      opacity: 0 !important;
      display: none !important;
    }
    .MuiInputLabel-root {
      right: ${direction === 'rtl' ? '24px' : 'unset'};
      left: ${direction === 'rtl' ? 'unset' : '0px'};
      width: fit-content;
      background: #f3f3f3;
      padding: 2px;
      margin-top: -4px;
    }
    .MuiFormHelperText-root:not(.Mui-error) {
      display: none;
    }
    .MuiTextField-root {
      margin-top: 20px;
    }
    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
      padding-left: ${direction === 'rtl' ? '32px' : '12px'};
      padding-right: ${direction === 'rtl' ? '12px' : '32px'};
    }
    .MuiSvgIcon-root.MuiSelect-icon {
      left: ${direction === 'rtl' ? '8px' : 'unset'};
      right: ${direction === 'rtl' ? 'unset' : '8px'};
    }
  `}
`

export const StyledTab = styled(Tab)`
  ${({ theme: { palette } }) => css`
    color: ${palette.secondary.dark};
    &.Mui-selected {
      border-radius: 5px;
      font-weight: bold;
      color: ${palette.primary.main};
    }
  `}
`

export const StyledFormControl = styled(FormControl) <any>`
  ${({ theme, onMobile }) => css`
    max-width: ${onMobile ? '100%' : '340px'};
  `}
  width: 100%;
  min-width: 230px;
  fieldset {
    border: 1px solid ${({ theme: { palette } }) => palette.primary.main};
  }
`

export const StyledButton = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
  font-size: 20px;
  text-transform: none;
  height: 52px;
  box-sizing: border-box;
  min-width: 100px;
`

export const Flex = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-direction: ${({ theme: { direction } }) => (direction === 'rtl' ? 'row-reverse' : 'row')};
  flex-wrap: wrap;
`

export const StyledActions = styled(DialogActions)`
  padding-bottom: 16px;
`

export const DialogButton = styled(Button)`
  color: #000;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 16px;
`

export const StyledDialog = styled(Dialog)`
  .css-78mkp9-MuiPaper-root-MuiDialog-paper {
    min-width: 300px;
  }
`
