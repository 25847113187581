import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Home from "../views/Home";
import RestaurantPlanFoods from "../views/RestaurantPlanFoods";
import RestaurantPlans from "../views/RestaurantPlans";

import { useIsAuthenticated } from "../hooks";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  cleanUserData,
  me,
  verifyAccessTokenAndRefil,
} from "../store/slices/accounts";
import ApplePayVerificationFile from "../views/ApplePayVerification";
import AreaVerificationError from "../views/AreaVerificationError";
import AuthRoutes from "../views/Auth";
import DetailForm from "../views/Auth/DetailForm";
import CheckOut from "../views/Checkout";
import ContactRestaurant from "../views/ContactRestaurant";
import ContactUs from "../views/ContactUs";
import FoodSelection from "../views/FoodSelection";
import ForcedEnglish from "../views/ForcedEnglish";
import NotFoundView from "../views/NotFoundView";
import PaymentError from "../views/PaymentError";
import PaymentSuccess from "../views/PaymentSuccess";
import Profile from "../views/Profile";
import MySubscriptions from "../views/Profile/MySubscriptions";
import RestaurantPlanSchedule from "../views/RestaurantPlanSchedule";
import Settings from "../views/Settings";
import TermsPolicy from "../views/TermsPolicy";
import EnglishTermsConditions from "../views/TermsPolicy/English";

/**
 * Renders routes depending on Authenticated or Anonymous users
 */

const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { userData, redirectToCheckoutOnSuccess } = useAppSelector(
    (state) => state.accounts
  );

  const { isAuthenticated } = useIsAuthenticated();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // first check whether user is authenticated or not. If authenticated then verify the access token and refil the token. After refilling the token, get the user data again
    // clear the user data incase user is not authenticated
    if (isAuthenticated) {
      let userDataFromStorage = JSON.parse(
        localStorage.getItem("user") || "{}"
      );
      dispatch(verifyAccessTokenAndRefil(userDataFromStorage));
      if (userData?.token?.access) {
        dispatch(me(userData?.token?.access));
      } else {
        userDataFromStorage = JSON.parse(localStorage.getItem("user") || "{}");
        dispatch(me(userDataFromStorage?.token?.access));
      }
    } else {
      dispatch(cleanUserData());
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route
        path="/.well-known/apple-developer-merchantid-domain-association"
        element={<ApplePayVerificationFile />}
      />
      <Route path="/en" element={<ForcedEnglish />} />
      <Route path="/restaurants" element={<Navigate to="/" replace />} />
      <Route
        path="/restaurants/:slug/:planID"
        element={<RestaurantPlanFoods />}
      />
      <Route path="/restaurants/:slug" element={<RestaurantPlans />} />
      <Route
        path="/restaurant/:slug/:planID/schedule"
        element={<RestaurantPlanSchedule />}
      />
      <Route
        path="/area-verification-error"
        element={<AreaVerificationError />}
      />

      <Route path="/terms-policy" element={<TermsPolicy />} />
      <Route path="/terms-policy/en" element={<EnglishTermsConditions />} />
      <Route path="/contact-us" element={<ContactUs />} />

      <Route path="/checkout/:slug?/:subscriptionID?" element={<CheckOut />} />

      <Route path="/food-selection/:slug" element={<FoodSelection />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-error" element={<PaymentError />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/my-subscriptions" element={<MySubscriptions />} />
      <Route path="/contact-restaurant" element={<ContactRestaurant />} />
      <Route
        path="auth/*"
        element={
          isAuthenticated ? (
            redirectToCheckoutOnSuccess ? (
              <Navigate to="/checkout" />
            ) : (
              <Navigate to="/" replace />
            )
          ) : (
            <AuthRoutes />
          )
        }
      />
      <Route
        path="/personal-details"
        element={isAuthenticated ? <Navigate to="/" replace /> : <DetailForm />}
      />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
};
export default AppRoutes;
