import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Layout } from "../../layout/Layout2";
import { useAppSelector } from "../../store/hooks";
import { localStorageSet } from "../../utils/localStorage";

const English: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { terms, isLoading } = useAppSelector((state) => state.terms);

  useEffect(() => {
    // Change user language to English
    localStorageSet("i18nextLng", "en");
  }, [i18n, location]);

  return (
    <Layout title={t("TERMS_AND_POLICY")} isLoading={isLoading}>
      <Helmet>
        <title>
          Terms and Conditions | Eshtrakaat for Subscriptions Restaurants
        </title>
        <meta
          name="description"
          content="Please read our terms and conditions carefully as it sets out the terms of a legally binding agreement between you (the reader) and Eshtrakaat for Subscriptions Restaurants Website/App"
        />
      </Helmet>

      <div dangerouslySetInnerHTML={{ __html: terms.en }} />
    </Layout>
  );
};

export default English;
