import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Layout } from "../../layout/Layout2";
import { useAppSelector } from "../../store/hooks";

const Arabic = () => {
  const { t } = useTranslation();
  const { terms, isLoading } = useAppSelector((state) => state.terms);

  return (
    <Layout title={t("TERMS_AND_POLICY")} isLoading={isLoading}>
      <Helmet>
        <title>الشروط والاحكام | اشتراكات للمطاعم الصحية</title>
        <meta
          name="description"
          content="يجب على كل مستخدمي موقع وتطبيق اشتراكات قراءة صفحة الشروط والأحكام، وبذلك يكونون قد أقرًوا بأنهم وافقوا على كافة بنود هذه الصفحة بالإضافة إلى قوانين دولة الكويت "
        />
      </Helmet>

      <div dangerouslySetInnerHTML={{ __html: terms.ar }} />
    </Layout>
  );
};

export default Arabic;
