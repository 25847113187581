import { useEffect } from 'react'
import Layout1 from '../../layout/Layout1'
import { useTranslation } from 'react-i18next'

const ContactUs = () => {
  const { t } = useTranslation()
  const whatsappNumber = '+96597002008'
  const message = t('CONACT_US_WHATSAAP_MESSAGE')

  useEffect(() => {
    const encodedMessage = encodeURIComponent(message)
    const whatsappAPIURL = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`
    // Add a state object to the history entry
    window.history.pushState({ redirectToHome: true }, '', '/')

    // Redirect to WhatsApp
    window.location.href = whatsappAPIURL
  }, [])

  useEffect(() => {
    // Listen for changes in the browser's history
    const handleHistoryChange = (event: PopStateEvent) => {
      // Check if the user is coming back from WhatsApp
      if (event.state && event.state.redirectToHome) {
        // Redirect the user to the home page
        window.location.href = '/'
      }
    }

    // Add the history change listener
    window.addEventListener('popstate', handleHistoryChange)

    // Remove the listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleHistoryChange)
    }
  }, [])

  return <Layout1 title={t('CONTACT_US')} withFooter isLoading={true}></Layout1>
}

export default ContactUs
