import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { validate } from "validate.js";

// Components
// Utils and Hooks
import { ObjectPropByName, convertArabicToWestern } from "../../../utils";
import { SHARED_CONTROL_PROPS, eventPreventDefault } from "../../../utils/form";
import { generateValidNumber } from "../../../utils/generateValidNumber";
// Styles
// Types
import { LoginFormErrors, LoginParams } from "../../../types/accounts";

import CustomSwipeableDrawer from "../../../components/CustomSwipeableDrawer";
import { Layout } from "../../../layout/Layout2";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  cleanUserData,
  login,
  resetAccountsFormsState,
  setRedirectToCheckoutOnSuccess,
} from "../../../store/slices/accounts";
import { resetInitialState } from "../../../store/slices/globals";
import { actions } from "../../../store/slices/restaurants";
import { appValidation } from "../../../utils/appValidation";
import ForgetPassword from "../Recovery/ForgetPassword";
import RecoveryPassword from "../Recovery/RecoveryPassword";
import VerifyOtp from "../Recovery/VerifyOtp";

import { ReactComponent as PhoneIcon } from "../../../assets/icons/call.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { ReactComponent as PasswordHideIcon } from "../../../assets/icons/password_hide.svg";
import { ReactComponent as PasswordVisibleIcon } from "../../../assets/icons/password_visible.svg";

interface FormStateValues {
  phoneNumber: string;
  password: string;
}

const Login = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { signIn } = appValidation(t);
  const [openForgetPwdPopup, setOpenForgetPwdPopup] = useState(false);
  const [resetPwdStep, setResetPwdStep] = useState(0);
  const [count, setCount] = useState<number>(3);

  const { otpSendSuccess } = useAppSelector((state) => state.accounts);

  const handleOpenForgetPwdPopup = () => setOpenForgetPwdPopup(true);

  const handleCloseForgetPwdPopup = () => setOpenForgetPwdPopup(false);

  const {
    isLoginLoading,
    loginError,
    loginSuccess,
    userData,
    success,
    redirectToCheckoutOnSuccess,
  } = useAppSelector((state) => state.accounts);

  useEffect(() => {
    dispatch(cleanUserData());
    dispatch(resetAccountsFormsState());
    dispatch(resetInitialState());
  }, []);

  const [state, setState] = useState<FormStateValues>({
    phoneNumber: "",
    password: "",
  });

  const [errors, setErrors] = useState<LoginFormErrors>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  const params: LoginParams = {
    phone_number: state.phoneNumber,
    password: state.password,
  };

  const handleFormSubmit = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();
      dispatch(login(params));

      if (loginSuccess) {
        dispatch(actions.clearRestaurants());
        if (redirectToCheckoutOnSuccess) {
          dispatch(setRedirectToCheckoutOnSuccess(false));
          navigate("/checkout");
        } else {
          navigate("/");
        }
      }
    },
    [state, navigate, dispatch, loginError, loginSuccess]
  );

  const handleCloseError = useCallback(() => setError(undefined), []);

  const onFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const valid = (signIn as ObjectPropByName)[name];
    const err = validate({ [name]: value }, { [name]: valid });
    const errs = { ...errors, ...err };
    if (!err) {
      delete errs[name];
    }
    setErrors({ ...errs });
  };

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newPassword = value.replace(/\s/g, "");
    setState((prev: FormStateValues) => {
      return {
        ...prev,
        [name]:
          name === "phoneNumber"
            ? convertArabicToWestern(newPassword)
            : newPassword,
      };
    });
  };

  const fieldGetError = (key: keyof ObjectPropByName, label: string) => {
    const errorMessage = (errors as ObjectPropByName)[key]?.[0];
    return errorMessage?.replace(label, "");
  };

  const fieldHasError = (key: keyof ObjectPropByName) => {
    return (errors as ObjectPropByName)[key] ? true : false;
  };

  const isValid = validate(state, signIn) ? false : true;

  useEffect(() => {
    if (openForgetPwdPopup) {
      if (resetPwdStep === 0 && otpSendSuccess) {
        setResetPwdStep(1);
      } else if (resetPwdStep === 1 && success) {
        setResetPwdStep(2);
      }
    }
  }, [otpSendSuccess, count, success]);

  return (
    <Layout title={t("LOGIN")}>
      {!openForgetPwdPopup ? (
        <Helmet>
          <title>
            {isArabic
              ? "تسجيل الدخول | اشتراكات للمطاعم الصحية"
              : "Login  | Eshtrakaat for Subscriptions Restaurant"}
          </title>
          <meta
            name="description"
            content={
              isArabic
                ? "قم بتسجيل الدخول من خلال هذه الصفحة بواسطة رقم هاتفك المسجل وكلمة السر الخاصة بك | اشتراكات للمطاعم الصحية"
                : "Login to your account from this page using your registered mobile number and password | Eshtrakaat for Subscriptions Restaurants"
            }
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>
            {isArabic
              ? "تغيير كلمة السر | اشتراكات للمطاعم الصحية"
              : "Password Reset | Eshtrakaat for Subscriptions Restaurants"}
          </title>
          <meta
            name="description"
            content={
              isArabic
                ? " يمكنك تغيير كلمة السر الخاصة بحسابك من خلال هذه الصفحة  | اشتراكات للمطاعم الصحية"
                : " Reset your account password from this page | Eshtrakaat for Subscriptions Restaurants"
            }
          />
        </Helmet>
      )}

      <form
        onSubmit={handleFormSubmit}
        style={{
          display: "flex",
          flexGrow: "1",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: "1",
            height: "100%",
            maxWidth: "600px",
          }}
        >
          <Stack direction="column" mt="40px">
            <Box rowGap="12px">
              <Typography
                sx={{ fontSize: "24px", fontWeight: 700, marginBottom: "12px" }}
              >
                {t("LOGIN")}
              </Typography>
              <Typography
                sx={{ fontSize: "13px", fontWeight: 400, color: "#3F526F" }}
              >
                {t("LOGIN.MESSAGE")}
              </Typography>
            </Box>

            <Box mt="40px">
              <TextField
                sx={{ input: { color: "#556885" } }}
                required
                type="text"
                label={t("PHONE_NUMBER")}
                name="phoneNumber"
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: 8,
                  inputMode: "numeric",
                }}
                value={generateValidNumber(state.phoneNumber)}
                error={fieldHasError("phoneNumber")}
                helperText={
                  fieldGetError("phoneNumber", "Phone number") || null
                }
                id="phoneNumber"
                autoComplete="new-phone-number"
                onBlur={onFieldBlur}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon
                        style={{
                          transform:
                            i18n.dir() === "ltr" ? "scaleX(-1)" : "scaleX(1)",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{ mt: "16px", input: { color: "#556885" } }}
                required
                type={showPassword ? "text" : "password"}
                label={t("PASSWORD")}
                name="password"
                id="password"
                autoComplete="new-password"
                value={state.password}
                error={fieldHasError("password")}
                helperText={fieldGetError("password", "Password") || null}
                onBlur={onFieldBlur}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ bgcolor: "transparent" }}
                        aria-label="toggle password visibility"
                        onClick={handleShowPasswordClick}
                        onMouseDown={eventPreventDefault}
                      >
                        {!showPassword ? (
                          <PasswordHideIcon />
                        ) : (
                          <PasswordVisibleIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container justifyContent="flex-start" alignItems="center">
                <Button
                  disableElevation
                  style={{ color: "#10A67B" }}
                  variant="text"
                  color="inherit"
                  onClick={handleOpenForgetPwdPopup}
                >
                  {t("FORGET_PASSWORD")}
                </Button>
              </Grid>
            </Box>
            {error ? (
              <Alert severity="error" onClose={handleCloseError}>
                {error}
              </Alert>
            ) : null}

            <Grid item xs={12}>
              {loginError && (
                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                  {t("LOGIN.ERROR")}
                </Alert>
              )}
            </Grid>
          </Stack>

          <Box>
            <Box>
              <LoadingButton
                type="submit"
                loading={isLoginLoading}
                disabled={!isValid}
                fullWidth
                variant="contained"
                size="large"
                sx={(theme) => ({
                  padding: "var(--1, 15px) var(--2, 0px)",
                  height: "var(--7, 56px)",
                  borderRadius: "var(--6, 48px)",
                  backgroundColor: `${theme.palette.primary.main}`,
                  fontSize: "17px",
                  fontWeight: 700,
                  letterSpacing: "0.2px",
                })}
              >
                {t("LOGIN")}
              </LoadingButton>
            </Box>
            <Box sx={{ textAlign: "center" }} mt={0}>
              {t("LOGIN.HAVE_ACCOUNT")}
              <Button
                variant="text"
                color="primary"
                component={Link}
                to="/personal-details"
                sx={{ fontWeight: 700 }}
              >
                {t("LOGIN.CREATE_ACCOUNT")}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
      <CustomSwipeableDrawer
        open={openForgetPwdPopup}
        onOpen={handleOpenForgetPwdPopup}
        onClose={handleCloseForgetPwdPopup}
        height={
          resetPwdStep === 0 ? "365px" : resetPwdStep === 1 ? "458px" : "365px"
        }
      >
        {resetPwdStep === 0 ? (
          <ForgetPassword />
        ) : resetPwdStep === 1 ? (
          <VerifyOtp />
        ) : (
          <RecoveryPassword count={count} setCount={setCount} />
        )}
      </CustomSwipeableDrawer>
    </Layout>
  );
};

export default Login;
