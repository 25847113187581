/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Button, CardContent, Grid, TextField } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { validate } from "validate.js";
// Components
// Utils and hooks
import { ObjectPropByName } from "../../../utils";
import { SHARED_CONTROL_PROPS } from "../../../utils/form";
// Styles

import { RecoveryPassFormErrors } from "../../../types/accounts";
// Redux
import {
  DrawerHeading,
  DrawerText,
} from "../../../components/CustomSwipeableDrawer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  sendVerificationCode,
  verifyOTPForPassReset,
} from "../../../store/slices/accounts";
import getContactUsLink from "../../../utils/getContactUsLink";

const blinkAnimation = css`
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

interface FormStateValues {
  otp: number | undefined;
}

const VerifyOtp = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState(60); // 60 seconds for 1 minute

  const validation = {
    otp: {
      presence: true,
      format: {
        pattern: "[0-9]*",
        message: t("OTP_ERROR"),
      },
      length: {
        is: 4,
        message: t("OTP_ERROR"),
      },
    },
  };
  const {
    isloading,
    success,
    error: verificationError,
    otpSendloading,
    otpSendError,
    otpSendSuccess,
    phone_number,
    throttleError,
  } = useAppSelector((state) => state.accounts);

  const [resendDisabled, setResendDisabled] = useState<boolean>(false);
  const [isBlinking, setIsBlinking] = useState<boolean>(false);

  const [error, setError] = useState<string>();
  const [state, setState] = useState<FormStateValues>({
    otp: undefined,
  });

  const [errors, setErrors] = useState<RecoveryPassFormErrors>({});

  const onFieldBlur = (event: any) => {
    const { name, value } = event.target;
    const valid = (validation as ObjectPropByName)[name];
    const err = validate({ [name]: value }, { [name]: valid });
    const errs = { ...errors, ...err };
    if (!err) {
      delete errs[name];
    }
    setErrors({ ...errs });
  };
  const onFieldChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "otp" && value.length > 4) return;
    if (name === "otp" && !/^[0-9]*$/.test(value)) return;

    const limit = (validation as ObjectPropByName)[name]?.length?.is;
    if (value?.length <= limit) {
      setState((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };
  const fieldGetError = (key: any) => {
    return (errors as ObjectPropByName)[key]?.[0];
  };
  const fieldHasError = (key: any) => {
    return (errors as ObjectPropByName)[key] ? true : false;
  };
  const isValid = validate(state, validation) ? false : true;

  const handleResendOtp = () => {
    setResendDisabled(true);
    setIsBlinking(true);

    // Make API call to resend OTP
    dispatch(sendVerificationCode(phone_number as string));

    setTimeout(() => {
      setResendDisabled(false);
      setIsBlinking(false);
    }, 12000); // 12 seconds
  };

  const handleFormSubmit = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();
      // Make verification API call and navigate to next RecoveryPassword page
      const otpString = state.otp?.toString();
      dispatch(
        verifyOTPForPassReset(phone_number as string, otpString as string)
      );
    },
    [dispatch, state.otp, phone_number]
  );
  const handleCloseError = useCallback(() => setError(undefined), []);

  useEffect(() => {
    // Set up a timer only if it's greater than 0
    const countdown =
      timer > 0
        ? setInterval(() => {
            setTimer(timer - 1);
          }, 1000)
        : null;

    // Clear interval on component unmount
    if (countdown) {
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const handleContactUsClick = () => {
    const message = t("CONTACT_US_WHATSAPP_OTP_FORGOT_PASSWORD");
    const whatsappAPIURL = getContactUsLink({ message });
    window.open(whatsappAPIURL, "_blank");
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      style={{
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CardContent>
        <DrawerHeading marginTop="48px" marginBottom="8px">
          {t("VERIFY_OTP")}
        </DrawerHeading>
        <DrawerText marginBottom="40px">{t("VERIFY_OTP_MESSAGE")}</DrawerText>
        {/* {otpSendError && <Description>{t("OTP_MESSAGE_ON_FAIL")}</Description>} */}
        {/* {otpSendSuccess && <Description>{t("OTP_MESSAGE")}</Description>} */}
        <Grid container mb="16px">
          <TextField
            required
            label={t("ENTER_OTP")}
            name="otp"
            type="tel"
            value={state.otp}
            inputProps={{
              pattern: "[0-9]*",
              maxLength: 6,
              inputMode: "numeric",
            }}
            error={fieldHasError("otp")}
            helperText={fieldGetError("otp") || null}
            onChange={onFieldChange}
            onBlur={onFieldBlur}
            id="otp"
            {...SHARED_CONTROL_PROPS}
          />
        </Grid>
        {error ? (
          <Alert severity="error" onClose={handleCloseError}>
            {t("OTP_LIMIT_ERROR")}
          </Alert>
        ) : null}
        {verificationError && (
          <Alert severity="error">{t("INCORRECT_OTP_ERROR")}</Alert>
        )}
        {throttleError && <Alert severity="error">{t("THROTTLE_ERROR")}</Alert>}

        <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
          {`${Math.floor(timer / 60)}:${
            timer % 60 < 10 ? `0${timer % 60}` : timer % 60
          }`}

          {/* {t("DIDNT_RECIVED_OTP")} */}
          <Button
            variant="text"
            color="primary"
            onClick={handleResendOtp}
            disabled={resendDisabled || timer !== 0}
            sx={{
              py: 0,
              animation: isBlinking ? `${blinkAnimation} 1s infinite` : "none",
            }}
          >
            {t("RESEND_OTP")}
          </Button>
        </Grid>
        <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
          <Button onClick={handleContactUsClick} color="primary">
            {t("CONTACT_US")}
          </Button>
        </Grid>
        <Grid container mt={verificationError ? "2px" : "32px"}>
          <LoadingButton
            type="submit"
            loading={otpSendloading || isloading}
            disabled={!isValid}
            fullWidth
            variant="contained"
            size="large"
            sx={(theme) => ({
              padding: "var(--1, 15px) var(--2, 0px)",
              height: "var(--7, 56px)",
              borderRadius: "var(--6, 48px)",
              backgroundColor: `${theme.palette.primary.main}`,
              fontSize: "17px",
              fontWeight: 700,
              letterSpacing: "0.2px",
            })}
          >
            {t("VERIFY")}
          </LoadingButton>
        </Grid>
      </CardContent>
    </form>
  );
};

export default VerifyOtp;
