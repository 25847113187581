import { City } from '../types/cities'

/**
 * Get options for cities to be used in Autocomplete component.
 *
 * @param cities - Array of city objects. Also being used as areas.
 * @param isArabicMode - Boolean indicating whether the Arabic mode is enabled.
 * @returns Array of options for cities, with label and value properties.
 */
export function getCitiesOptions(cities: City[], isArabicMode: boolean) {
  // Return name as label if not arabic mode
  // Return arabic name as label if arabic mode
  // Return id as value
  return cities.map((city) => ({
    label: isArabicMode ? city.name_arabic : city.name,
    value: city.id,
  }))
}
