import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ForcedEnglish: React.FC = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (i18n.language !== 'en') {
      i18n.changeLanguage('en')
      // Set the key i18nextLng to en, to prevent i18next-browser-languagedetector
      // from returning en-US
      localStorage.setItem('i18nextLng', 'en')
    }
    navigate('/')
  }, [i18n, navigate])

  return null
}

export default ForcedEnglish
