import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../'
import { TermsAndconditions } from '../../types/terms'
import axios from '../../utils/axios'

interface State {
    isLoading: boolean
    success: boolean
    error: undefined | boolean
    terms: TermsAndconditions
}

const initialState: State = {
    isLoading: false,
    success: false,
    error: false,
    terms: {
        en: '',
        ar: '',
    },
}

// Helper function to reset the state
const resetState = (state: State, isLoading: boolean, success: boolean, error: boolean | undefined = false) => {
    state.isLoading = isLoading
    state.success = success
    state.error = error
}

const slice = createSlice({
    name: 'terms',
    initialState,
    reducers: {
        loading(state) {
            resetState(state, true, false)
        },
        success(state, action: PayloadAction<TermsAndconditions>) {
            resetState(state, false, true)
            state.terms = action.payload
        },
        fail(state, action: PayloadAction<boolean>) {
            resetState(state, false, false, action.payload)
        },
    },
})

// Reducer
export default slice.reducer
export const { actions } = slice

// Actions
export const getTermsAndConditions = () => async (dispatch: AppDispatch) => {
    dispatch(actions.loading())
    try {
        const response = await axios.get('/api/v1/terms/')
        dispatch(actions.success(response.data))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        dispatch(actions.fail(error))
    }
}