import { createSlice } from '@reduxjs/toolkit'

import { AppDispatch } from '../'
import { City, GetCityError } from '../../types/cities'
import axios from '../../utils/axios'

// Define a type for the slice state
interface State {
  isLoading: boolean
  error: GetCityError | null
  success: boolean
  cities: City[] | []
}

const initialState: State = {
  isLoading: false,
  error: null,
  success: false,
  cities: [],
}

const slice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    loading(state) {
      state.isLoading = true
      state.error = null
      state.success = false
    },
    // LOGIN
    success(state, action) {
      state.isLoading = false
      state.error = null
      state.success = true
      state.cities = action.payload
    },

    fail(state, action) {
      state.isLoading = false
      state.error = action.payload
      state.success = false
    },
  },
})

// Reducer
export default slice.reducer
export const { actions } = slice

// Actions

export const getCities = () => async (dispatch: AppDispatch) => {
  dispatch(actions.loading())
  try {
    const response = await axios.get('/api/v1/cities/')
    dispatch(actions.success(response.data))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    dispatch(actions.fail(error))
  }
}
