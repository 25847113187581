import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import { TabContext, TabPanel } from "@mui/lab";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getRestaurantPlanMenu,
  setCurrentPackageID,
} from "../../store/slices/restaurants";
// Components
// Types
import { Food } from "../../types/restaurant";
// Styles
// Dynamic Imports
import { t } from "i18next";
import { ReactComponent as ViewCalendarIcon } from "../../assets/icons/view_calendar.svg";
import foodPlaceHolder from "../../assets/illustrations/food_placeholder.svg";
import placeHolderRestaurantLogo from "../../assets/illustrations/placeholder_restaurant_logo.png";
import CheckoutFooter from "../../components/Footer/CheckoutFooter";
import { LazyImage } from "../../components/LazyImage";
import ReadMore from "../../components/ReadMore";
import { Layout } from "../../layout/Layout2";
import { setLogo } from "../../store/slices/globals";
import { resetExecutePaymentState } from "../../store/slices/payments";

const RestaurantPlanFoods: React.FC<{ allowSelect?: boolean }> = () => {
  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const navigate = useNavigate();
  const { slug, planID } = useParams();
  const {
    isLoading,
    planMenu: plan,
    currentPackageIDInMenue,
  } = useAppSelector((state) => state.restaurants);
  const { userData } = useAppSelector((state) => state.accounts);

  useEffect(() => {
    localStorage.removeItem("executeRequestPaymentData");
    localStorage.removeItem("checkoutData");
    dispatch(resetExecutePaymentState());

    if (plan) {
      const logo = plan?.restaurant_logo;
      if (logo) {
        dispatch(setLogo(logo as string));
      }
    }
  }, [plan]);

  useEffect(() => {
    dispatch(
      getRestaurantPlanMenu(
        slug as string,
        planID as string,
        userData?.token?.access
      )
    );
  }, []);

  const restaurantName = isArabic
    ? plan?.restaurant_name_arabic
    : plan?.restaurant_name;
  const title = (isArabic ? plan?.title_arabic : plan?.title) || "";
  const description = (
    isArabic ? plan?.description_arabic : plan?.description
  )?.replace(", ", " • ");

  const logo = plan?.restaurant_logo || placeHolderRestaurantLogo;

  const [selectedMealIndex, setValue] = React.useState(0);
  const selectedMeal = plan?.meals?.[selectedMealIndex];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const setSelectedPackageId = (id: number) => {
    localStorage.setItem("selected_package_id", `${id}`);
    dispatch(setCurrentPackageID(id));
  };

  return (
    <>
      <Layout
        title={title}
        isLoading={isLoading}
        restaurantLogo={logo}
        footer={
          <CheckoutFooter
            planId={planID || ""}
            packs={plan?.packages || []}
            restaurantSlug={plan?.restaurant_slug || ""}
            setSelectedPackageId={setSelectedPackageId}
            selectedPackageId={currentPackageIDInMenue}
          />
        }
        subTitle={description}
      >
        <Helmet>
          <title>
            {isArabic
              ? `مطعم ${restaurantName} | ${title} | اشتراكات`
              : `${restaurantName} | ${title} | Eshtrakaat for Subscriptions Restaurants`}
          </title>
          <meta
            name="description"
            content={
              isArabic
                ? "شاهد قائمة الوجبات المتنوعة  وقم بالإشتراك | اشتراكات للمطاعم الصحية"
                : "See the food menu options & then subscribe | Eshtrakaat for Subscriptions Restaurants"
            }
          />
        </Helmet>



   
        <TabContext value={selectedMealIndex.toString()}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000, // Adjust zIndex if necessary
          backgroundColor: "#f4f6f9", // Ensure background color is set
        }}
      >
        <TabPanel
          value={selectedMealIndex.toString()}
          sx={{
            p: "0",
            pt: "15px",
            overflowX: "hidden",
            overscrollBehavior: "contain",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Tabs
              value={selectedMealIndex}
              onChange={handleTabChange}
              TabIndicatorProps={{ sx: { display: "none" } }}
            >
              {plan?.meals?.map((m, i) => (
                <Tab
                  key={i}
                  label={
                    <Typography variant="h3">
                      {isArabic ? m.title_arabic : m.title}
                    </Typography>
                  }
                  value={i}
                />
              ))}

              
            <Button
              variant="contained"
              disableElevation
              sx={{
                borderRadius: "8px",
                backgroundColor: "rgba(208, 233, 229, 0.53)",
                px: "8px",
                py: "10px",
                "&:focus": {
                  background: "rgba(208, 233, 229, 0.53)",
                },
                "&:hover": {
                  background: "rgba(208, 233, 229, 0.53)",
                },
              }}
              onClick={() => {
                navigate(`/restaurant/${slug}/${planID}/schedule`);
              }}
            >
              <ViewCalendarIcon />
              <Typography
                variant="h4"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  marginInlineStart: "6px",
                })}
              >
                {t("VIEW_SCHEDULE")}
              </Typography>
            </Button>
            </Tabs>

          </Stack>
        </TabPanel>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <Foods foods={selectedMeal?.foods || []} />
      </Box>
    </Box>
  </TabContext>

      </Layout>
    </>
  );
};

function Foods({ foods }: { foods: Food[] }) {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <Grid container spacing={2}>
      {foods.map((food) => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={food.id}>
            <Paper
              elevation={0}
              key={food.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                p: "16px",
                gap: "11px",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "11px",
                }}
              >
                <LazyImage
                  alt={food.title}
                  image={food.photo || foodPlaceHolder}
                  placeHolder={foodPlaceHolder}
                  sx={{
                    height: "123px",
                    width: "131px",
                    minHeight: "123px",
                    minWidth: "131px",
                    maxWidth: "131px",
                  }}
                />
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  flexGrow={1}
                >
                  <Typography variant="h2" component="div">
                    {isArabic ? food.title_arabic : food.title}
                    <Typography
                      variant="h3"
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                        mt: "2px",
                      })}
                    >
                      <ReadMore maxCharacterCount={50}>
                        {isArabic ? food.description_arabic : food.description}
                      </ReadMore>
                    </Typography>
                  </Typography>
                </Stack>
              </Box>
              <Stack direction={"row"} gap={"7px"} sx={{ width: "100%" }}>
                <Macro title={t("CAL")} value={food.calories} />
                <Macro title={t("PROTEIN")} value={food.protein} />
                <Macro title={t("CARB")} value={food.carb} />
                <Macro title={t("FAT")} value={food.fat} />
              </Stack>
            </Paper>
          </Grid>
        );
      })}
      <Box minHeight="86px">&nbsp;</Box>
    </Grid>
  );
}

const Macro = ({ title, value }: { title: string; value: number | null }) => {
  if (!value) return null;

  return (
    <Typography
      sx={(theme) => ({
        background: theme.palette.background.default,
        color: theme.palette.text.secondary,
        py: "7px",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        width: "25%",
      })}
      variant="h4"
      component="div"
    >
      {`${value} ${title}`}
    </Typography>
  );
};

export default RestaurantPlanFoods;
