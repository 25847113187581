import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

// Components
import Snackbar from "../../../components/Snackbar/Snackbar";
// Utils and hooks
import { SHARED_CONTROL_PROPS, eventPreventDefault } from "../../../utils/form";
// Styles

import { ReactComponent as PhoneIcon } from "../../../assets/icons/call.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";

import {
  setPreviousPathInStore,
  signupRequest,
  updateTemporaryPersonalDetails,
} from "../../../store/slices/accounts";

import { Layout } from "../../../layout/Layout2";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resetInitialState } from "../../../store/slices/globals";

// Redux
import { Link } from "react-router-dom";
import { ReactComponent as PasswordHideIcon } from "../../../assets/icons/password_hide.svg";
import { ReactComponent as PasswordVisibleIcon } from "../../../assets/icons/password_visible.svg";

import CustomSwipeableDrawer from "../../../components/CustomSwipeableDrawer";
import { RHFTextField } from "../../../components/hook-form";
import FormProvider from "../../../components/hook-form/form-provider";
import { SignupParams, TemoraryUserData } from "../../../types/accounts";
import ConfirmOTP from "./ConfirmOTP";

const startsWithDigits = process.env.REACT_APP_PHONE_REGEX_PATTERN || "4569";
const startsWithDigitsNumber = Number(startsWithDigits);
const pattern = new RegExp(`^[${startsWithDigitsNumber}][0-9]{7}$`);

/**
 * Renders "Signup" view
 * url: /auth/signup
 */
const Signup = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const isArabicMode = i18n.language === "ar";
  const dispatch = useAppDispatch();
  const [openConfirmPwdPopup, setOpenConfirmPwdPopup] = useState(false);

  const handleOpenConfirmPwdPopup = () => {
    setOpenConfirmPwdPopup(true);
  };

  const handleCloseConfirmPwdPopup = () => {
    setOpenConfirmPwdPopup(false);
  };

  const {
    temporaryUserData,
    userData,
    signupRequestError,
    signupRequestSuccess,
    isSignupRequestLoading,
    throttleError,
    signupParams,
  } = useAppSelector((state) => state.accounts);

  const defaultValues = useMemo(() => {
    return {
      phoneNumber: signupParams?.phone_number || "",
      password: signupParams?.password || "",
    };
  }, [signupParams]);

  const SignupSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(`${t("PHONE_NUMBER")} ${t("ISREQUIRED")}`)
      .length(8, t("PHONE_NUMBER_ERROR") || "")
      .matches(pattern, t("INVALID_PHONE_NUMBER_ERROR") || ""),
    password: Yup.string()
      .required(`${t("PASSWORD")} ${t("ISREQUIRED")}`)
      .min(3, `${t("PASSWORD_ERROR")}`)
      .max(50, `${t("PASSWORD_ERROR")}`),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(SignupSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isLoading },
  } = methods;

  const onSubmit = handleSubmit(async (state, event) => {
    event?.preventDefault();
    const userDataDetails = { ...temporaryUserData };
    // Update userData.phone_number with the new phone number
    userDataDetails.phone_number = state.phoneNumber;

    const params: SignupParams = {
      phone_number: state.phoneNumber,
      password: state.password,
      // Basic Details
      name: userDataDetails.name || "",
      block: userDataDetails.block || "",
      street: userDataDetails.street || "",
      avenue: userDataDetails.avenue || "",
      house: userDataDetails.house || "",
      area: userDataDetails.area || 0,
    };

    // Here make signup request, This request will send OTP to users's number
    // If they don't have a verified account other wise it will login the user if password is correct
    dispatch(signupRequest(params));
    dispatch(setPreviousPathInStore(location?.pathname));
    dispatch(
      updateTemporaryPersonalDetails(userDataDetails as TemoraryUserData)
    );
  });

  useEffect(() => {
    if (signupRequestSuccess && userData && userData.status === "unverified") {
      handleOpenConfirmPwdPopup();
    }
  }, [signupRequestSuccess, userData]);

  useEffect(() => {
    if (signupRequestError) {
      if (
        signupRequestError ===
        "You already have an verified account. Please login."
      ) {
        handleAlreadyVerifiedAccount();
      }
    }
  }, [signupRequestError]);

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string>();

  const handleAlreadyVerifiedAccount = useCallback(() => {
    setError(
      isArabicMode
        ? "لديك حساب موثق بالفعل. يرجى تسجيل الدخول."
        : "You already have an verified account. Please login."
    );
  }, []);

  const [openSuccess, setOpenSuccess] = React.useState(false);

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  const handleCloseError = useCallback(() => setError(undefined), []);

  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  useEffect(() => {
    dispatch(resetInitialState());
  }, []);

  const horizontal = i18n.dir() === "rtl" ? "left" : "right";

  return (
    <Layout title={t("SIGNUP.CREATE_ACCOUNT")}>
      {!openConfirmPwdPopup ? (
        <Helmet>
          <title>
            {isArabicMode
              ? " التسجيل | انشاء حساب | اشتراكات للمطاعم الصحية"
              : " Register account | Eshtrakaat for Subscriptions Restaurants"}
          </title>
          <meta
            name="description"
            content={
              isArabicMode
                ? " سجل حساب جديد باستخدام رقم هاتفك واختيار كلمة سر جديدة | اشتراكات للمطاعم الصحية"
                : "Register new account | Eshtrakaat for Subscriptions Restaurants"
            }
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>
            {isArabicMode
              ? " التسجيل | التأكيد | اشتراكات للمطاعم الصحية"
              : " Register | confirm phone| Eshtrakaat for Subscriptions Restaurants"}
          </title>
          <meta
            name="description"
            content={
              isArabicMode
                ? " سجل حساب جديد وقم بتأكيد رقم هاتفك | اشتراكات للمطاعم الصحية"
                : "Register new account | Eshtrakaat for Subscriptions Restaurants"
            }
          />
        </Helmet>
      )}

      <div
        style={{
          display: "flex",
          flexGrow: "1",
          justifyContent: "center",
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexGrow: "1",
              height: "100%",
              maxWidth: "600px",
            }}
          >
            <Stack direction="column" mt="40px">
              <Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                    marginBottom: "12px",
                  }}
                >
                  {t("SIGNUP.CREATE_ACCOUNT")}
                </Typography>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: 400, color: "#3F526F" }}
                >
                  {t("SIGNUP.CREATE_ACCOUNT.MESSAGE")}
                </Typography>
              </Box>

              <Box mt="40px">
                <RHFTextField
                  type="text"
                  required
                  label={t("PHONE_NUMBER")}
                  name="phoneNumber"
                  {...SHARED_CONTROL_PROPS}
                  sx={{ input: { color: "#556885" } }}
                  id="phoneNumber"
                  inputProps={{
                    pattern: "[0-9]*",
                    maxLength: 8,
                    inputMode: "numeric",
                  }}
                  {...SHARED_CONTROL_PROPS}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon
                          style={{
                            transform:
                              i18n.dir() === "ltr" ? "scaleX(-1)" : "scaleX(1)",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                <RHFTextField
                  sx={{ mt: "16px", input: { color: "#556885" } }}
                  required
                  label={t("PASSWORD")}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  autoComplete="new-password"
                  {...SHARED_CONTROL_PROPS}
                  InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ bgcolor: "transparent" }}
                          aria-label="toggle password visibility"
                          onClick={handleShowPasswordClick}
                          onMouseDown={eventPreventDefault}
                        >
                          {!showPassword ? (
                            <PasswordHideIcon />
                          ) : (
                            <PasswordVisibleIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    color: "#556885",
                    mt: "16px",
                    width: "100%",
                  }}
                >
                  <Typography fontSize="14px">
                    {t("SIGNUP.AGREE")}{" "}
                    <Link
                      to="/terms-policy"
                      style={{ color: "#10A67B", textDecoration: "none" }}
                    >
                      {t("SIGNUP.TERMS_AND_CONDITIONS")}
                    </Link>
                  </Typography>
                </Box>
              </Box>

              {error ? (
                <Alert severity="error" onClose={handleCloseError}>
                  {error}
                </Alert>
              ) : null}

              {throttleError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {t("THROTTLE_ERROR")}
                </Alert>
              )}
            </Stack>

            <Box>
              <Box>
                <LoadingButton
                  color="primary"
                  loading={isLoading || isSubmitting || isSignupRequestLoading}
                  disabled={!isValid}
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={(theme) => ({
                    padding: "var(--1, 15px) var(--2, 0px)",
                    height: "var(--7, 56px)",
                    borderRadius: "var(--6, 48px)",
                    backgroundColor: `${theme.palette.primary.main}`,
                    fontSize: "17px",
                    fontWeight: 700,
                    letterSpacing: "0.2px",
                  })}
                >
                  {t("SIGNUP.SIGN_UP")}
                </LoadingButton>
              </Box>
              <Box sx={{ textAlign: "center" }} mt={0}>
                {t("SIGNUP.ALREADY_ACCOUNT")}
                <Button
                  variant="text"
                  color="primary"
                  component={Link}
                  to="/auth/login"
                >
                  {t("LOGIN")}
                </Button>
              </Box>

              <Snackbar
                handleOpen={openSuccess}
                onClose={handleCloseSuccess}
                message={t("YOUR_NUMBER_IS_ALREADY_REGISTERED")}
                anchorOrigin={{ vertical: "top", horizontal }}
              />
            </Box>
          </Box>
        </FormProvider>
      </div>
      <CustomSwipeableDrawer
        open={openConfirmPwdPopup}
        onOpen={handleOpenConfirmPwdPopup}
        onClose={handleCloseConfirmPwdPopup}
        height="500px"
      >
        <ConfirmOTP />
      </CustomSwipeableDrawer>
    </Layout>
  );
};

export default Signup;
