import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useUpdateDeepLink() {
  const location = useLocation();

  useEffect(() => {
    const metaTag: unknown = document.querySelector(
      'meta[name="apple-itunes-app"]'
    );

    if (metaTag instanceof HTMLMetaElement) {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const deepLink = `${protocol}//${host}${location.pathname}${location.search}`;
      metaTag.content = `app-id=6470207213, app-argument=${deepLink}`;
    }
  }, [location]); // This effect runs whenever the location changes
}
