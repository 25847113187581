import { useState } from "react";
import { CardMedia, CardMediaProps } from "@mui/material";

export const LazyImage = (
  props: CardMediaProps & { placeHolder: string; alt?: string }
) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <CardMedia {...props} image={props.placeHolder}>
      <img
        src={props.image || props.src || props.placeHolder}
        alt={props.alt}
        style={{
          display: loaded ? "block" : "none",
          backgroundSize: "cover",
          borderRadius: "8px",
          width: "100%",
          height: "100%",
        }}
        onLoad={() => setLoaded(true)}
      />
      {props.children}
    </CardMedia>
  );
};
