import dayjs from "dayjs";
import { PlanDate } from "../types/restaurant";

import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

// Load plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const IS_SERVER = typeof window === "undefined";
export const IS_BROWSER =
  typeof window !== "undefined" && typeof window?.document !== "undefined";
/* eslint-disable no-restricted-globals */
export const IS_WEBWORKER =
  typeof self === "object" &&
  self.constructor &&
  self.constructor.name === "DedicatedWorkerGlobalScope";
/* eslint-enable no-restricted-globals */

export function getCurrentVersion(): string {
  return (
    process.env?.npm_package_version ??
    process.env.REACT_APP_VERSION ??
    "unknown"
  );
}

export function getCurrentEnvironment(): string {
  return process.env?.NODE_ENV ?? "development";
}

export function convertArabicToWestern(str: string): string {
  const arabicNumbers = "٠١٢٣٤٥٦٧٨٩";
  const westernNumbers = "0123456789";
  let convertedValue = "";

  // eslint-disable-next-line no-restricted-syntax
  for (const char of str) {
    const index = arabicNumbers.indexOf(char);
    if (index !== -1) {
      convertedValue += westernNumbers[index];
    } else {
      convertedValue += char; // If it's not an Arabic numeral, keep the original character.
    }
  }

  return convertedValue;
}

export function randomElement<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

export const dayType = (day: PlanDate, offDays: string[]) => {
  if (day.is_paused) return "pause";
  if (offDays.includes(day.date.weekday)) return "off";
  if (day.lock) return "lock";
  return "default";
};

// Set the timezone to Kuwait and get the start of the day
export const todayDateInKuwait = dayjs().tz("Asia/Kuwait").startOf("day");
export const todayDate = todayDateInKuwait.format("ddd, MMM D YYYY");
