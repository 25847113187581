/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Button, CardContent, Grid, TextField } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { validate } from "validate.js";
// Components
import { AppAlert } from "../../../components";
// Utils and hooks
import { SHARED_CONTROL_PROPS } from "../../../utils/form";

import { ObjectPropByName } from "../../../utils";
// Styles
import {
  SignupVerificationErrors,
  SignupVerificationParams,
} from "../../../types/accounts";

import {
  DrawerHeading,
  DrawerText,
} from "../../../components/CustomSwipeableDrawer";
import Snackbar from "../../../components/Snackbar";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  sendVerificationCode,
  signupVerification,
} from "../../../store/slices/accounts";
import getContactUsLink from "../../../utils/getContactUsLink";

const blinkAnimation = css`
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

/**
 * Renders "Confirm or Verify OTP" view
 * url: /auth/signup/confirm-otp
 */

interface FormStateValues {
  otp: number | undefined;
}

const ConfirmOTP = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState(60); // 60 seconds for 1 minute

  const isArabicMode = i18n.language === "ar";

  const validation = {
    otp: {
      presence: true,
      format: {
        pattern: "[0-9]*",
        message: t("OTP_ERROR"),
      },
      length: {
        is: 4,
        message: t("OTP_ERROR"),
      },
    },
  };

  const {
    otpSendloading,
    otpSendSuccess,
    userData,
    isSignupRequestLoading,
    isSignupVerificationLoading,
    signupVerificationError,
    signupVerificationSuccess,
    throttleError,
  } = useAppSelector((state) => state.accounts);

  const [resendDisabled, setResendDisabled] = useState<boolean>(false);
  const [isBlinking, setIsBlinking] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [state, setState] = useState<FormStateValues>({
    otp: undefined,
  });
  const [errors, setErrors] = useState<SignupVerificationErrors>({});
  const [openSuccess, setOpenSuccess] = useState(false);

  const onFieldBlur = (event: any) => {
    const { name, value } = event.target;
    const valid = (validation as ObjectPropByName)[name];
    const err = validate({ [name]: value }, { [name]: valid });
    const errs = { ...errors, ...err };
    if (!err) {
      delete errs[name];
    }
    setErrors({ ...errs });
  };
  const onFieldChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "otp" && value.length > 4) return;
    if (name === "otp" && !/^[0-9]*$/.test(value)) return;

    const limit = (validation as ObjectPropByName)[name]?.length?.is;
    if (value?.length <= limit) {
      setState((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };
  const fieldGetError = (key: any) => {
    return (errors as ObjectPropByName)[key]?.[0];
  };
  const fieldHasError = (key: any) => {
    return (errors as ObjectPropByName)[key] ? true : false;
  };
  const isValid = validate(state, validation) ? false : true;

  const handleResendOtp = () => {
    setResendDisabled(true);
    setIsBlinking(true);
    setTimer(60);

    // Make API call to resend OTP
    dispatch(sendVerificationCode(userData?.phone_number as string));

    setTimeout(() => {
      setResendDisabled(false);
      setIsBlinking(false);
    }, 12000); // 12 seconds
  };

  const handleFormSubmit = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();

      const token = userData?.token?.access as string;
      const otpString = state.otp?.toString();
      const params: SignupVerificationParams = {
        phone_number: userData?.phone_number as string,
        otp: otpString as string,
      };

      dispatch(signupVerification(token, params));
    },
    [dispatch, state.otp, userData]
  );

  const handleCloseError = useCallback(() => setError(undefined), []);

  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const horizontal = i18n.dir() === "rtl" ? "left" : "right";

  const handleContactUsClick = () => {
    const message = t("CONTACT_US_WHATSAPP_OTP_REGISTER");
    const whatsappAPIURL = getContactUsLink({ message });
    window.open(whatsappAPIURL, "_blank");
  };

  useEffect(() => {
    // Set up a timer only if it's greater than 0
    const countdown =
      timer > 0
        ? setInterval(() => {
            setTimer(timer - 1);
          }, 1000)
        : null;

    // Clear interval on component unmount
    if (countdown) {
      return () => clearInterval(countdown);
    }
  }, [timer]);

  return (
    <>
      <form
        onSubmit={handleFormSubmit}
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CardContent>
          <DrawerHeading marginTop="48px" marginBottom="8px">
            {t("VERIFY_OTP")}
          </DrawerHeading>
          <DrawerText marginBottom="40px">{t("VERIFY_OTP_MESSAGE")}</DrawerText>

          <CardContent>
            <TextField
              required
              label={t("ENTER_OTP")}
              name="otp"
              type="tel"
              value={state.otp}
              inputProps={{
                pattern: "[0-9]*",
                maxLength: 4,
                inputMode: "numeric",
              }}
              error={fieldHasError("otp")}
              helperText={fieldGetError("otp") || " "}
              onChange={onFieldChange}
              onBlur={onFieldBlur}
              id="otp"
              {...SHARED_CONTROL_PROPS}
            />
            {error ? (
              <AppAlert severity="error" onClose={handleCloseError}>
                {t("OTP_LIMIT_ERROR")}
              </AppAlert>
            ) : null}

            {signupVerificationError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {t("INCORRECT_OTP_ERROR")}
              </Alert>
            )}

            {signupVerificationSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {isArabicMode
                  ? `تم التحقق من الحساب بنجاح`
                  : `Account verified successfully`}
              </Alert>
            )}

            {throttleError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {t("THROTTLE_ERROR")}
              </Alert>
            )}

            {otpSendSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {t("OTP_SEND_SUCCESS")}
              </Alert>
            )}

            <Grid
              container
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              {`${Math.floor(timer / 60)}:${
                timer % 60 < 10 ? `0${timer % 60}` : timer % 60
              }`}

              {/* {t("DIDNT_RECIVED_OTP")} */}
              <Button
                variant="text"
                color="primary"
                onClick={handleResendOtp}
                disabled={resendDisabled || timer !== 0}
                sx={{
                  py: 0,
                  animation: isBlinking
                    ? `${blinkAnimation} 1s infinite`
                    : "none",
                }}
              >
                {t("RESEND_OTP")}
              </Button>
            </Grid>
            <Grid
              container
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Button onClick={handleContactUsClick} color="primary">
                {t("CONTACT_US")}
              </Button>
            </Grid>

            <Grid container mt="32px">
              <LoadingButton
                type="submit"
                loading={
                  otpSendloading ||
                  isSignupVerificationLoading ||
                  isSignupRequestLoading
                }
                disabled={!isValid}
                fullWidth
                variant="contained"
                size="large"
                sx={(theme) => ({
                  padding: "var(--1, 15px) var(--2, 0px)",
                  height: "var(--7, 56px)",
                  borderRadius: "var(--6, 48px)",
                  backgroundColor: `${theme.palette.primary.main}`,
                  fontSize: "17px",
                  fontWeight: 700,
                  letterSpacing: "0.2px",
                })}
              >
                {t("VERIFY")}
              </LoadingButton>
            </Grid>
          </CardContent>
          <Snackbar
            severity="success"
            handleOpen={openSuccess}
            onClose={handleCloseSuccess}
            message={t("YOU_ARE_NOW_REGISTERED")}
            anchorOrigin={{ vertical: "top", horizontal }}
          />
        </CardContent>
      </form>
    </>
  );
};

export default ConfirmOTP;
