import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from '../'


interface State {
  logo: string
}

const initialState: State = {
  logo: '/static/media/logo.611df188e0add228d2d1.png',
}

const slice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    setLogo(state, action) {
      state.logo = action.payload // Updates if user on a restaurant page, plan, food menue page
    },
    resetInitialState(state) {
      state.logo = '/static/media/logo.611df188e0add228d2d1.png'
    },
  },
})

// Reducer
export default slice.reducer
export const { actions } = slice

// Actions
export const setLogo = (logo: string) => async (dispatch: AppDispatch) => {
  dispatch(actions.setLogo(logo))
}

export const resetInitialState = () => async (dispatch: AppDispatch) => {
  dispatch(actions.resetInitialState())
}
