import {
  Box,
  Chip,
  Fade,
  Grid,
  Paper,
  Radio,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";

import { TabContext, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";

// Components
// Types
import { Food, PlanDate } from "../../types/restaurant";
// Styles
// Dynamic Imports
import { SxProps, Theme } from "@mui/material/styles";
import { t } from "i18next";
import { ReactComponent as LockIcon } from "../../assets/icons/lock_day.svg";
import { ReactComponent as OffDayIcon } from "../../assets/icons/off_day.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause_day.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning-polygon.svg";
import foodPlaceHolder from "../../assets/illustrations/food_placeholder.svg";
import { LazyImage } from "../../components/LazyImage";
import ReadMore from "../../components/ReadMore";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectFoodRequest } from "../../store/slices/foodselection";
import {
  SelectFoodRequest,
  SelectedFood,
  SelectedPlanMeal,
} from "../../types/foodselection";
import { dayType } from "../../utils";

const ScheduleAndSelectionView = ({
  dates,
  defaultDayIndex,
  offDays,
  selectedFoods,
  subScriptionId,
  isSelectLoading,
  disableSelection,
}: {
  dates: PlanDate[];
  defaultDayIndex: number;
  offDays: string[];
  disableSelection?: boolean;
  selectedFoods?: SelectedPlanMeal[];
  subScriptionId?: string;
  isSelectLoading?: boolean;
}) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const dispatch = useAppDispatch();

  const { userData } = useAppSelector((state) => state.accounts);

  const [dayTab, setDayTab] = React.useState(defaultDayIndex);
  const selectedDay = dates?.[dayTab];

  useEffect(() => {
    setDayTab(defaultDayIndex);
  }, [defaultDayIndex]);

  const handleDayTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setDayTab(newValue);
  };

  const [mealTab, setMealTab] = React.useState(0);
  const selectedMeal = selectedDay?.meals?.[mealTab];

  useEffect(() => {
    setMealTab(0)
  }, [dayTab])

  const handleMealTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setMealTab(newValue);
  };

  const mealTabPanelRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    mealTabPanelRef.current?.scrollTo(0, 0);
  }, [mealTab, dayTab]);

  const selectedFood = selectedFoods
    ?.find((meal) => meal.date == selectedDay?.date.date)
    ?.meals.find((meal) => meal.meal == selectedMeal?.id)
    ?.foods.find((food) => food.id);

  const WARNING_TEXT = isArabic
    ? "يمكنك اختيار الوجبات بعد الدفع والاشتراك"
    : "You can select food after subscribing";

  function handleSelectFood(foodId: number) {
    if (!selectedFoods || !selectedMeal.id || !userData || !subScriptionId)
      return;

    const data = {
      food_id: foodId,
      meal_id: selectedMeal.id,
      date: selectedDay.date.date,
    } as SelectFoodRequest;

    dispatch(
      selectFoodRequest(
        data,
        userData?.token?.access as string,
        subScriptionId,
        selectedFoods
      )
    );
  }

  return (
    <Box flexGrow={1} height="100%" overflow="hidden">
      <TabContext value={dayTab.toString()}>
        <Tabs
          value={dayTab}
          onChange={handleDayTabChange}
          sx={{
            p: "0",
          }}
        >
          {dates?.map((d, i) => {
            const type = dayType(d, offDays);

            return (
              <Tab
                key={i}
                sx={(theme) => ({
                  ...tabVariants(theme, type),
                })}
                label={
                  <DisableDayTooltip dayType={type}>
                    <Stack gap="6px" alignItems="center">
                      <Box className="day-circle">
                        <Typography variant="h3">{d.date.day}</Typography>
                      </Box>

                      <Typography variant="h3">
                        {isArabic ? d.date.weekday_arabic : d.date.weekday}
                      </Typography>

                      <Box className="icon-circle">{tabIcon(type)}</Box>
                    </Stack>
                  </DisableDayTooltip>
                }
                value={i}
              />
            );
          })}
        </Tabs>
        <TabPanel
          value={dayTab.toString()}
          sx={{
            p: "0",
            mt: "5px",
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
            height: "calc(100% - 113px)",
          }}
        >
          <TabContext value={mealTab.toString()}>
            <Tabs value={mealTab} onChange={handleMealTabChange}>
              {selectedDay?.meals?.map((m, i) => (
                <Tab
                  key={i}
                  label={
                    <Typography variant="h3">
                      {isArabic ? m.title_arabic : m.title}
                    </Typography>
                  }
                  value={i}
                />
              ))}
            </Tabs>
            {disableSelection && (
              <Stack
                direction="row"
                my="10px"
                marginLeft="4px"
                gap="6px"
                alignItems="center"
              >
                <WarningIcon />
                <Typography
                  variant="h4"
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                >
                  {WARNING_TEXT}
                </Typography>
              </Stack>
            )}
            <TabPanel
              ref={mealTabPanelRef}
              value={mealTab.toString()}
              sx={{
                p: "0",
                pt: "15px",
                display: "flex",
                flex: "1",
                overflowY: "auto",
                overscrollBehavior: "contain",
              }}
            >
              <Foods
                foods={selectedMeal?.foods || []}
                selectedFood={selectedFood}
                handleSelectFood={handleSelectFood}
                disableSelection={disableSelection}
                isSelectLoading={isSelectLoading}
              />
            </TabPanel>
          </TabContext>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

function Foods({
  foods,
  selectedFood,
  handleSelectFood,
  disableSelection,
  isSelectLoading,
}: {
  foods: Food[];
  selectedFood?: SelectedFood;
  handleSelectFood?: (foodId: number) => void;
  disableSelection?: boolean;
  isSelectLoading?: boolean;
}) {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const [clickedFoodId, setClickedFoodId] = useState<number | null>(null);

  return (
    <Grid container spacing={2}>
      {foods.map((food) => {
        const isSelected = selectedFood?.id === food.id;

        const handleOnClick = () => {
          if (isSelected) return;
          handleSelectFood?.(food.id);
          setClickedFoodId(food.id);
        };

        const isSaved = isSelected && !isSelectLoading;
        const wasClicked = clickedFoodId === food.id;
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={food.id}>
            <Paper
              elevation={0}
              key={food.id}
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                p: "16px",
                gap: "11px",
                cursor: "pointer",
                border: isSelected
                  ? `1px solid ${theme.palette.primary.main}`
                  : ``,
              })}
              onClick={handleOnClick}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "11px",
                }}
              >
                <LazyImage
                  image={food.photo || foodPlaceHolder}
                  placeHolder={foodPlaceHolder}
                  sx={{
                    height: "123px",
                    width: "131px",
                    minHeight: "123px",
                    minWidth: "131px",
                    maxWidth: "131px",
                  }}
                />
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  flexGrow={1}
                >
                  <Typography variant="h2" component="div">
                    {isArabic ? food.title_arabic : food.title}
                    <Typography
                      variant="h3"
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                        mt: "2px",
                      })}
                    >
                      <ReadMore maxCharacterCount={50}>
                        {isArabic ? food.description_arabic : food.description}
                      </ReadMore>
                    </Typography>
                  </Typography>
                  <Stack
                    direction="row"
                    alignSelf="flex-end"
                    // alignItems="Center"
                    gap="3px"
                  >
                    {isSelected && (
                      <SavedChip isSaved={isSaved} wasClicked={wasClicked} />
                    )}
                  
                  </Stack>
                </Stack>
              </Box>
              <Stack
                direction={"row"}
                gap={"7px"}
                alignItems="stretch"
                width="100%"
              >
                <Macro title={t("CAL")} value={food.calories} />
                <Macro title={t("PROTEIN")} value={food.protein} />
                <Macro title={t("CARB")} value={food.carb} />
                <Macro title={t("FAT")} value={food.fat} />
              </Stack>
            </Paper>
          </Grid>
        );
      })}
      {/* make space for the footer */}
      {disableSelection && <Box minHeight="106px">&nbsp;</Box>}
    </Grid>
  );
}

function SavedChip({
  isSaved,
  wasClicked,
}: {
  isSaved: boolean;
  wasClicked: boolean;
}) {
  const [showSavedChip, setShowSavedChip] = useState(false);

  useEffect(() => {
    if (!isSaved || !wasClicked) {
      return;
    }

    setShowSavedChip(true);
    const timer = setTimeout(() => {
      setShowSavedChip(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isSaved && wasClicked]);

  return showSavedChip ? (
    <Fade in={showSavedChip}>
      <Chip
        sx={(theme) => ({
          borderRadius: "6px",
          border: `1px solid rgba(16, 166, 123, 0.24)`,
          background: "white",
          color: theme.palette.primary.main,
        })}
        label={`${t("SAVED")}`}
      />
    </Fade>
  ) : null;
}

const Macro = ({ title, value }: { title: string; value: number | null }) => {
  if (!value) return null;

  return (
    <Typography
      sx={(theme) => ({
        background: theme.palette.background.default,
        color: theme.palette.text.secondary,
        py: "7px",
        borderRadius: "6px",
        width: "25%",
        display: "flex",
        justifyContent: "center",
      })}
      variant="h4"
      component="div"
    >
      {`${value} ${title}`}
    </Typography>
  );
};

const tabVariants = (
  theme: Theme,
  variant: "lock" | "pause" | "off" | "default" = "default"
) => {
  const tabStyle = {
    width: "55px",
    height: "97px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "27.5px",
    border: "1.5px solid #E2F0EF",
    color: theme.palette.primary.main,
    position: "relative",
    overflow: "visible",
    mb: "16px",
    "&.Mui-selected": {
      background: theme.palette.primary.main,
      color: "white",
    },
    "& .day-circle": {
      borderRadius: "50%",
      width: "31px",
      height: "31px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#E2F0EF",
      color: `${theme.palette.primary.main}`,
    },
    "& .icon-circle": {
      position: "absolute",
      bottom: "-14px",
      borderRadius: "50%",
      width: "28px",
      height: "28px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "white",
      border: "1.5px solid #EDF0F3",
    },
  } as const;

  const tabVariants: {
    [key: string]: SxProps<Theme>;
  } = {
    default: {
      ...tabStyle,
      "& .icon-circle": {
        display: "none",
      },
    },
    lock: {
      ...tabStyle,
      background: "#EDF0F3",
      border: "1.5px solid #EDF0F3",
      color: theme.palette.text.secondary,
      "& .day-circle": {
        ...tabStyle["& .day-circle"],
        background: "white",
        color: `${theme.palette.text.secondary}`,
      },
    },
    off: {
      ...tabStyle,
      pointerEvents: "none",
      background: "#CED3DB",
      border: "1.5px solid #EDF0F3",
      color: theme.palette.text.secondary,
      "& .day-circle": {
        ...tabStyle["& .day-circle"],
        background: "#EAEEF2",
        color: `${theme.palette.text.secondary}`,
      },
      "&.Mui-selected": {
        background: "#CED3DB",
        border: "1.5px solid #EDF0F3",
        color: theme.palette.text.secondary,
      },
    },
    pause: {
      ...tabStyle,
      pointerEvents: "none",
      background: "#EDF0F3",
      border: "1.5px solid #EDF0F3",
      color: theme.palette.text.secondary,
      "& .day-circle": {
        ...tabStyle["& .day-circle"],
        background: "white",
        color: `${theme.palette.text.secondary}`,
      },
    },
  } as const;

  return tabVariants[variant];
};

const tabIcon = (variant: "lock" | "pause" | "off" | "default" = "default") => {
  if (variant === "pause") return <PauseIcon />;
  if (variant === "off") return <OffDayIcon />;
  if (variant === "lock") return <LockIcon />;
};

const DisableDayTooltip = ({
  children,
  dayType,
}: PropsWithChildren<{ dayType: string }>) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const MESSAGE = {
    off: isArabic ? "يوم راحة" : "Off Day",
    pause: isArabic ? "يوم متوقف" : "Paused Day",
  }[dayType];

  return (
    <Tooltip title={MESSAGE} enterTouchDelay={0} placement="top-start">
      {children as React.ReactElement<any, any>}
    </Tooltip>
  );
};

export default ScheduleAndSelectionView;
