import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
// Components
// Types
// Styles
// Dynamic Imports
import placeHolderRestaurantLogo from "../../assets/illustrations/placeholder_restaurant_logo.png";
import { Layout } from "../../layout/Layout2";
import {
  getLastEdittedDay,
  getSavedMeals,
  getSelectedPlanDetail,
} from "../../store/slices/foodselection";
import { dayType } from "../../utils";
import ScheduleAndSelectionView from "../shared/ScheduleAndSelectionView";

const FoodSelection: React.FC<{ allowSelect?: boolean }> = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const { slug } = useParams();
  const { userData } = useAppSelector((state) => state.accounts);
  const dispatch = useAppDispatch();

  const {
    selectedPlan,
    selectedPlanMeals,
    lastEdittedDay,
    isLoading,
    isSelectLoading,
  } = useAppSelector((state) => state.foodselection);
  const plan = selectedPlan?.plan;

  const planTitle = (isArabic ? plan?.title_arabic : plan?.title) || "";
  const logo = plan?.restaurant_logo || placeHolderRestaurantLogo;
  const offDays = selectedPlan?.off_days || [];
  const pausedDates =
    userData?.customer.active_subscriptions.find((sub) => sub.id === slug)
      ?.paused_dates || [];

  useEffect(() => {
    if (!slug || !userData) {
      return;
    }

    const token = userData?.token?.access as string;

    if (!selectedPlan) {
      dispatch(getSavedMeals(token, slug));
      dispatch(getLastEdittedDay(token, slug));
    }

    if (!selectedPlan && selectedPlanMeals.length) {
      dispatch(
        getSelectedPlanDetail(token, slug, selectedPlanMeals, pausedDates)
      );
    }
  }, [slug, userData, selectedPlanMeals]);

  const lastEditedDayIndex =
    selectedPlan?.dates.findIndex(
      (d) => d.date.date === lastEdittedDay?.date
    ) ?? -1;

  const firstAvailableDay =
    selectedPlan?.dates?.findIndex((d) => dayType(d, offDays) == "default") ??
    -1;

  let defaultDayIndex = 0;
  if (lastEditedDayIndex !== -1) {
    defaultDayIndex = lastEditedDayIndex;
  } else if (firstAvailableDay !== -1) {
    defaultDayIndex = firstAvailableDay;
  }

  return (
    <>
      <Layout
        title={t("SELECT_FOOD")}
        isLoading={isLoading}
        subTitle={planTitle}
      >
        <Helmet>
          <title>
            {isArabic
              ? "إختيار الوجبات | اشتراكات للمطاعم الصحية"
              : "Select Food | Eshtrakaat for Subscriptions Restaurants"}
          </title>
          <meta
            name="description"
            content={
              isArabic
                ? "يمكنك من صفحة إختيار الوجبات القيام باختيار الوجبات المفضلة لديك في كل وجبة رئيسية وفي كل يوم | اشتراكات للمطاعم الصحية"
                : "You can select food on every meal & day | Eshtrakaat for Subscriptions Restaurants"
            }
          />
        </Helmet>
        <ScheduleAndSelectionView
          dates={selectedPlan?.dates || []}
          defaultDayIndex={defaultDayIndex}
          offDays={offDays}
          selectedFoods={selectedPlanMeals}
          subScriptionId={slug}
          isSelectLoading={isSelectLoading}
        />
      </Layout>
    </>
  );
};

export default FoodSelection;
