import { addDays, format } from 'date-fns';

/**
 * Calculate the end date of a subscription, taking into account off days.
 *
 * @param startDate - The date from which calculations start. This date is included in the calculation.
 * @param days - The number of days to add from the start date. Off days are meant to be excluded.
 * @param offDaysStrArr - A string array of off days, e.g. ["Fri", "Sat"]. If all days are off, an error is thrown.
 * @returns The tentative new end date of the subscription or null if the input is invalid.
 */
function calculateSubscriptionEndDate(
  startDate: Date,
  days: number, 
  offDaysStrArr: string[]
): Date | null {

  if (!startDate || !days || !offDaysStrArr) {
    return null;
  }

  // If there are no off days, simply add the number of days to the start date
  if (offDaysStrArr.length === 0) {
    return addDays(startDate, days - 1);
  }

  // Define a set of all possible days of the week
  const allDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  // Check if all days are off, and raise an error
  if (offDaysStrArr.length === allDays.length) {
    return null; 
  }

  // Initialize the end date as the start date
  let endDate = startDate; 

  // If the start date is an off day, find the next working day
  while (offDaysStrArr.includes(format(endDate, 'eee'))) {
    endDate = addDays(endDate, 1);
  }

  // Calculate the end date while considering off days
  while (days > 1) {
    endDate = addDays(endDate, 1);
    if (!offDaysStrArr.includes(format(endDate, 'eee'))) {
      days -= 1;
    }
  }

  // Return the calculated end date
  return endDate;
}

// export
export default calculateSubscriptionEndDate;