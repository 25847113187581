import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// Redux
import { useAppDispatch } from '../../store/hooks'

import { setRestaurant } from '../../store/slices/restaurants'
// Data
import { RestaurantWithPlans } from '../../types/restaurant'
// Styles
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, Card, IconButton, Stack, Typography } from '@mui/material'
import card_placeholder from '../../assets/icons/card_placeholder.svg'
import placeHolderRestaurantLogo from '../../assets/illustrations/placeholder_restaurant_logo.png'
import RestaurantCardBg from '../../assets/logos/restaurant_bg.png'
import RestaurantCardArBg from '../../assets/logos/restaurant_bg_ar.png'
import { LazyImage } from '../LazyImage'

const RestaurantCard: React.FC<RestaurantWithPlans> = ({
  cover,
  logo,
  name,
  name_arabic,
  slug,
  plans,
  description,
  description_arabic,
}) => {
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isArabic = i18n.language === 'ar'

  const restaurant = {
    name,
    name_arabic,
    slug,
    plans,
    cover,
    logo,
    description,
    description_arabic,
  }

  const handleClick = () => {
    navigate(`/restaurants/${slug}`)
    dispatch(setRestaurant(restaurant))
  }

  const _description = isArabic
    ? description_arabic || 'الاشتراك'
    : description || 'subscribe'

  return (
    <Card
      onClick={handleClick}
      elevation={0}
      dir={i18n.dir()}
      sx={(theme) => ({
        aspectRatio: '16/12.5',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        backgroundImage: `url(${
          isArabic ? RestaurantCardArBg : RestaurantCardBg
        })`,
        backgroundRepeat: 'repeat-y',
        backgroundPositionX: 'right',
        borderRadius: 'var(--2, 16px)',
        padding: '12px',
        borderWidth: '0px',
        position: 'relative',
      })}
    >
      <LazyImage
        alt={name}
        placeHolder={card_placeholder}
        image={cover || card_placeholder}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          objectFit: 'contain',
          aspectRatio: '21/9',
          width: '100%',
          maxWidth: '100%',
          borderRadius: 'var(--1, 8px)',
          position: 'relative',
          zIndex: 2,
        })}
      >
        <LazyImage
          alt={name}
          placeHolder={placeHolderRestaurantLogo}
          image={logo || placeHolderRestaurantLogo}
          sx={(theme) => ({
            width: '25%',
            aspectRatio: '1/1',
            borderRadius: 'var(--2, 8px)',
            position: 'absolute',
            bottom: '-25%',
            left: '12px',
            zIndex: 5,
            backgroundColor: theme.palette.divider,
            border: `2px solid ${theme.palette.divider}`,
          })}
        />
      </LazyImage>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bottom: '20px',
          position: 'absolute',
          width: '95%',
          paddingX: '12px',
          zIndex: 5,
        }}
      >
        <Stack>
          <Typography
            variant="h5"
            sx={{
              textTransform: 'capitalize',
              fontWeight: '700',
              fontSize: { xs: '16px', sm: '18px' },
              overflow: 'hidden',
              lineHeight: 'normal',
            }}
          >
            {isArabic ? `${name_arabic} - ${name}` : name}
          </Typography>
          <Box
            sx={{
              borderRadius: '24px',
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
              justifyContent: 'center',
              background: '#ECF8F5',
              p: '6px',
              px: '10px',
            }}
          >
            <Typography
              variant="h4"
              component="div"
              sx={(theme) => ({ color: theme.palette.primary.main })}
            >
              {_description || '-'}
            </Typography>
          </Box>
        </Stack>
        <IconButton sx={{ width: '24px', height: '24px', mb: 0 }}>
          {isArabic ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </Stack>
    </Card>
  )
}

export default RestaurantCard
