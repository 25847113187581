import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getRestaurantPlan,
  setCurrentPackageID,
} from "../../store/slices/restaurants";
// Components
// Types
// Styles
// Dynamic Imports
import placeHolderRestaurantLogo from "../../assets/illustrations/placeholder_restaurant_logo.png";
import CheckoutFooter from "../../components/Footer/CheckoutFooter";
import { Layout } from "../../layout/Layout2";
import { dayType } from "../../utils";
import ScheduleAndSelectionView from "../shared/ScheduleAndSelectionView";

const RestaurantPlanSchedule: React.FC<{ allowSelect?: boolean }> = () => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const { slug, planID } = useParams();
  const { userData } = useAppSelector((state) => state.accounts);
  const dispatch = useAppDispatch();

  const {
    planDetail,
    isLoading,
    currentPackageIDInMenue: selectedPackageID,
  } = useAppSelector((state) => state.restaurants);
  const plan = planDetail?.plan;
  const restaurantName = isArabic
    ? plan?.restaurant_name_arabic
    : plan?.restaurant_name;

  const title =
    (isArabic ? "المنيو بالايام" : "Daily Menu") || "";
  const logo = plan?.restaurant_logo || placeHolderRestaurantLogo;

  useEffect(() => {
    if (!planDetail) {
      dispatch(
        getRestaurantPlan(
          slug as string,
          planID as string,
          userData?.token?.access
        )
      );
    }
  }, []);

  const selectedPackage = plan?.packages.find(
    (p) => p.id === selectedPackageID
  );
  const offDays = selectedPackage?.off_days.map((d) => d.day) || [];

  const [defaultDayIndex, setDefaultDay] = useState<number>(0);
  useEffect(() => {
    const firstAvailableDay =
      planDetail?.dates?.findIndex((d) => dayType(d, offDays) == "default") ??
      -1;

    setDefaultDay(firstAvailableDay != -1 ? firstAvailableDay : 0);
  }, [selectedPackageID, planDetail?.dates]);

  const setSelectedPackageID = (id: number) => {
    localStorage.setItem("selected_package_id", `${id}`);
    dispatch(setCurrentPackageID(id));
  };

  return (
    <>
      <Layout
        title={title}
        isLoading={isLoading}
        restaurantLogo={logo}
        footer={
          <CheckoutFooter
            planId={planID || ""}
            packs={planDetail?.plan.packages || []}
            restaurantSlug={plan?.restaurant_slug || ""}
            setSelectedPackageId={setSelectedPackageID}
            selectedPackageId={selectedPackageID}
          />
        }
        subTitle={restaurantName}
      >
        <Helmet>
          <title>
            {isArabic
              ? `مطعم ${restaurantName} | تفاصيل ${plan?.title_arabic} | اشتراكات`
              : `${restaurantName} | ${plan?.title} details | Eshtrakaat for Subscriptions Restaurants`}
          </title>
          <meta
            name="description"
            content={
              isArabic
                ? `شاهد الوجبات المتنوعة في جميع الأيام  وقم بالإشتراك | اشتراكات للمطاعم الصحية`
                : `See the food menu options in details & then subscribe | Eshtrakaat for Subscriptions Restaurants`
            }
          />
        </Helmet>
        <ScheduleAndSelectionView
          dates={planDetail?.dates || []}
          defaultDayIndex={defaultDayIndex}
          offDays={offDays}
          disableSelection
        />
      </Layout>
    </>
  );
};

export default RestaurantPlanSchedule;
