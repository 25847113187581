import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  verifyUserArea,
  fetchPaymentMethods,
} from '../../store/slices/payments'
import { getRestaurantPlanSubscription } from '../../store/slices/restaurants'
import { resetInitialState } from '../../store/slices/globals'
import {
  CheckoutPageViewRequest,
  CheckoutPageViewResponse,
} from '../../types/impressions'

import axios from '../../utils/axios'
import CheckoutForm from './CheckoutForm'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const CheckOut: React.FC = () => {
  const { i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  const { slug, subscriptionID } = useParams()
  const { userData } = useAppSelector((state) => state.accounts)
  const { planDetail } = useAppSelector((state) => state.restaurants)
  const restaurantSlug = planDetail?.plan?.restaurant_slug
  const { isUserAreaVerified, paymentMethods } = useAppSelector(
    (state) => state.payments
  )
  const dispatch = useAppDispatch()

  const checkoutData = localStorage.getItem('checkoutData')

  const sendCheckoutPageView = async (params: CheckoutPageViewRequest) => {
    const token = userData?.token.access
    if (token) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      }
      try {
        const response = await axios.post<CheckoutPageViewResponse>(
          '/api/v1/impressions/checkout-page-view/',
          params,
          config
        )
        const responseData = response.data
        /*
          In next step, storing checkoutPageViewID to use later, to link it up with the executeRequestPaymentData..
          main purpose for this process is To further analyse subscription & already
          interacted restaurant, plan, subscribe click using same interaction_id
        */
        sessionStorage.setItem('checkoutPageViewID', responseData.id.toString())
      } catch (error) {
        console.error('Failed to send checkout page view', error)
        // Fail silently without breaking the page
      }
    }
  }

  useEffect(() => {
    if (!slug && restaurantSlug && userData?.token.access) {
      dispatch(resetInitialState())

      if (!paymentMethods) {
        dispatch(fetchPaymentMethods(userData?.token.access, restaurantSlug))
      }

      dispatch(verifyUserArea(restaurantSlug, userData?.token.access))
    } else if (!slug && userData?.token.access) {
      const restaurant_slug = localStorage.getItem('restaurant_slug')
      if (restaurant_slug) {
        dispatch(verifyUserArea(restaurant_slug, userData?.token.access))
      }
    }
  }, [restaurantSlug, userData?.token.access])

  useEffect(() => {
    if (slug && subscriptionID && userData?.token.access) {
      dispatch(verifyUserArea(slug, userData?.token.access))
      dispatch(
        getRestaurantPlanSubscription(subscriptionID, userData?.token.access)
      )
    }
  }, [slug, subscriptionID, userData?.token.access])

  useEffect(() => {
    const slg = slug || restaurantSlug || planDetail?.plan.restaurant_slug

    const interactionIDKey = `${slg}/interaction_id`
    const interactionID = sessionStorage.getItem(interactionIDKey)
    if (checkoutData && interactionID) {
      const parsedCheckoutData = JSON.parse(checkoutData)
      const { planID, currentPackageIDInMenue } = parsedCheckoutData

      // Retrieve stored plan and package IDs from session
      const storedPlanID = sessionStorage.getItem('currentPlanID')
      const storedPackageID = sessionStorage.getItem('currentPackageID')

      // Coerce all IDs to strings for comparison // cs noticed some inconsistence between datatypes
      const planIDStr = String(planID)
      const packageIDStr = String(currentPackageIDInMenue)
      const storedPlanIDStr = String(storedPlanID)
      const storedPackageIDStr = String(storedPackageID)

      if (
        slg &&
        planID &&
        planIDStr &&
        packageIDStr &&
        currentPackageIDInMenue &&
        (planIDStr !== storedPlanIDStr || packageIDStr !== storedPackageIDStr)
      ) {
        const params: CheckoutPageViewRequest = {
          plan: planID,
          package: currentPackageIDInMenue,
          interaction_id: interactionID,
          restaurant: slg as string,
        }
        sendCheckoutPageView(params)

        sessionStorage.setItem('currentPlanID', planID.toString())
        sessionStorage.setItem(
          'currentPackageID',
          currentPackageIDInMenue.toString()
        )
      }
    }
  }, [checkoutData])

  return (
    <>
      <Helmet>
        <title>
          {isArabic
            ? 'الاشتراك | اشتراكات للمطاعم الصحية'
            : 'Checkout | Eshtrakaat for Subscriptions Restaurants'}
        </title>
        <meta
          name="description"
          content={
            isArabic
              ? 'صفحة الشراء، حيث يمكنك مطالعة إختيارك للمطعم والخطة، ومن ثم الدفع | اشتراكات للمطاعم الصحية'
              : 'Checkout page, where you can see what you choose & pay | Eshtrakaat for Subscriptions Restaurants'
          }
        />
      </Helmet>
      <CheckoutForm slug={slug} isUserAreaVerified={isUserAreaVerified} />
    </>
  )
}

export default CheckOut
