import { Container } from '@mui/material'
import { FunctionComponent, ReactNode } from 'react'
import { CustomLinearProgress, Header, Logo, StyledToolbar } from './styles'

import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../store/hooks'

interface Props {
  endNode?: ReactNode
  startNode?: ReactNode
  title?: string
  isLoading?: boolean
  onClick?: () => void
}

/**
 * Renders TopBar composition
 * @component TopBar
 */
const TopBar: FunctionComponent<Props> = ({ startNode, onClick, isLoading, ...restOfProps }) => {
  const { i18n } = useTranslation()
  const { logo } = useAppSelector((state) => state.globals)

  // Check if logo str is empty

  return (
    <Header {...restOfProps}>
      <Container>
        <StyledToolbar disableGutters dir={i18n.dir()}>
          {startNode}
          <Logo onClick={onClick} src={logo} alt="logo" />
        </StyledToolbar>
      </Container>
      {isLoading && <CustomLinearProgress />} {/* Show progress bar while loading */}
    </Header>
  )
}

export default TopBar
