/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../";
import axios from "../../utils/axios";

import {
  ExecutePaymentRequestData,
  ExecutePaymentResponse,
  InitiatePaymentResponse,
  InitiateSessionResponse,
  PaymentConfirmationRequestData,
  PaymentConfirmationResponse,
} from "../../types/payments";

interface State {
  initiatePaymentLoading: boolean;
  initiatePaymentResponse: InitiatePaymentResponse | null;
  initiatePaymentError: boolean;

  initiateSessionLoading: boolean;
  initiateSessionResponse: InitiateSessionResponse | null;
  initiateSessionError: boolean;

  executePaymentLoading: boolean;
  executePaymentResponse: ExecutePaymentResponse | null;
  executePaymentError: ExecutePaymentResponse | null;

  paymentConfirmationLoading: boolean;
  paymentConfirmationResponse: PaymentConfirmationResponse | null;
  paymentConfirmationError: boolean;

  renderSession: boolean;

  isUserAreaVerificationLoading: boolean;
  isUserAreaVerified: boolean;
  isUserAreaVerificationError: boolean;

  paymentMethodsLoading: boolean;
  paymentMethods: string[] | null;
  paymentMethodsError: boolean;
}

const initialState: State = {
  initiatePaymentLoading: false,
  initiatePaymentResponse: null,
  initiatePaymentError: false,

  initiateSessionLoading: false,
  initiateSessionResponse: null,
  initiateSessionError: false,

  executePaymentLoading: false,
  executePaymentResponse: null,
  executePaymentError: null,

  paymentConfirmationLoading: true,
  paymentConfirmationResponse: null,
  paymentConfirmationError: false,

  isUserAreaVerificationLoading: false,
  isUserAreaVerified: true,
  isUserAreaVerificationError: false,

  paymentMethodsLoading: false,
  paymentMethods: null,
  paymentMethodsError: false,

  renderSession: false,
};

const slice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    startInitiatePayment(state) {
      state.initiatePaymentLoading = true;
    },

    setInitiatePaymentResponse(state, action) {
      state.initiatePaymentResponse = {
        IsSuccess: action.payload.IsSuccess,
      };
    },
    setInitiatePaymentError(state) {
      state.initiatePaymentError = true;
      state.initiatePaymentLoading = false;
      state.initiatePaymentResponse = {
        IsSuccess: "false",
      };
    },

    startInitiateSession(state) {
      state.initiateSessionLoading = true;
      state.initiateSessionError = false;
      state.initiateSessionResponse = null;
    },

    setInitiateSessionResponse(state, action) {
      state.initiateSessionResponse = action.payload;
      state.initiateSessionLoading = false;
    },

    setUserAreaVerificationLoading(state, action) {
      (state.isUserAreaVerificationLoading = action.payload || true),
        (state.isUserAreaVerificationError = false)
    },

    setUserAreaVerification(state, action) {
      state.isUserAreaVerified = action.payload || false;
      state.isUserAreaVerificationError = false;
      state.isUserAreaVerificationLoading = false;
    },

    setUserAreaVerificationError(state, action) {
      (state.isUserAreaVerificationError = action.payload || false),
        (state.isUserAreaVerificationLoading = false),
        (state.isUserAreaVerified = false);
    },

    setInitiateSessionError(state) {
      state.initiateSessionError = true;
      state.initiateSessionLoading = false;
      state.initiateSessionResponse = {
        IsSuccess: false,
        Message: "Something went wrong",
        ValidationErrors: null,
        Data: {
          SessionId: "",
          CountryCode: "",
        },
      };
    },

    startExecutePayment(state) {
      state.executePaymentLoading = true;
      state.executePaymentError = null;
      state.executePaymentResponse = null;
    },

    setExecutePaymentResponse(state, action) {
      state.executePaymentResponse = action.payload;
      state.executePaymentLoading = false;
    },

    setExecutePaymentError(state, action) {
      state.executePaymentError = action.payload;
      state.executePaymentLoading = false;
    },

    startPaymentConfirmation(state) {
      state.paymentConfirmationLoading = true;
      state.paymentConfirmationError = false;
      state.paymentConfirmationResponse = null;
    },

    setPaymentConfirmationResponse(state, action) {
      state.paymentConfirmationResponse = action.payload;
      state.paymentConfirmationLoading = false;
    },

    setPaymentConfirmationError(state) {
      state.paymentConfirmationError = true;
      state.paymentConfirmationLoading = false;
      state.paymentConfirmationResponse = null;
    },

    resetInitialState(state) {
      state.executePaymentError = initialState.executePaymentError;
      state.executePaymentLoading = initialState.executePaymentLoading;
      state.executePaymentResponse = initialState.executePaymentResponse;
    },

    resetExecutePayment(state) {
      state.executePaymentError = initialState.executePaymentError;
      state.executePaymentLoading = initialState.executePaymentLoading;
      state.executePaymentResponse = initialState.executePaymentResponse;
    },

    resetConfirmaPayment(state) {
      state.paymentConfirmationError = initialState.paymentConfirmationError;
      state.paymentConfirmationLoading =
        initialState.paymentConfirmationLoading;
      state.paymentConfirmationResponse =
        initialState.paymentConfirmationResponse;
    },

    setPaymentMethodsLoading(state) {
      state.paymentMethodsLoading = true;
      state.paymentMethodsError = false;
      state.paymentMethods = null;
    },

    setPaymentMethods(state, action) {
      state.paymentMethods = action.payload;
      state.paymentMethodsLoading = false;
    },

    setPaymentMethodsError(state) {
      state.paymentMethodsError = true;
      state.paymentMethodsLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

export const resetPaymentState = () => async (dispatch: AppDispatch) => {
  dispatch(actions.resetInitialState());
};

export const initiateSession =
  (token: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.startInitiateSession());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };
      const response = await axios.post(
        "/api/v1/payments/initiate-session/",
        {},
        config
      );
      dispatch(actions.setInitiateSessionResponse(response.data));
    } catch (error) {
      dispatch(actions.setInitiateSessionError());
    }
  };

export const verifyUserArea =
  (slug: string, access_token: string) => async (dispatch: AppDispatch) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        ...(access_token && { Authorization: `Bearer ${access_token}` }),
      },
    };
    try {
      dispatch(actions.setUserAreaVerificationLoading(true));

      const response = await axios.post(
        "/api/v1/subscriptions/area-verification/",
        {
          restaurant_slug: slug,
        },
        config
      );

      dispatch(actions.setUserAreaVerification(response.data.can_subscribe));
    } catch (error) {
      dispatch(actions.setUserAreaVerificationError(true));
    }
  };

export const executePaymentRequest =
  (token: string, body: ExecutePaymentRequestData) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startExecutePayment());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };

      const response = await axios.post(
        "/api/v1/payments/execute-payment/",
        body,
        config
      );
      dispatch(actions.setExecutePaymentResponse(response.data));
    } catch (error) {
      dispatch(actions.setExecutePaymentError(error));
    }
  };

export const fetchPaymentMethods =
  (token: string | undefined, restaurant_slug: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.setPaymentMethodsLoading());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };

      const response = await axios.get(
        `/api/v1/restaurants/${restaurant_slug}/payment_methods/`,
        config
      );

      if (response.data) {
        const paymentMethods = response.data.map((method: any) => method.name);
        dispatch(actions.setPaymentMethods(paymentMethods));
      }
    } catch (error) {
      dispatch(actions.setPaymentMethodsError());
    }
  };

export const confirmPayment =
  (token: string, body: PaymentConfirmationRequestData) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.startPaymentConfirmation());

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };

      const response = await axios.post(
        "/api/v1/payments/confirm/",
        body,
        config
      );
      dispatch(actions.setPaymentConfirmationResponse(response.data));
    } catch (error) {
      dispatch(actions.setPaymentConfirmationError());
    }
  };

export const resetExecutePaymentState = () => async (dispatch: AppDispatch) => {
  dispatch(actions.resetExecutePayment());
};

export const resetConfirmPaymentState = () => async (dispatch: AppDispatch) => {
  dispatch(actions.resetConfirmaPayment());
};
