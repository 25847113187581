import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import dayjs from "dayjs";
import { AppDispatch } from "../";
import i18n from "../../locale";
import { ActiveSubscription } from "../../types/accounts";
import { SelectedPlanMeal, SelectFoodRequest } from "../../types/foodselection";
import {
  Food,
  Meal,
  PlanDate,
  SelectedPlanDetail,
  SelectedPlanResponse,
} from "../../types/restaurant";
import axios from "../../utils/axios";
import { calculateIfLockedDay } from "../../utils/calculateIfLockedDay";

interface State {
  isLoading: boolean;
  error: boolean;
  success: boolean;
  selectedPlan: SelectedPlanDetail | null;
  isOffDay: boolean;
  selectedPlanMeals: SelectedPlanMeal[];
  selectedSubscription: ActiveSubscription | null;
  currentDate: string;
  currentMealID: number;
  currentDay: string;
  isLockDay: boolean;
  lastEdittedDay: {
    date: string;
    meal_id: number;
  } | null;
  isMealSelected: boolean;
  selectedMealFoodID: number | null;
  isSelectLoading: boolean;
}

const initialState: State = {
  isLoading: false,
  error: false,
  success: false,
  selectedPlan: null,
  isOffDay: false,
  selectedPlanMeals: [],
  selectedSubscription: null,
  currentDate: "",
  currentMealID: 0,
  currentDay: "",
  lastEdittedDay: null,
  isLockDay: false,
  isSelectLoading: false,

  isMealSelected: false,
  selectedMealFoodID: null,
};

const setSelectLoadingState = (state: State) => {
  state.isSelectLoading = true;
  state.error = false;
  state.success = false;
};

const setLoadingState = (state: State) => {
  state.isLoading = true;
  state.error = false;
  state.success = false;
};

const setSuccessState = (state: State) => {
  state.isLoading = false;
  state.isSelectLoading = false;
  state.error = false;
  state.success = true;
};

const setErrorState = (state: State, errorPayload: boolean) => {
  state.isLoading = false;
  state.isSelectLoading = false;
  state.error = errorPayload;
  state.success = false;
};

const foodSelectionSlice = createSlice({
  name: "foodselection",
  initialState,
  reducers: {
    getSelectedPlanStart: setLoadingState,
    getSelectedPlanSuccess: (
      state,
      action: PayloadAction<SelectedPlanDetail>
    ) => {
      setSuccessState(state);
      state.selectedPlan = action.payload;
    },
    getLastEdittedDay: (
      state,
      action: PayloadAction<{
        date: string;
        meal_id: number;
      }>
    ) => {
      state.lastEdittedDay = action.payload;
    },
    getSelectedPlanFailure: (state, action: PayloadAction<boolean>) => {
      setErrorState(state, action.payload);
    },
    setOffDay: (state, action: PayloadAction<boolean>) => {
      state.isOffDay = action.payload;
    },
    selectFoodRequestStart: setSelectLoadingState,
    selectFoodRequestSuccess: (state) => {
      setSuccessState(state);

      // state is ignored here since it's already updated in selectFoodRequestOptimistic'
      // const mealIndex = state.selectedPlanMeals.findIndex((meal) => meal.day === action.payload.day)
      // if (mealIndex !== -1) {
      //   state.selectedPlanMeals[mealIndex] = action.payload
      // } else {
      //   state.selectedPlanMeals.push(action.payload)
      // }
    },
    selectFoodRequestOptimistic: (
      state,
      action: PayloadAction<SelectedPlanMeal>
    ) => {
      const mealIndex = state.selectedPlanMeals.findIndex(
        (meal) => meal.day === action.payload.day
      );

      if (mealIndex !== -1) {
        state.selectedPlanMeals[mealIndex] = action.payload;
      } else {
        state.selectedPlanMeals.push(action.payload);
      }
    },
    undoSelectFoodRequestOptimistic: (
      state,
      action: PayloadAction<SelectedPlanMeal[]>
    ) => {
      state.selectedPlanMeals = action.payload;
    },
    selectFoodRequestFailure: (state, action: PayloadAction<boolean>) => {
      setErrorState(state, action.payload);
    },
    getSavedMealsStart: setLoadingState,
    getSavedMealsSuccess: (
      state,
      action: PayloadAction<SelectedPlanMeal[]>
    ) => {
      setSuccessState(state);
      state.selectedPlanMeals = action.payload;
    },
    getSavedMealsFailure: (state, action: PayloadAction<boolean>) => {
      setErrorState(state, action.payload);
    },
    setCurrentDate: (state, action: PayloadAction<string>) => {
      state.currentDate = action.payload;
    },
    setCurrentMealID: (state, action: PayloadAction<number>) => {
      state.currentMealID = action.payload;
    },
    setCurrentDay: (state, action: PayloadAction<string>) => {
      state.currentDay = action.payload;
    },
    setIsMealSelected: (state, action: PayloadAction<boolean>) => {
      state.isMealSelected = action.payload;
    },
    setSelectedMealFoodID: (state, action: PayloadAction<number>) => {
      state.selectedMealFoodID = action.payload;
    },

    setSelectedSubscription: (
      state,
      action: PayloadAction<ActiveSubscription | null>
    ) => {
      state.selectedSubscription = action.payload;
    },

    resetAllStates: (state) => {
      state.isLoading = false;
      state.error = false;
      state.success = false;
      state.selectedPlan = null;
      state.isOffDay = false;
      state.selectedPlanMeals = [];
      state.selectedSubscription = null;
      state.currentDate = "";
      state.currentMealID = 0;
      state.currentDay = "";
      state.isLockDay = false;
      state.isMealSelected = false;
      state.selectedMealFoodID = null;
      state.lastEdittedDay = null;
    },
  },
});

export default foodSelectionSlice.reducer;
export const { actions } = foodSelectionSlice;

interface DataEntry {
  date: any;
  is_paused: boolean;
  is_off: boolean;
}

export const getSelectedPlanDetail =
  (
    token: string,
    subscription_id: string,
    selectedPlanMeals: SelectedPlanMeal[],
    pausedDates: string[]
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.getSelectedPlanStart());

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };

      // optimize this one
      const data: SelectedPlanResponse = (
        await axios.get(
          `/api/v1/subscriptions/selected-plan/${subscription_id}/optimised`,
          config
        )
      ).data;

      const foods: Food[] = data.foods;

      const mealOrder: { [key: string]: number } = data.plan.meals.reduce(
        (acc, meal, index) => ({ ...acc, [meal.title]: index + 1 }),
        {}
      );

      let processedData: SelectedPlanDetail = {
        ...data,
        dates: await Promise.all(
          data.dates.map(async (day) => {
            const date = new Date(day.date);
            const weekday = format(date, "EEE");
            const month = format(date, "MMM");
            const t = i18n.getFixedT("ar");
            const isLock = calculateIfLockedDay(day.date);

            return {
              ...day,
              date: {
                day: day.date.split("-")[2], // 5
                month, // Mar
                month_arabic: t(month),
                weekday, // Mon,
                weekday_arabic: t(weekday),
                date: day.date,
              },
              is_off: data.off_days.includes(weekday),
              is_paused: pausedDates.includes(day.date),
              lock: isLock,
              meals: (
                await Promise.all(
                  day.meals.map(async (meal) => {
                    const selectedFoodId = selectedPlanMeals
                      .find((d) => d.date === day.date)
                      ?.meals.find((m) => m.meal === meal.id)?.foods[0]?.id;

                    const mealDetail = data.plan.meals.find(
                      (m) => m.id === meal.id
                    ) as Meal;

                    // only display selected food if the day is lock day
                    if (isLock) {
                      let food = foods.find(
                        (f) => f.id == selectedFoodId
                      ) as Food;

                      // sometimes the food might not be available in the food list
                      if (!food && selectedFoodId) {
                        food = (
                          await axios.get(
                            `/api/v1/subscriptions/locked-food/${selectedFoodId}`,
                            config
                          )
                        ).data;
                      }

                      return {
                        ...mealDetail,
                        foods: food ? [food] : [],
                      };
                    }

                    return {
                      ...mealDetail,
                      foods: meal.foods?.map(
                        (foodId) => foods.find((f) => f.id === foodId) as Food
                      ),
                    };
                  })
                )
              ).sort((a, b) => mealOrder[a.title] - mealOrder[b.title]),
            };
          })
        ),
      };

      // processedData = {
      //   ...processedData,
      //   dates: processedData.dates.filter((curr, i) => {
      //     const prev = processedData.dates[i - 1];
      //     const next = processedData.dates[i + 1];
      //     return !(
      //       (curr.is_paused || curr.is_off) &&
      //       (prev?.is_paused || prev?.is_off) &&
      //       (next?.is_paused || next?.is_off)
      //     );
      //   }),
      // };

      processedData.dates.sort((a, b) =>
        dayjs(a.date.date).diff(dayjs(b.date.date))
      );

      // Find contiguous paused date ranges
      let firstPaused: PlanDate | undefined = undefined;
      let lastPaused: PlanDate | undefined = undefined;
      const resultData: PlanDate[] = [];

      processedData.dates.forEach((entry, index, array) => {
        if (entry.is_paused) {
          if (!firstPaused) {
            firstPaused = entry; // start of new paused period
          }
          lastPaused = entry; // always update lastPaused to the current paused entry
        }

        const nextEntry = array[index + 1];
        if (!entry.is_paused || !nextEntry || !nextEntry.is_paused) {
          if (
            firstPaused &&
            lastPaused &&
            firstPaused.date.date !== lastPaused.date.date
          ) {
            resultData.push(firstPaused, lastPaused);
          } else if (firstPaused) {
            resultData.push(firstPaused);
          }
          firstPaused = undefined;
          lastPaused = undefined;
        }

        if (!entry.is_paused) {
          resultData.push(entry);
        }
      });

      // Assign sorted and filtered data to processedData
      processedData = {
        ...processedData,
        dates: resultData,
      };

      // const pausedDatess = processedData.dates.filter((d) => d.is_paused);
      // const firstPaused = pausedDatess[0];
      // const lastPaused = pausedDatess[pausedDatess.length - 1];
      // const unpausedData = processedData.dates.filter(
      //   (entry) => !entry.is_paused
      // );

      // const isOnly = firstPaused.date.date === lastPaused.date.date;

      // const returnedData = [
      //   firstPaused,
      //   ...(isOnly ? [] : [lastPaused]),
      //   ...unpausedData,
      // ];

      // processedData = {
      //   ...processedData,
      //   dates: returnedData.sort((a, b) =>
      //     dayjs(a.date.date).diff(dayjs(b.date.date))
      //   ),
      // };

      dispatch(actions.getSelectedPlanSuccess(processedData));
    } catch (error: unknown) {
      dispatch(actions.getSelectedPlanFailure(true));
    }
  };

export const setOffDay =
  (isLockDay: boolean) => async (dispatch: AppDispatch) => {
    dispatch(actions.setOffDay(isLockDay));
  };

export const cleanFoodSelectionData = () => async (dispatch: AppDispatch) => {
  dispatch(actions.resetAllStates());
};

export const getSavedMeals =
  (token: string, subscription_id: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.getSavedMealsStart());

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };
      const response = await axios.get(
        `/api/v1/subscriptions/my-food-selections/${subscription_id}`,
        config
      );
      dispatch(actions.getSavedMealsSuccess(response.data));
    } catch (error: unknown) {
      dispatch(actions.getSavedMealsFailure(true));
    }
  };

export const selectFoodRequest =
  (
    data: SelectFoodRequest,
    token: string,
    subscription_id: string,
    currentState: SelectedPlanMeal[]
  ) =>
  async (dispatch: AppDispatch) => {
    const dayData = currentState.find(
      (day) => day.date == data.date
    ) as SelectedPlanMeal;
    const mealIndex = dayData?.meals?.findIndex(
      (meal) => meal.meal == data.meal_id
    ) as number;

    const optimisticData: SelectedPlanMeal = {
      ...dayData,
      meals: [
        ...dayData.meals.slice(0, mealIndex),
        { meal: data.meal_id, foods: [{ id: data.food_id }] },
        ...dayData.meals.slice(mealIndex + 1),
      ],
    };

    dispatch(actions.selectFoodRequestStart());
    dispatch(actions.selectFoodRequestOptimistic(optimisticData));

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };

      await axios.post(
        `/api/v1/subscriptions/select-food/${subscription_id}/`,
        data,
        config
      );
      // response date is ignored since it's the same as optimisticData
      dispatch(actions.selectFoodRequestSuccess());
    } catch (error: unknown) {
      dispatch(actions.selectFoodRequestFailure(true));
      dispatch(actions.undoSelectFoodRequestOptimistic(currentState));
    }
  };

export const getLastEdittedDay =
  (token: string, subscription_id: string) => async (dispatch: AppDispatch) => {
    // dispatch(actions.getSelectedPlanStart())

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };
      const response = await axios.get(
        `/api/v1/subscriptions/last-edited-selection-date/${subscription_id}/`,
        config
      );
      dispatch(actions.getLastEdittedDay(response.data));
    } catch (error: any) {
      if (error.detail === "No last modified selection found") {
        dispatch(actions.getLastEdittedDay({ date: "", meal_id: 0 }));
      }
      // dispatch(actions.getSelectedPlanFailure(true))
    }
  };

export const setCurrentDate =
  (date: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.setCurrentDate(date));
  };

export const setCurrentMealID =
  (mealID: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.setCurrentMealID(mealID));
  };

export const setCurrentDay = (day: string) => async (dispatch: AppDispatch) => {
  dispatch(actions.setCurrentDay(day));
};

export const setIsMealSelected =
  (isMealSelected: boolean) => async (dispatch: AppDispatch) => {
    dispatch(actions.setIsMealSelected(isMealSelected));
  };

export const setSelectedMealFoodID =
  (foodID: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.setSelectedMealFoodID(foodID));
  };
