import { UserData } from '../types/accounts'

interface AuthResult extends UserData {
  isAuthenticated: boolean
}

export function useIsAuthenticated(): AuthResult {
  const user: UserData = JSON.parse(localStorage.getItem('user') || '{}')
  const isAuthenticated = user && user.token ? true : false

  const result = {
    isAuthenticated,
    ...user,
  }

  return result
}
