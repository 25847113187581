import { Navigate, Route, Routes } from "react-router-dom";
import { NotFoundView } from "..";
import { useAppSelector } from "../../store/hooks";
import LoginRoutes from "./Login";
import Signup from "./Signup/Signup";

/**
 * Routes for "Auth" flow
 * url: /auth/*
 */
const AuthRoutes = () => {
  const { temporaryUserData } = useAppSelector((state) => state.accounts);
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/auth/login" replace />} />
      <Route
        path="signup/*"
        element={
          temporaryUserData ? (
            <Signup />
          ) : (
            <Navigate to="/personal-details" replace />
          )
        }
      />

      <Route path="login/*" element={<LoginRoutes />} />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
};

export default AuthRoutes;
