import createCache from '@emotion/cache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { prefixer } from 'stylis';
import defaultTheme from './theme';

function createEmotionCache() {
  return createCache({ key: 'css', prepend: true, stylisPlugins: [prefixer] });
}

// Client-side cache, shared for the whole session of the user in the browser.
const CLIENT_SIDE_EMOTION_CACHE = createEmotionCache();

interface AppThemeProviderProps extends PropsWithChildren {
  emotionCache?: EmotionCache;
}

const AppThemeProvider: FunctionComponent<AppThemeProviderProps> = ({ children, emotionCache = CLIENT_SIDE_EMOTION_CACHE }) => {
  const { i18n } = useTranslation();

  // Create the theme based on the i18n direction and any custom theme properties
  const theme = createTheme({
    ...defaultTheme,
    direction: i18n.dir(),

    // Add other theme properties here
  });

  const updateTheme = createTheme({
    ...theme, palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: 'rgba(0, 0, 0, 0.26)'
      }
    }
  });

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={updateTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default AppThemeProvider;
