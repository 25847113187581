import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Components
// Utils and Hooks
import { SHARED_CONTROL_PROPS } from "../../../utils/form";
// Styles
import { validate } from "validate.js";
import { ObjectPropByName } from "../../../utils";

//Types
import { RecoveryPassFormErrors } from "../../../types/accounts";
// Redux
import {
  DrawerHeading,
  DrawerText,
} from "../../../components/CustomSwipeableDrawer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { sendRecoveryPassCode } from "../../../store/slices/accounts";
import { resetInitialState } from "../../../store/slices/globals";
import { appValidation } from "../../../utils/appValidation";

import { ReactComponent as PhoneIcon } from "../../../assets/icons/call.svg";

interface FormStateValues {
  phoneNumber: string;
}

const ForgetPassword = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const dispatch = useAppDispatch();
  dispatch(resetInitialState());

  const { forgetPassword } = appValidation(t);

  const { otpSendloading, otpSendError, otpSendSuccess, throttleError } =
    useAppSelector((state) => state.accounts);

  const [errors, setErrors] = useState<RecoveryPassFormErrors>({});
  const [state, setState] = useState<FormStateValues>({
    phoneNumber: "",
  });

  const onFieldBlur = () => {
    const err = validate(state, forgetPassword);
    setErrors(err || {});
  };

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Do not allow more than 8 digits
    if (name === "phoneNumber" && value.length > 8) return;

    // Allow only numbers for phone number
    if (name === "phoneNumber" && value !== "" && !/^\d+$/.test(value)) return;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const fieldGetError = (key: keyof ObjectPropByName, label: string) => {
    const errorMessage = (errors as ObjectPropByName)[key]?.[0];
    return errorMessage?.replace(label, "");
  };

  const fieldHasError = (key: keyof ObjectPropByName) => {
    return (errors as ObjectPropByName)[key] ? true : false;
  };

  const isValid = !validate(state, forgetPassword);

  useEffect(() => {
    if (otpSendSuccess) {
      // navigate(`/auth/recovery/verify`);
    }
  }, [otpSendSuccess]);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(sendRecoveryPassCode(state.phoneNumber));
  };
  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <DrawerHeading marginTop="48px" marginBottom="8px">
          {t("FORGET_PASSWORD_HEAD")}
        </DrawerHeading>
        <DrawerText marginBottom="40px">
          {t("FORGET_PASSWORD_MESSAGE")}
        </DrawerText>
        <CardContent>
          <TextField
            required
            type="tel"
            label={t("PHONE_NUMBER")}
            name="phoneNumber"
            inputProps={{
              pattern: "[0-9]*",
              maxLength: 8,
              inputMode: "numeric",
            }}
            value={state.phoneNumber}
            error={fieldHasError("phoneNumber")}
            helperText={fieldGetError("phoneNumber", "Phone number") || " "}
            id="phoneNumber"
            autoComplete="new-phone-number"
            onBlur={onFieldBlur}
            onChange={onFieldChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon
                    style={{
                      transform:
                        i18n.dir() === "ltr" ? "scaleX(-1)" : "scaleX(1)",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            {...SHARED_CONTROL_PROPS}
          />

          {otpSendError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {t("OTP_SEND_ERROR")}
            </Alert>
          )}

          {throttleError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {t("THROTTLE_ERROR")}
            </Alert>
          )}

          <Grid container justifyContent="center" alignItems="center">
            <LoadingButton
              type="submit"
              loading={otpSendloading}
              disabled={!isValid}
              fullWidth
              variant="contained"
              size="large"
              sx={(theme) => ({
                padding: "var(--1, 15px) var(--2, 0px)",
                height: "var(--7, 56px)",
                borderRadius: "var(--6, 48px)",
                backgroundColor: `${theme.palette.primary.main}`,
                fontSize: "17px",
                fontWeight: 700,
                letterSpacing: "0.2px",
              })}
            >
              {t("NEXT")}
            </LoadingButton>
          </Grid>
        </CardContent>
      </form>
    </>
  );
};

export default ForgetPassword;
