/* eslint-disable @typescript-eslint/no-explicit-any */
function isApplePaySupported(): boolean {
  // Check if the ApplePaySession object is available
  if (typeof window !== 'undefined' && 'ApplePaySession' in window) {
    // Check if the canMakePaymentsWithActiveCard method is available
    if ((window as any).ApplePaySession.canMakePaymentsWithActiveCard) {
      // You can further check if the user's device and browser version are supported
      // For example, you can check user agent or other browser features here

      // Return true if Apple Pay is supported
      return true
    }
  }

  // Apple Pay is not supported
  return false
}

export default isApplePaySupported
