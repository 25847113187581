import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ErrorPageIcon } from "../assets/icons/error_page_icon.svg";
import { DrawerHeading } from "../components/CustomSwipeableDrawer";
import { Layout } from "../layout/Layout2";

const NotFoundView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isModeArabic = i18n.language === "ar";

  const backToHome = () => {
    navigate("/");
  };

  const handlePageReload = () => {
    window.location.reload();
  };

  return (
    <Layout title={t("ERROR.PAGENOTFOUND")} isLoading={false}>
      <Container
        sx={(theme) => ({
          padding: "16px 0px",
          flexShrink: 0,
          overflow: "hidden",
          backgroundColor: theme.palette.background.default,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          direction: isModeArabic ? "rtl" : "ltr",
          minHeight: "88vh",
        })}
      >
        <ErrorPageIcon />

        <DrawerHeading marginBottom="30px" marginTop="58px">
          {t("ERROR_PAGE_TITLE")}
        </DrawerHeading>

        <div>
          <LoadingButton
            onClick={handlePageReload}
            variant="contained"
            sx={{
              // m: '20px',
              mt: "40px",
              width: "342px",
              height: "56px",
              maxWidth: "100%",
              display: "flex", // Add this style to make icon and text align horizontally
              alignItems: "center", // Vertically center the icon and text
            }}
            color="primary"
          >
            {t("ERROR_PAGE_FIRST_BTN")}
          </LoadingButton>

          <div
            style={{
              display: "flex",
              justifyContent: "center", // Horizontally center
              alignItems: "center", // Vertically center
            }}
          >
            <LoadingButton
              onClick={backToHome}
              variant="text"
              sx={{
                border: "1px solid #10A67B",
                color: "#10A67B",
                mt: "12px",
                width: "342px",
                height: "56px",
                maxWidth: "100%",
                display: "flex", // Add this style to make icon and text align horizontally
                alignItems: "center", // Vertically center the icon and text
                borderRadius: "48px",
              }}
            >
              {t("ERROR_PAGE_SECOND_BTN")}
            </LoadingButton>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundView;
