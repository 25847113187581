import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AccountBackwardArIcon from "../../assets/icons/account_back.svg";
import AccountForwardEnIcon from "../../assets/icons/account_forward.svg";
import delete_account from "../../assets/icons/delete_account.svg";
import delete_account_lock from "../../assets/icons/delete_account_lock.svg";
import delete_account_popup from "../../assets/icons/delete_account_popup.svg";

// Component

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import CustomSwipeableDrawer, {
  DrawerHeading,
  DrawerText,
} from "../../components/CustomSwipeableDrawer";
import { Layout } from "../../layout/Layout2";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  deleteAccount as reduxCallToDeleteAcount,
  resetDeleteAccountOperation,
} from "../../store/slices/accounts";

const Settings: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isArabicMode = i18n.language === "ar";
  const dispatch = useAppDispatch();
  const {
    deleteAccountError,
    deleteAccountLoading,
    deleteAccountSuccess,
    userData,
    throttleError,
  } = useAppSelector((state) => state.accounts);
  const navigate = useNavigate();

  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const [password, setPassword] = useState<string>("");
  const handleClick = () => {
    setOpen(true);
  };

  const deleteAccount = () => {
    const token = userData?.token?.access || "";
    dispatch(reduxCallToDeleteAcount(token as string, password as string));
  };

  useEffect(() => {
    if (deleteAccountSuccess) {
      setDeletePopup(false);
      navigate("/");
    }
  }, [deleteAccountSuccess]);

  const toggleDrawer = (state: boolean) => {
    setOpen(state);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleCloseConfirmationPopup = () => {
    dispatch(resetDeleteAccountOperation());
    setShowPassword(false);
    setOpen(false);
    setDeletePopup(false);
  };

  return (
    <Layout title={t("ACCOUNT_SETTINGS")}>
      <Helmet>
        <title>
          {isArabicMode
            ? "الإعدادات | اشتراكات للمطاعم الصحية"
            : "Settings | Eshtrakaat for Subscriptions Restaurants"}
        </title>
        <meta
          name="description"
          content={
            isArabicMode
              ? "قم بتغيير اللغة أو حذف حسابك في حال رغبتك بذلك | اشتراكات للمطاعم الصحية"
              : "Change language or delete your account if wish to | Eshtrakaat for Subscriptions Restaurants"
          }
        />
      </Helmet>
      <List
        component="ul"
        dir={i18n.dir()}
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "8px",
        }}
      >
        <ListItemButton sx={{ width: "100%" }} onClick={handleClick}>
          <ListItemIcon>
            <img src={delete_account} alt="delete profile" />
          </ListItemIcon>
          <ListItemText
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "19px",
            })}
            primary={t("DELETE")}
          />
          <Box
            sx={{
              width: "16px",
              height: "16px",
            }}
          >
            <img
              src={
                theme.direction === "ltr"
                  ? AccountForwardEnIcon
                  : AccountBackwardArIcon
              }
              alt="delete profile"
            />
          </Box>
        </ListItemButton>
      </List>
      <CustomSwipeableDrawer
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        height="500px"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100%",
            width: "87.69%",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              height: "160px",
              width: "47%",
              marginBottom: "32px",
              marginTop: "48px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={delete_account_popup}
              alt="delete account confirmation"
              style={{
                maxWidth: "100%", // Ensure the image is responsive
                maxHeight: "100%", // and doesn't overflow its container
              }}
            />
          </Box>
          <Box>
            <DrawerHeading marginBottom="50px">
              {t("DELETE_POPUP")}
            </DrawerHeading>
          </Box>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            fullWidth
            sx={{
              fontWeight: 700,
              marginBottom: "12px",
              height: "56px",
              padding: "var(--1, 16px) var(--2, 0px)",
              fontSize: "16px",
            }}
          >
            {t("DELETE_REJECT")}
          </Button>
          <Button
            fullWidth
            onClick={() => {
              setOpen(false);
              setDeletePopup(true);
            }}
            variant="text"
            sx={{
              backgroundColor: "#FFF",
              fontWeight: 700,
              marginBottom: "8px",
              height: "56px",
              padding: "var(--1, 16px) var(--2, 0px)",
              fontSize: "16px",
              color: "#556885",
              borderRadius: "48px",
            }}
          >
            {t("DELETE")}
          </Button>
        </Box>
      </CustomSwipeableDrawer>

      <CustomSwipeableDrawer
        transitionDuration={10}
        open={deletePopup && !open}
        onOpen={() => setDeletePopup(true)}
        onClose={handleCloseConfirmationPopup}
        height="384px"
      >
        <Box
          sx={{
            height: "100%",
            width: "87%", // Maintain the width as per design
            margin: "0 auto", // Automatically adjust margin on both sides
            display: "flex", // Enable flexbox
            flexDirection: "column", // Stack children vertically
            justifyContent: "center", // Center content vertically
          }}
        >
          <DrawerHeading marginBottom="8px">
            {t("DELETE_ACCOUNT_CONFIRMATION")}
          </DrawerHeading>

          <DrawerText marginBottom="40px">
            {t("DELETE_ACCOUNT_CONFIRMATION_MESSAGE")}
          </DrawerText>

          <Grid item xs={12} justifyContent="flex-start">
            {deleteAccountError && (
              <Alert severity="error" sx={{ width: "100%", mb: 2.5 }}>
                {isArabicMode
                  ? "كلمة المرور غير صحيحة"
                  : "Password is incorrect"}
              </Alert>
            )}

            {throttleError && (
              <Alert severity="error" sx={{ width: "100%", mt: 2.5 }}>
                {t("THROTTLE_ERROR")}
              </Alert>
            )}
          </Grid>
          <TextField
            value={password}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            label={t("PASSWORD")}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={delete_account_lock} alt="delete account lock" />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  disableFocusRipple
                  sx={{ bgcolor: "transparent" }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff sx={{ color: "#556885" }} />
                  ) : (
                    <Visibility sx={{ color: "#556885" }} />
                  )}
                </IconButton>
              ),
            }}
          />

          <LoadingButton
            color="error"
            loading={deleteAccountLoading}
            disabled={password.length < 2}
            variant="contained"
            size="small"
            onClick={deleteAccount}
            sx={{
              fontWeight: 700,
              height: "56px",
              padding: "var(--1, 16px) var(--2, 0px)",
              fontSize: "16px",
              marginTop: "40px",
            }}
          >
            {t("DELETE")}
          </LoadingButton>
        </Box>
      </CustomSwipeableDrawer>
    </Layout>
  );
};

export default Settings;
