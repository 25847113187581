import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Box,
  Container,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  createTheme,
  useTheme,
} from '@mui/material'
import { FunctionComponent, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
// Componnets
import { ErrorBoundary } from '../components'
import SideBar from './SideBar'
import TopBar from './TopBar'
// Hooks and Utils
import { useRefill } from '../hooks'
import { useOnMobile } from '../hooks/layout'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { cleanUserData, setRedirectToCheckoutOnSuccess } from '../store/slices/accounts'
import { setCurrentPackageID } from '../store/slices/restaurants'
import { Package, PlanSelectedPackage } from '../types/restaurant'
import getContactRestaurantLink from '../utils/getContactRestaurantLink'
import getContactUsLink from '../utils/getContactUsLink'
import removeTrailingZeros from '../utils/removeTrailingZeros'
import { LinkToPage } from '../utils/type'

import { localStorageGet } from '../utils/localStorage'

import { TOPBAR_DESKTOP_HEIGHT, TOPBAR_MOBILE_HEIGHT } from './config'

// Styles
import { cleanFoodSelectionData } from '../store/slices/foodselection'
import {
  Content,
  DialogButton,
  Flex,
  Main,
  Navigation,
  PageWrapper,
  PriceWrapper,
  StyledActions,
  StyledButton,
  StyledDialog,
  StyledFormControl,
  Title,
} from './styles'

interface IProps extends PropsWithChildren {
  title: ReactNode
  hasFooter?: boolean
  menuHeader?: boolean
  withFooter?: boolean
  isLoading?: boolean
  onTouchStart?: React.TouchEventHandler<Element>
  onTouchMove?: React.TouchEventHandler<Element>
}

const Layout1: FunctionComponent<IProps> = ({ children, title, hasFooter, menuHeader, withFooter, isLoading }) => {
  useRefill() // Refill the user's token, a bridge between localStorage and Redux
  const { t, i18n } = useTranslation()
  const { slug, planID } = useParams()
  const isArabic = i18n.language === 'ar'
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { planDetail, planSelectedPackages, isLoading: restaurantStateLoading } = useAppSelector((state) => state.restaurants)
  const { userData } = useAppSelector((state) => state.accounts);




  // have only unique restaurant contact values
  const restaurantContactValues = userData?.customer?.active_subscriptions?.map((item) => {
    return {
      restaurantName: item.restaurant_name,
      restaurantNameArabic: item.restaurant_name_arabic,
      planTitle: item.plan_title,
      planTitleArabic: item.plan_title_arabic,
      contactNumber: item.contact_number,
    }
  })

  const uniqueRestaurantContactValues = restaurantContactValues?.filter((item, index, self) => {
    return (
      index ===
      self.findIndex(
        (t) => t.restaurantName === item.restaurantName && t.contactNumber === item.contactNumber,
      )
    )
  })

  const isAuthenticated = userData?.token?.access ? true : false
  const isSubscribed = userData?.customer?.active_subscriptions?.length ?? 0 > 0 ? true : false;
  const packages = planDetail?.plan?.packages

  const [value, setValue] = useState<string>('')

  let message = t("CONTACT_US_WHATSAPP_VISITOR");
  if (isAuthenticated) message = t("CONTACT_US_WHATSAPP_NON_SUBSCRIBED_USER");
  if (isSubscribed) message = t("CONTACT_US_WHATSAPP_SUBSCRIBED_USER");
  const whatsappAPIURL = getContactUsLink({ message })

  useEffect(() => {
    // Check if plan is in planSelectedPackages array and set the value to the selected package
    const selectedPackage = planSelectedPackages.find((item: PlanSelectedPackage) => item.plan_id === (planDetail?.plan?.id as number))
    if (selectedPackage) {
      setValue(selectedPackage?.package_value || '0')
    } else {
      const initialPackageId = packages?.[0]?.id.toString() || '0'
      setValue(initialPackageId)
    }
  }, [planDetail, planSelectedPackages])

  useEffect(() => {
    if (isNaN(parseInt(value))) return
    dispatch(setCurrentPackageID(parseInt(value) as number))
  }, [value])

  const SIDEBAR_ITEMS: Array<LinkToPage> = [
    {
      title: 'HOME_RESTAURANTS',
      path: '/',
      icon: 'home',
    },
    {
      title: 'LANGUAGE',
      path: '',
      icon: 'language',
      key: 'language',
    },
  ]

  const AUTH_SIDEBAR_ITEMS_NORMAL_CUSTOMER = [
    {
      title: 'Profile',
      path: '/profile',
      icon: 'account',
    },

    { title: 'App name', path: '/contact-us', icon: 'whatsapp', externalLink: true, href: whatsappAPIURL },
    {
      title: 'Terms and Conditions',
      path: '/terms-policy',
      icon: 'terms',
    },
    {
      title: 'Logout',
      path: '',
      icon: 'logout',
      key: 'logout',
    },
  ]

  const AUTH_SIDEBAR_ITEMS_SUBSCRIBED_CUSTOMER = [
    {
      title: 'Profile',
      path: '/profile',
      icon: 'account',
    },

    ...(uniqueRestaurantContactValues || []).map((item) => {
      const restaurant_name = isArabic ? item.restaurantNameArabic : item.restaurantName
      const title = t('CONTACT') + " " + `${restaurant_name}`;
      return {
        title: title,
        isTranslated: true,
        icon: 'whatsapp',
        path: '/contact-restaurant',
        externalLink: true,
        href: getContactRestaurantLink({
          isArabic,
          restaurantName: restaurant_name,
          planTitle: isArabic ? item.planTitleArabic : item.planTitle,
          contactNumber: item.contactNumber,
        }),
      }
    }),

    { title: 'App name', path: '/contact-us', icon: 'whatsapp', externalLink: true, href: whatsappAPIURL },
    {
      title: 'Terms and Conditions',
      path: '/terms-policy',
      icon: 'terms',
    },
    {
      title: 'Logout',
      path: '',
      icon: 'logout',
      key: 'logout',
    },
  ]

  const onMobile = useOnMobile()

  const [sideBarVisible, setSideBarVisible] = useState(false)

  useEffect(() => {
    setSideBarVisible(false)
  }, [])

  // Variant 1 - Sidebar is static on desktop and is a drawer on mobile
  // const sidebarOpen = onMobile ? sideBarVisible : true;
  // const sidebarVariant = onMobile ? 'temporary' : 'persistent';

  // Variant 2 - Sidebar is drawer on mobile and desktop
  const sidebarVariant = 'temporary'

  const onSideBarOpen = () => setSideBarVisible(true) // Don't re-render Layout when SideBar is already open

  const onSideBarClose = () => setSideBarVisible(false)

  let theme = useTheme()
  const onClick = (lng: string) => {
    i18n.changeLanguage(lng === 'ar' ? 'en' : 'ar')
    document.body.dir = i18n.dir()
    const updateTheme = createTheme({ ...theme, direction: i18n.dir() })
    theme = updateTheme
  }
  const anchor = i18n.dir() === 'rtl' ? 'right' : 'left'


  const isInActiveUser = false
  const AUTH_SIDEBAR_ITEMS_TO_RENDER = isSubscribed ? AUTH_SIDEBAR_ITEMS_SUBSCRIBED_CUSTOMER : AUTH_SIDEBAR_ITEMS_NORMAL_CUSTOMER
  const generateSidebarItems = useCallback(() => {
    if (isAuthenticated) {
      if (isInActiveUser) {
        return [...SIDEBAR_ITEMS, ...AUTH_SIDEBAR_ITEMS_TO_RENDER]
      } else {
        return [...SIDEBAR_ITEMS, ...AUTH_SIDEBAR_ITEMS_TO_RENDER]
      }
    } else {
      return [
        ...SIDEBAR_ITEMS,
        // { title: 'Register', icon: 'register', path: '/personal-details' },
        { title: 'Login', icon: 'login', path: '/auth/login' },
        { title: 'App name', path: '/contact-us', icon: 'whatsapp', externalLink: true, href: whatsappAPIURL },
        {
          title: 'Terms and Conditions',
          path: '/terms-policy',
          icon: 'terms',
        },
      ]
    }
  }, [isAuthenticated, isInActiveUser, SIDEBAR_ITEMS, AUTH_SIDEBAR_ITEMS_TO_RENDER, isArabic, isSubscribed])

  // Usage in the component
  const sidebar_items = generateSidebarItems()
  const handleClick = (e: LinkToPage) => {
    onSideBarClose()
    if (e.path) {
      if (e.externalLink) {
        if (e.path === '/contact-us' || e.path === '/contact-restaurant') {
          // Use e.href for WhatsApp links
          window.open(e.href, '_blank')
          return
        }
      } else {
        navigate(e.path)
      }
    } else {
      if (e.key === 'language') {
        onClick(i18n.language)
      }
      // Show dialog if e.key is 'logout'
      if (e.key === 'logout') {
        setOpen(true) // Set the state to open the dialog
      }
    }
  }

  const [open, setOpen] = useState(false)

  const handleLogout = () => {
    dispatch(cleanFoodSelectionData())
    dispatch(cleanUserData())
    navigate('/')
    setOpen(false) // Close the dialog after logout
  }

  const handleSubscribe = () => {
    if (planDetail?.plan?.restaurant_slug) {
      localStorage.setItem('restaurant_slug', planDetail?.plan?.restaurant_slug as string)
    }
    navigate(isAuthenticated ? '/checkout' : '/personal-details')
    if (!isAuthenticated) {
      dispatch(setRedirectToCheckoutOnSuccess(true))
    }
  }

  const handleArrowBackClick = () => {
    // window.history.back()
    const otherURLS = ['/auth/login', '/auth/register', '/food-selection']
    const currentPath = window.location.pathname

    // Default values if a slug or planID is not provided
    let _slug = slug
    let _planID = planID

    // Checkout data is stored in localStorage when the user clicks on the "Subscribe" button
    const checkoutData = localStorageGet('checkoutData')
    if (checkoutData) {
      const { restaurantSlug, planID: planIDInCheckout } = checkoutData
      // If the user is on the "/checkout" page, and user presses back button
      if (currentPath == '/checkout') {
        _slug = slug || restaurantSlug
        _planID = planID || planIDInCheckout
      }
    }

    if (_slug && _planID) {
      if (currentPath == '/checkout') {
        // The user is on the "/checkout" page.
        navigate(`/restaurants/${_slug}/${_planID}`)
      } else {
        // The user is on the "/restaurants/:slug/:planID" page.
        // window.location.href = `/restaurants/${slug}`
        navigate(`/restaurants/${_slug}`)
      }
    } else if (_slug) {
      // The user is on the "/restaurants/:slug" page.
      navigate('/')
    } else if (otherURLS.includes(currentPath)) {
      // The user is on the home page. or any other page.
      navigate('/')
    } else {
      window.history.back()
    }
  };


  const handleReload = () => {
    navigate("/");

  }
  const CONTENT_HEIGHT = 64
  const location = useLocation()
  const isIndexPage = location.pathname === '/' ? 1 : 0
  return (
    <PageWrapper
      sx={{
        minHeight: '100vh', // Full screen height
        paddingTop: onMobile ? TOPBAR_MOBILE_HEIGHT : TOPBAR_DESKTOP_HEIGHT,
      }}
    >
      {menuHeader ? (
        <TopBar
          startNode={
            <Navigation>
              <MenuIcon className="pointer" onClick={onSideBarOpen} />
              <Title>{title}</Title>
            </Navigation>
          }
          isLoading={isLoading}
          onClick={handleReload}
        />
      ) : (
        <TopBar
          startNode={
            <Box>
              <Navigation>
                <ArrowBackIosIcon fontSize="small" className="pointer" onClick={handleArrowBackClick} />
                <Title>{title}</Title>
              </Navigation>
            </Box>
          }
          isLoading={isLoading}
          onClick={handleReload}
        />
      )}
      <SideBar
        anchor={anchor}
        open={sideBarVisible}
        variant={sidebarVariant}
        items={sidebar_items}
        onClose={onSideBarClose}
        onClick={handleClick}
      />
      <Main component="main">
        <Content contentheight={CONTENT_HEIGHT} isIndexPage={isIndexPage}>
          <ErrorBoundary name="Content">{children}</ErrorBoundary>
          {/* {(menuHeader || withFooter) && <Footer />} */}
        </Content>

        {hasFooter && (
          <PriceWrapper onMobile={onMobile}>
            <Container>
              <Flex dir={i18n.dir()}>
                {!restaurantStateLoading && (
                  <>
                    <StyledFormControl onMobile={onMobile} fullWidth={onMobile}>
                      <InputLabel id="my-select-label">{t('PACKAGE')}</InputLabel>
                      <Select
                        fullWidth={onMobile}
                        labelId="select-package"
                        id="select-package"
                        value={value.toString()}
                        label="Package"
                        sx={{
                          height: '49px',
                          mt: 0.5,
                        }}
                        disabled={!packages} // Disable the select when packages is undefined
                        onChange={(e: SelectChangeEvent) => setValue(e.target.value as string)}
                      >
                        {packages?.map((item: Package) => (
                          <MenuItem key={item.id} value={item.id}>
                            <>
                              {item.discounted_price ? (
                                <>
                                  <span
                                    style={{
                                      textDecoration: 'line-through',
                                      color: '#999',
                                    }}
                                  >
                                    ({removeTrailingZeros(item.price)})
                                  </span>{' '}
                                  {removeTrailingZeros(item.discounted_price)}
                                  {` ${t('KWD')} , `}
                                </>
                              ) : (
                                <>
                                  {removeTrailingZeros(item.price)} {`${t('KWD')} , `}
                                </>
                              )}
                            </>

                            {` ${item.period} ${t('DAY')} ,  ${t('OFF')} : ${item.off_days.length > 0
                              ? item.off_days.map((day) => (isArabic ? day.day_arabic : day.day)).join(', ')
                              : ` ${t('WITHOUT_OFF')}`
                              }`}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledFormControl>
                    <StyledButton
                      fullWidth={onMobile}
                      variant="contained"
                      color="primary"
                      onClick={handleSubscribe}
                    >
                      {t('PROCEED_TO_PAY')}
                    </StyledButton>
                  </>
                )}
              </Flex>
            </Container>
          </PriceWrapper>
        )}
      </Main>
      <StyledDialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('LOGOUT')}</DialogTitle>
        <DialogContent>
          <Typography>{t('ARE_YOU_SURE')}</Typography>
        </DialogContent>
        <StyledActions>
          <DialogButton size="small" variant="outlined" onClick={() => setOpen(false)}>
            {t('CANCEL')}
          </DialogButton>
          <DialogButton size="small" variant="outlined" color="primary" onClick={handleLogout} className="ml">
            {t('LOGOUT')}
          </DialogButton>
        </StyledActions>
      </StyledDialog>
    </PageWrapper>
  )
}

export default Layout1
