import { useEffect } from 'react'
// Redux
import { useAppSelector } from '../../store/hooks'

import { useTranslation } from 'react-i18next'
import Layout1 from '../../layout/Layout1'

const ContactRestaurant = () => {
  const { i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  const { userData } = useAppSelector((state) => state.accounts)
  const { selectedSubscription } = useAppSelector((state) => state.foodselection)


  const restaurantName = isArabic ? selectedSubscription?.restaurant_name_arabic : selectedSubscription?.restaurant_name
  const planTitle = isArabic ? selectedSubscription?.plan_title_arabic : selectedSubscription?.plan_title
  const contactNumber = selectedSubscription?.contact_number

  const message = isArabic
    ? `احتاج لبعض المساعدة ${planTitle} من خلال مطعم ${restaurantName} السلام عليكم، أنا مشترك في باقة `
    : `Hi, I am subscribed to ${planTitle} through ${restaurantName}. I need some help.`

  useEffect(() => {
    if (selectedSubscription) {
      const encodedMessage = encodeURIComponent(message)
      const whatsappAPIURL = `https://wa.me/${contactNumber}/?text=${encodedMessage}`

      // Add a state object to the history entry
      window.history.pushState({ redirectToHome: true }, '', '/')

      // Redirect to WhatsApp
      window.location.href = whatsappAPIURL
    }
  }, [userData, message, contactNumber])

  useEffect(() => {
    // Listen for changes in the browser's history
    const handleHistoryChange = (event: PopStateEvent) => {
      // Check if the user is coming back from WhatsApp
      if (event.state && event.state.redirectToHome) {
        // Redirect the user to the home page
        window.location.href = '/'
      }
    }

    // Add the history change listener
    window.addEventListener('popstate', handleHistoryChange)

    // Remove the listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleHistoryChange)
    }
  }, [])

  return <Layout1 title={restaurantName} withFooter isLoading={true}></Layout1>
}

export default ContactRestaurant
