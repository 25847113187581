import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";

const ReadMore = ({
  children,
  maxCharacterCount = 100,
    }: PropsWithChildren<{
      maxCharacterCount?: number;
}>) => {
  if (!children) return null;

  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const text = Array.isArray(children)
    ? children.join("")
    : children.toString();
  const [isTruncated, setIsTruncated] = useState(
    text.length > maxCharacterCount
  );

  const resultString = isTruncated ? text.slice(0, maxCharacterCount) : text;

  function toggleIsTruncated() {
    setIsTruncated(!isTruncated);
  }

  const readMore = isArabic ? "اقرأ المزيد..." : "... Read more";

  return (
    <span>
      {resultString}
      <span onClick={toggleIsTruncated}>{isTruncated ? readMore : ""}</span>
    </span>
  );
};

export default ReadMore;
