import { Height } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles";

export const newTheme: ThemeOptions = createTheme({
  typography: {
    fontFamily: "JannaLT",
    allVariants: {
      textTransform: "none",
    },
    h1: {
      fontSize: "20px",
      fontWeight: 700,
      letterSpacing: "0.1px",
    },
    h2: {
      fontSize: "16px",
      fontWeight: 700,
      letterSpacing: "0.08px",
    },
    h3: {
      fontSize: "14px",
      fontWeight: 700,
      letterSpacing: "0.07px",
    },
    h4: {
      fontSize: "12px",
      fontWeight: 700,
      letterSpacing: "0.06px",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#10a67b",
      light: "#D8EEE9",
    },
    secondary: {
      main: "#f18f01",
    },
    background: {
      default: "#f4f6f9",
      paper: "#ffffff",
    },
    text: {
      primary: "#272D2D",
      secondary: "#7587A1",
      disabled: "#7587A1",
    },
    error: {
      main: "#D32F2F",
    },
    divider: "#E7ECF3",
  },

  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#ECF8F5",
          color: "#10A67B",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          boxShadow: "none", // sets elevation to 0
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "0px",
          paddingTop: "15px",
        },
        title: {
          color: "#272D2D",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "145%",
          letterSpacing: "-0.066px",
          padding: "0px",
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          borderRadius: 10, // Set the border radius you want
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none", // sets elevation to 0
        },
        contained: {
          color: "#FFF",
          borderRadius: "var(--6, 48px)",
          backdropFilter: "blur(2px)",
          backgroundColor: "#10A67B",
          lineHeight: "145%",
          fontWeight: 700,
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "solid 2px #E4E4E4",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "10px",
        },
        icon: {
          transition: "transform 0.5s ease-in-out",
        },
      },
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          width: "208px",
          height: "var(--6, 48px)",
          flexShrink: 0,
          borderRadius: "var(--1, 8px)",
          backgroundColor: "#FFFFFF",
          padding: "0 12px",
          gap: "8px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "24px !important",
          color: "var(--3, #7587A1)",
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          fontSize: "14px",
        },
      },
      styleOverrides: {
        root: {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontSize: "14px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          // This targets the dropdown paper element
          backgroundColor: "#F4F6F9", // Set your desired background color here
          color: "#556885",
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        variant: "scrollable",
        scrollButtons: "auto",
        allowScrollButtonsMobile: true,
        TabIndicatorProps: { sx: { display: "none" } },
      },
      styleOverrides: {
        root: {
          p: "3px",
          position: "relative",
          "& .MuiTabs-scroller": {
            display: "flex",
            alignItems: "center",
          },
          "& .MuiTabs-flexContainer": {
            gap: "8px",
          },
          "& .MuiTabScrollButton-root": {
            position: "absolute",
            top: "50%",
            height: "100%",
            transform: "translateY(-50%)",
            margin: "0px",
            display: "flex",
            width: "25px",
            zIndex: 99,
          },
          "& .MuiTabScrollButton-root:first-of-type": {
            left: 0,
            justifyContent: "flex-start",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to right, rgba(244, 246, 249,1), transparent)",
              zIndex: -1,
            },
          },
          "& .MuiTabScrollButton-root:last-of-type": {
            right: 0,
            justifyContent: "flex-end",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to left, rgba(244, 246, 249,1), transparent)",
              zIndex: -1,
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: "1px solid #DBDBDB",
          borderRadius: "10px",
          minWidth: "0px",
          px: "15px",
          py: "11px",
          fontSize: "14px",
          minHeight: "0px",
          "&.Mui-selected": {
            background: "#f18f01",
            color: "white",
          },
          height: "32px",
        },
      },
    },
  },

  shape: {
    borderRadius: 10,
  },
});
