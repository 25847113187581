import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
  TypographyProps,
} from "@mui/material";
const iOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

const CustomSwipeableDrawer = ({
  children,
  open,
  onClose,
  onOpen,
  height,
  ...otherProps
}: SwipeableDrawerProps & { height: string }) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      disableDiscovery={iOS}
      disableBackdropTransition={!iOS}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        sx: (theme) => ({
          borderRadius: "24px 24px 0px 0px",
          padding: "16px 0px",
          flexShrink: 0,
          overflow: "hidden",
          height: height,
          backgroundColor: theme.palette.background.default,
        }),
      }}
      {...otherProps} // allows for additional props to be passed through
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          color: "#556885",
          backgroundColor: "transparent",
        }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </SwipeableDrawer>
  );
};

interface DrawerTypographyProps extends TypographyProps {
  children: React.ReactNode;
  marginBottom?: string;
  marginTop?: string;
}

const DrawerHeading: React.FC<DrawerTypographyProps> = ({
  children,
  marginBottom,
  marginTop,
  ...props
}) => {
  return (
    <Typography
      variant="h1"
      align="center"
      sx={{
        color: "#272D2D",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "160%",
        letterSpacing: "-0.48px",
        marginBottom: marginBottom || "18px", // Default margin bottom
        marginTop: marginTop || "0", // Default margin top
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

const DrawerText: React.FC<DrawerTypographyProps> = ({
  children,
  marginBottom,
  marginTop,
  ...props
}) => {
  return (
    <Typography
      align="center"
      sx={{
        color: "#556885",
        textAlign: "center",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
        marginBottom: marginBottom || "8px", // Default margin bottom
        marginTop: marginTop || "0", // Default margin top
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export { DrawerHeading, DrawerText };

export default CustomSwipeableDrawer;
