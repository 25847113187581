import { I18nextProvider } from 'react-i18next'
import { AppThemeProvider } from './theme'
import { ErrorBoundary } from './components'
import i18n from './locale/index'

import Localization from './Localization'

const App: React.FC = () => {
  return (
    <ErrorBoundary name="App">
      <I18nextProvider i18n={i18n}>
        <AppThemeProvider>
          <Localization />
        </AppThemeProvider>
      </I18nextProvider>
    </ErrorBoundary>
  )
}

export default App
