import axios from 'axios'

// ----------------------------------------------------------------------

const HOST = process.env.REACT_APP_DRF_PUBLIC_API_HOST || 'https://stage-api.eshterakaat.com'

const axiosInstance = axios.create({ baseURL: HOST })

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || error),
)

export default axiosInstance
