import { Box, Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsAuthenticated } from "../../hooks";
// Redux

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getMySubscriptions } from "../../store/slices/subscriptions";

import dayjs from "dayjs";
import "dayjs/locale/ar"; // Import the Arabic locale

import { ReactComponent as SubscriptionCardPauseIcon } from "../../assets/icons/subscription_card_pause.svg";
import { ReactComponent as SubscriptionCardResumeIcon } from "../../assets/icons/subscription_card_resume.svg";

import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";

import { Layout } from "../../layout/Layout2";
import { MySubscription } from "../../types/subscriptions";

import { DateTime } from "luxon";
import SubscriptionAction from "../../components/SubscriptionAction";
import { Helmet } from "react-helmet";

const kuwaitDateTime = DateTime.now()
  .setZone("Asia/Kuwait")
  .startOf("day")
  .toJSDate();
const todayDateInKuwait = kuwaitDateTime.toDateString();

const MySubscriptions = () => {
  const user = useIsAuthenticated();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const { mySubscriptions, isLoading } = useAppSelector(
    (state) => state.subscriptions
  );
  useEffect(() => {
    const token = user?.token?.access as string;
    dispatch(getMySubscriptions(token));
  }, []);

  return (
    <Layout title={t("SUBSCRIPTIONS_HEAD") || ""} isLoading={isLoading}>
      <Helmet>
        <title>
          {isArabic
            ? "اشتراكاتك | اشتراكات للمطاعم الصحية"
            : "My Subscriptions | Eshtrakaat for Subscriptions Restaurants"}
        </title>
        <meta
          name="description"
          content={
            isArabic
              ? "شاهد الإشتراكات التي قمت بالدفع لها، سواء كانت إشتراكات قديمة أو فعالة | اشتراكات للمطاعم الصحية"
              : "Check you old & active subscriptions | Eshtrakaat for Subscriptions Restaurants"
          }
        />
      </Helmet>
      <Grid container spacing={{ xs: 2, lg: 2 }}>
        {mySubscriptions?.map((row: MySubscription) => (
          <SubscriptionCard row={row} />
        ))}
      </Grid>
    </Layout>
  );
};

const SubscriptionCard = ({ row }: { row: MySubscription }) => {
  const { t, i18n } = useTranslation();
  const today = dayjs(todayDateInKuwait);

  const [showCalendar, setShowCalendar] = useState(false);

  const isArabic = i18n.language === "ar";

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const isShowPause = useMemo(() => {
    // get two days from today in string

    if (!row.paused_dates || row.paused_dates.length === 0) {
      return true;
    }

    const excludeLockDays = [
      today.format("YYYY-MM-DD"),
      today.add(1, "day").format("YYYY-MM-DD"),
      today.add(2, "day").format("YYYY-MM-DD"),
    ];

    const filteredPausedDates = row.paused_dates.filter((date) => {
      return !excludeLockDays.includes(dayjs(date).format("YYYY-MM-DD"));
    });

    if (!filteredPausedDates.length) {
      return true;
    }

    return false;
  }, [row]);

  return (
    <Grid item xs={12} sm={6} md={4} key={row.id}>
      <Card
        sx={{
          borderRadius: "12px",
          backgroundColor: isShowPause ? "#D8EEE9" : "#FFFFFF",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: isShowPause ? "#F18F01" : "#556885",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {row.status === "active"
                ? isShowPause
                  ? t("SUBSCRIPTIONS_ACTIVE_SUBTITLE")
                  : t("SUBSCRIPTIONS_PAUSE_SUBTITLE")
                : row.status === "cancelled"
                ? t("CANCELLED")
                : row.status === "old"
                ? t("OLD")
                : row.status === "unpaid"
                ? t("UNPAID")
                : null}
            </Typography>
            <Typography
              sx={{ color: "#272D2D", fontSize: "16px", fontWeight: 700 }}
            >
              {isArabic ? row.plan_arabic : row.plan}
            </Typography>
            <Typography
              variant="h3"
              alignItems="center"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontWeight: 400,
                lineHeight: "170%",
                letterSpacing: "0.3px",
                textTransform: "capitalize",
                wordSpacing: "2px",
              })}
            >
              {isArabic ? (
                <>
                  {`${row.restaurant_arabic} `}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                  >
                    <circle cx="2" cy="2" r="2" fill="#7587A1" />
                  </svg>{" "}
                  {` ينتهي ${dayjs(row.end_date)
                    .locale("ar")
                    .format("D MMMM")}`}
                </>
              ) : (
                <>
                  {`${row.restaurant} `}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                  >
                    <circle cx="2" cy="2" r="2" fill="#7587A1" />
                  </svg>{" "}
                  {` Ends ${dayjs(row.end_date).format("MMM D")}`}
                </>
              )}
            </Typography>
          </Box>
          <Stack direction="column">
            {row.status === "active" && (
              <>
                {isShowPause ? (
                  <IconButton
                    sx={{
                      backgroundColor: "transparent",
                    }}
                    onClick={() => setShowCalendar(true)}
                  >
                    <SubscriptionCardPauseIcon
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      backgroundColor: "transparent",
                    }}
                    onClick={() => setShowCalendar(true)}
                  >
                    <SubscriptionCardResumeIcon
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </IconButton>
                )}
              </>
            )}

            <IconButton
              onClick={handleToggle}
              sx={{
                backgroundColor: "transparent",
              }}
            >
              {isOpen ? (
                <ArrowDownIcon
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : (
                <ArrowUpIcon
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              )}
            </IconButton>
          </Stack>
        </Box>

        {!isOpen && (
          <Grid
            container
            justifyContent="space-between"
            rowGap="4px"
            sx={{
              marginTop: "16px",
              border: "1px solid rgba(16, 166, 123, 0.24)",
              borderRadius: "8px",
              padding: "12px",
              background: "rgba(16, 166, 123, 0.10)",
            }}
          >
            <Grid item xs={4} justifyContent="center">
              <Typography
                color="#556885"
                fontSize="12px"
                textAlign="center"
                fontWeight={700}
              >
                {t("PERSONAL_DETAILS.START_DATE")}
              </Typography>
            </Grid>
            <Grid item xs={4} justifyContent="center">
              <Typography
                color="#556885"
                fontSize="12px"
                textAlign="center"
                fontWeight={700}
              >
                {t("PERSONAL_DETAILS.END_DATE")}
              </Typography>
            </Grid>
            <Grid item xs={4} justifyContent="center">
              <Typography
                color="#556885"
                fontSize="12px"
                textAlign="center"
                fontWeight={700}
              >
                {t("PERSONAL_DETAILS.PRICE")}
              </Typography>
            </Grid>
            <Grid item xs={4} justifyContent="center">
              <Typography
                color="#272D2D"
                fontSize="14px"
                textAlign="center"
                letterSpacing="0.3px"
              >
                {row.start_date}
              </Typography>
            </Grid>
            <Grid item xs={4} justifyContent="center">
              <Typography
                color="#272D2D"
                fontSize="14px"
                textAlign="center"
                letterSpacing="0.3px"
              >
                {row.end_date}
              </Typography>
            </Grid>
            <Grid item xs={4} justifyContent="center">
              <Typography
                color="#272D2D"
                fontSize="14px"
                textAlign="center"
                letterSpacing="0.3px"
              >
                {row.price} {t("KWD")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Card>
      <SubscriptionAction
        key={row.id}
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        isShowPause={isShowPause}
        row={row}
      />
    </Grid>
  );
};

export default MySubscriptions;
