import { createSlice } from "@reduxjs/toolkit";

import { AppDispatch } from "../";
import { MySubscription } from "../../types/subscriptions";
import axios from "../../utils/axios";

// Define a type for the slice state
interface State {
  isLoading: boolean;
  error: unknown | null;
  success: boolean;
  mySubscriptions: MySubscription[];
  subscriptionStatus: string;
  confirmationError: boolean;
}

const initialState: State = {
  isLoading: false,
  error: null,
  success: false,
  mySubscriptions: [],
  subscriptionStatus: "",
  confirmationError: false,
};

const slice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    getMySubscriptionsStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    },
    getMySubscriptionsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.success = true;
      state.mySubscriptions = payload;
    },
    getMySubscriptionsFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.success = false;
    },

    createSubscriptionStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    },

    createSubscriptionSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = true;
    },

    createSubscriptionFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.success = false;
    },

    pauseSubscriptionStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    },

    pauseSubscriptionSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.success = true;
      state.mySubscriptions = state.mySubscriptions.map((subscription) => {
        if (subscription.id === payload.id) {
          return payload;
        }
        return subscription;
      });
    },

    pauseSubscriptionFailure: (state, payload) => {
      state.isLoading = false;
      state.error = payload;
      state.success = false;
    },

    confirmSubscriptionStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    },

    confirmSubscriptionSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.success = true;
      state.subscriptionStatus = payload.status;
    },

    confirmSubscriptionFailure: (state) => {
      state.isLoading = false;
      state.confirmationError = true;
      state.success = false;
    },

    updateMySubscriptions: (state, { payload }) => {
      const allSubscriptions = state.mySubscriptions.map((subscription) => {
        if (subscription.id === payload?.id) {
          return {
            ...subscription,
            selection_days: payload?.selection_days,
            end_date: payload?.end_date,
            period: payload?.total_days,
            paused_dates: payload?.paused_dates,
          };
        }
        return subscription;
      });
      state.mySubscriptions = allSubscriptions;
    },

    resetState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.confirmationError = false;
      state.success = false;
      state.mySubscriptions = [];
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// Actions
export const getMySubscriptions =
  (token: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.getMySubscriptionsStart());
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };
      const { data } = await axios.get(
        "/api/v1/subscriptions/my-subscriptions",
        config
      );
      dispatch(actions.getMySubscriptionsSuccess(data));
    } catch (error) {
      dispatch(actions.getMySubscriptionsFailure(error));
    }
  };

export const resetSubscriptionState = () => (dispatch: AppDispatch) => {
  dispatch(actions.resetState());
};
