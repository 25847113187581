import { LoadingButton } from "@mui/lab";
import { Box, Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SubscriptionErrorIcon } from "../../assets/icons/delete_account_popup.svg";
import { DrawerHeading } from "../../components/CustomSwipeableDrawer";
import { Layout } from "../../layout/Layout2";

const AreaVerificationError: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const isModeArabic = i18n.language === "ar";

  const backToHome = () => {
    navigate("/");
  };

  return (
    <Layout title={""} isLoading={false}>
      <Container
        sx={(theme) => ({
          padding: "16px 0px",
          flexShrink: 0,
          overflow: "hidden",
          backgroundColor: theme.palette.background.default,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          direction: isModeArabic ? "rtl" : "ltr",
          minHeight: "81vh",
        })}
      >
        <Box sx={{ paddingTop: "100px" }}>
          <SubscriptionErrorIcon />

          <DrawerHeading>
            {t("RESTAURANT_DELIVERY_RESTRICTION")}
          </DrawerHeading>
        </Box>

        <div>
          <LoadingButton
            onClick={backToHome}
            variant="contained"
            sx={{
              mt: "10px", // Move the button up by reducing the top margin
              mb: "80px", // Move the button up by reducing the bottom margin
              width: "342px",
              height: "56px",
              maxWidth: "100%",
              display: "flex",
              alignItems: "center",
            }}
            color="primary"
          >
            {t("OTHER_RESTAURANTS")}
          </LoadingButton>
        </div>
      </Container>
    </Layout>
  );
};

export default AreaVerificationError;
