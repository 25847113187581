import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from "@sentry/react";

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as amplitude from '@amplitude/analytics-browser';
import { localStorageGet } from './utils';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);


// Possible Environments
// const DEVELOPMENT = 'development';
const STAGING = 'staging';
const PRODUCTION = 'production';
const DEMO = 'demo';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

const possibleEnvironments = [STAGING, PRODUCTION, DEMO /*DEVELOPMENT*/]; // Add DEVELOPMENT to the array if you want to test Sentry in development

// Get the current environment
const environment = process.env.REACT_APP_ENVIRONMENT;

// Get "user" from local storage
const user = localStorage.getItem('user');

if (environment && possibleEnvironments.includes(environment)) {
    // Initialize Sentry
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT,
        integrations: [
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                networkDetailAllowUrls: [
                    "https://stage-api.eshterakaat.com",
                ],
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: environment === PRODUCTION ? 0.1 : 0.05, // 0.1 == 10% of sessions, 0.05 == 5% of sessions
        // Session Replay
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });

    // User can be null
    if (user) {
        const parsedUser = JSON.parse(user);
        if (parsedUser && parsedUser.phone_number && parsedUser.name) {
            const phoneNumber = parsedUser.phone_number;
            // Remove +965 from start of phone number if it exists
            const phoneNumberWithoutCountryCode = phoneNumber.startsWith('+965') ? phoneNumber.substring(4) : phoneNumber;
            const name = parsedUser.name;
            Sentry.setUser({
                id: phoneNumberWithoutCountryCode,
                username: name,
            });
        }
    }


}

if (environment === PRODUCTION && process.env.REACT_APP_AMPLITUDE != undefined) {
    const user = localStorageGet('user', undefined)

    amplitude.init(process.env.REACT_APP_AMPLITUDE, user?.phone_number, { "autocapture": true });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
