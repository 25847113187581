import {
  Box,
  Button,
  Card,
  CardMedia,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/ar"; // import the Arabic locale
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SubscriptionCardPauseIcon } from "../../assets/icons/subscription_card_pause.svg";
import { ReactComponent as SubscriptionCardResumeIcon } from "../../assets/icons/subscription_card_resume.svg";

import { useAppDispatch } from "../../store/hooks";
import { actions } from "../../store/slices/foodselection";
import { ActiveSubscription } from "../../types/accounts";
import { todayDateInKuwait } from "../../utils";
import SubscriptionAction from "../SubscriptionAction";

const SelectFoodCard: React.FC<{
  to: string;
  subscribedUser: ActiveSubscription;
  subscription_length?: number;
}> = ({ to, subscribedUser }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isArabic = i18n.language === "ar";
  const today = dayjs(todayDateInKuwait);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleNavigation = () => {
    dispatch(actions.setSelectedSubscription(subscribedUser));
    navigate(to);
  };

  const isShowPause = useMemo(() => {
    // get two days from today in string

    if (
      !subscribedUser.paused_dates ||
      subscribedUser.paused_dates.length === 0
    ) {
      return true;
    }

    const excludeLockDays = [
      today.format("YYYY-MM-DD"),
      today.add(1, "day").format("YYYY-MM-DD"),
      today.add(2, "day").format("YYYY-MM-DD"),
    ];

    const filteredPausedDates = subscribedUser.paused_dates.filter((date) => {
      return !excludeLockDays.includes(dayjs(date).format("YYYY-MM-DD"));
    });

    if (!filteredPausedDates.length) {
      return true;
    }

    return false;
  }, [subscribedUser]);

  return (
    <Card
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.light,
        padding: "16px",
      })}
    >
      <Stack direction="column" rowGap="17px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="12px"
        >
          <CardMedia
            alt={subscribedUser.restaurant_name}
            src={subscribedUser.restaurant_logo}
            component="img"
            sx={{
              borderRadius: "6px",
              width: "55px",
              height: "55px",
            }}
          />
          <Box flex={1} rowGap="6px" display="flex" flexDirection="column">
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
              variant="h2"
            >
              {isArabic
                ? subscribedUser.plan_title_arabic
                : subscribedUser.plan_title}
            </Typography>

            <Typography
              variant="h3"
              alignItems="center"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontWeight: 400,
                lineHeight: "170%",
                letterSpacing: "0.3px",
                textTransform: "capitalize",
                wordSpacing: "2px",
              })}
            >
              {isArabic ? (
                <>
                  {`${subscribedUser.restaurant_name_arabic} `}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                  >
                    <circle cx="2" cy="2" r="2" fill="#7587A1" />
                  </svg>{" "}
                  {` ينتهي ${dayjs(subscribedUser.end_date)
                    .locale("ar")
                    .format("D MMMM")}`}
                </>
              ) : (
                <>
                  {`${subscribedUser.restaurant_name} `}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                  >
                    <circle cx="2" cy="2" r="2" fill="#7587A1" />
                  </svg>{" "}
                  {` Ends ${dayjs(subscribedUser.end_date).format("MMM D")}`}
                </>
              )}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" columnGap="8px">
          <Button fullWidth variant="contained" onClick={handleNavigation}>
            {isArabic ? "اختيار الوجبات" : "Select Food"}
          </Button>
          {isShowPause ? (
            <IconButton
              sx={{
                backgroundColor: "transparent",
              }}
              onClick={() => setShowCalendar(true)}
            >
              <SubscriptionCardPauseIcon
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                backgroundColor: "transparent",
              }}
              onClick={() => setShowCalendar(true)}
            >
              <SubscriptionCardResumeIcon
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <SubscriptionAction
        key={subscribedUser.id}
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        isShowPause={isShowPause}
        row={subscribedUser as any}
      />
    </Card>
  );
};

export default SelectFoodCard;
