import {
  Alert,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
// Components
import Snackbar from "../../../components/Snackbar";
// Hooks and utils
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { SHARED_CONTROL_PROPS } from "../../../utils/form";
// Styles

// Redux
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { ReactComponent as PasswordHideIcon } from "../../../assets/icons/password_hide.svg";
import { ReactComponent as PasswordVisibleIcon } from "../../../assets/icons/password_visible.svg";

import {
  DrawerHeading,
  DrawerText,
} from "../../../components/CustomSwipeableDrawer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resetPassword } from "../../../store/slices/accounts";

interface FormStateValues {
  password: string;
}

interface RecoveryPassword {
  count: number;
  setCount: any;
}

/**
 * Renders "Recover Password" view for Login flow
 * url: /uth/recovery/password
 * @param {string} [props.password] - pre-populated password in case the user already enters it
 */
const RecoveryPassword = ({ count, setCount }: RecoveryPassword) => {
  const { t, i18n } = useTranslation();
  const isArabicMode = i18n.language === "ar";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { resetPassLoading, resetPassError, resetPassSuccess, userData } =
    useAppSelector((state) => state.accounts);

  const [message, setMessage] = useState<string>();
  const [openPasswordAlert, setOpenPasswordAlert] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormStateValues>();

  const values = watch();
  const [showPassword, setShowPassword] = useState(false);

  const handleFormSubmit = useCallback(
    async (data: FormStateValues) => {
      const token = userData?.token?.access || "";
      dispatch(resetPassword(token as string, data.password));
    },
    [navigate, dispatch, userData]
  );

  const handleCloseError = useCallback(() => setMessage(undefined), []);
  const isValid =
    /*If password is more than 3 and no errors */ values.password?.length > 2 &&
    !errors.password;

  useEffect(() => {
    // if (!userData) {
    //   navigate(`/auth/recovery/verify`);
    // }
    if (resetPassSuccess) {
      resetPassSuccess && handleResetPasswordAlert();

      setCount(3);
      const countdown = setInterval(() => {
        setCount((prevCount: number) => prevCount - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [userData, resetPassSuccess]);

  const handleResetPasswordAlert = () => {
    setOpenPasswordAlert(true);
  };

  useEffect(() => {
    if (count === 0) {
      navigate(`/`, { replace: true });
    }
  }, [count]);

  const handleClosePasswordAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenPasswordAlert(false);
  };

  const horizontal = i18n.dir() === "rtl" ? "left" : "right";

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DrawerHeading marginTop="48px" marginBottom="8px">
          {t("RETYPE_PASSWORD")}
        </DrawerHeading>
        <DrawerText marginBottom="40px">
          {t("RESET_PASSWORD_MESSAGE")}
        </DrawerText>
        <CardContent>
          <Controller
            control={control}
            name="password"
            rules={{
              required: !isArabicMode
                ? "Password is required"
                : "كلمة المرور مطلوبة",
              minLength: {
                value: 3,
                message: t("PASSWORD_LIMIT_ERROR"),
              },
            }}
            render={({ field }) => (
              <TextField
                required
                label={t("PASSWORD")}
                name={field.name}
                autoComplete="new-password"
                value={field.value}
                error={!!errors[field.name]}
                helperText={errors[field.name]?.message || ""}
                onChange={field.onChange}
                onBlur={field.onBlur}
                InputProps={{
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPasswordClick}
                      >
                        {showPassword ? (
                          <PasswordHideIcon />
                        ) : (
                          <PasswordVisibleIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...SHARED_CONTROL_PROPS}
              />
            )}
          />

          {message ? (
            <Alert severity="success" onClose={handleCloseError}>
              {message}
            </Alert>
          ) : null}

          {resetPassError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {t("RESET_PASSWORD_ERROR")}
            </Alert>
          )}

          {resetPassSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {/* Password Updated Successfully, You will be logged in in ${count} seconds */}
              {isArabicMode
                ? `تم تحديث كلمة المرور بنجاح ، سيتم تسجيل دخولك في ${count} ثوانٍ`
                : `Password Updated Successfully, You will be logged in in ${count} seconds`}
            </Alert>
          )}

          <Grid
            marginTop="48px"
            container
            justifyContent="center"
            alignItems="center"
          >
            <LoadingButton
              type="submit"
              loading={resetPassLoading}
              disabled={!isValid}
              fullWidth
              variant="contained"
              size="large"
              sx={(theme) => ({
                padding: "var(--1, 15px) var(--2, 0px)",
                height: "var(--7, 56px)",
                borderRadius: "var(--6, 48px)",
                backgroundColor: `${theme.palette.primary.main}`,
                fontSize: "17px",
                fontWeight: 700,
                letterSpacing: "0.2px",
              })}
            >
              {t("RESET_PASSWORD_ACTION")}
            </LoadingButton>
          </Grid>
        </CardContent>
        <Snackbar
          handleOpen={openPasswordAlert}
          onClose={handleClosePasswordAlert}
          message={t("PASSWORD_CHANGED")}
          anchorOrigin={{ vertical: "top", horizontal }}
        />
      </form>
    </>
  );
};

export default RecoveryPassword;

{
  /* <Helmet>
<title>
  {isArabicMode
    ? " تغيير كلمة السر | اشتراكات للمطاعم الصحية "
    : "Login  | Eshtrakaat for Subscriptions Restaurant"}
</title>
<meta
  name="description"
  content={
    isArabicMode
      ? " يمكنك تغيير كلمة السر الخاصة بحسابك من خلال هذه الصفحة  | اشتراكات للمطاعم الصحية"
      : " Password Reset | Eshtrakaat for Subscriptions Restaurants"
  }
/>
</Helmet> */
}
