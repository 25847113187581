import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SymbolsEditIcon } from "../../../assets/icons/symbols_edit.svg";

// Components
// Utilties and Hooks
import { SHARED_CONTROL_PROPS } from "../../../utils";

import { resetInitialState } from "../../../store/slices/globals";
import { TemoraryUserData } from "../../../types/accounts";
import { getCitiesOptions } from "../../../utils/getCitiesOptions";
// Styles
// Redux
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { RHFAutocomplete, RHFTextField } from "../../../components/hook-form";
import FormProvider from "../../../components/hook-form/form-provider";
import { Layout } from "../../../layout/Layout2";
import {
  resetSignupRequestsState,
  updateTemporaryPersonalDetails,
} from "../../../store/slices/accounts";
import { getCities } from "../../../store/slices/cities";

const DetailForm = () => {
  const { t, i18n } = useTranslation();
  const isArabicMode = i18n.language === "ar";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { cities, success } = useAppSelector((state) => state.cities);
  const { temporaryUserData } = useAppSelector((state) => state.accounts);

  useEffect(() => {
    if (!success) {
      dispatch(getCities());
    }
  }, [cities, success]);

  useEffect(() => {
    dispatch(resetSignupRequestsState());
    dispatch(resetInitialState());
  }, []);

  const defaultValues = useMemo(() => {
    return {
      name: temporaryUserData?.name || "",
      block: temporaryUserData?.block || "",
      street: temporaryUserData?.street || "",
      avenue: temporaryUserData?.avenue || "",
      house: temporaryUserData?.house || "",
      area: temporaryUserData?.area || 0,
    };
  }, [temporaryUserData]);

  const UpdateProfileSchema = Yup.object().shape({
    name: Yup.string()
      .required(`${t("NAME")} ${t("ISREQUIRED")}`)
      .matches(/^[a-zA-Z\u0600-\u06FF\s]*$/, `${t("INVALID_NAME")}`)
      .min(2, `${t("NAME")} ${t("ERROR.NAME_RANGE")}`)
      .max(40, `${t("NAME")} ${t("ERROR.NAME_RANGE")}`),
    street: Yup.string()
      .required(`${t("PERSONAL_DETAILS.STREET")} ${t("ISREQUIRED")}`)
      .min(
        1,
        `${t("PERSONAL_DETAILS.STREET")} ${t("ERROR.PREFIX")} 60 ${t(
          "ERROR.DIGITS"
        )}`
      )
      .max(
        60,
        `${t("PERSONAL_DETAILS.STREET")} ${t("ERROR.PREFIX")} 60 ${t(
          "ERROR.DIGITS"
        )}`
      ),
    avenue: Yup.string().max(
      30,
      `${t("AVENUE")} ${t("ERROR.PREFIX")} 30 ${t("ERROR.DIGITS")}`
    ), // Optional field
    block: Yup.string()
      .required(`${t("PERSONAL_DETAILS.BLOCK")} ${t("ISREQUIRED")}`)
      .min(
        1,
        `${t("PERSONAL_DETAILS.BLOCK")} ${t("ERROR.PREFIX")} 4 ${t(
          "ERROR.DIGITS"
        )}`
      )
      .max(
        4,
        `${t("PERSONAL_DETAILS.BLOCK")} ${t("ERROR.PREFIX")} 4 ${t(
          "ERROR.DIGITS"
        )}`
      ),
    house: Yup.string()
      .required(`${t("PERSONAL_DETAILS.HOUSE")} ${t("ISREQUIRED")}`)
      .min(
        1,
        `${t("PERSONAL_DETAILS.HOUSE")} ${t("ERROR.PREFIX")} 5 ${t(
          "ERROR.DIGITS"
        )}`
      )
      .max(
        5,
        `${t("PERSONAL_DETAILS.HOUSE")} ${t("ERROR.PREFIX")} 5 ${t(
          "ERROR.DIGITS"
        )}`
      ),
    area: Yup.number()
      .integer()
      .required(`${t("PERSONAL_DETAILS.AREA")} ${t("ISREQUIRED")}`),
  });

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(UpdateProfileSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, isLoading },
  } = methods;

  const onSubmit = handleSubmit(async (state, event) => {
    event?.preventDefault();

    const data = {
      name: state.name,
      block: state.block,
      street: state.street,
      avenue: state.avenue || "",
      house: state.house,
      area: state.area,
      phone_number: "",
      userData: null,
    };
    dispatch(updateTemporaryPersonalDetails(data as TemoraryUserData));
    navigate(`/auth/signup`);
  });

  return (
    <Layout title={`${t("PERSONAL_DETAILS")}`}>
      <Helmet>
        <title>
          {isArabicMode
            ? "التسجيل | العنوان | اشتراكات للمطاعم الصحية"
            : "Register personal details| Eshtrakaat for Subscriptions Restaurants"}
        </title>
        <meta
          name="description"
          content={
            isArabicMode
              ? " سجل حساب جديد عبر ادخال عنوانك واسمك | اشتراكات للمطاعم الصحية"
              : "Register new account | Eshtrakaat for Subscriptions Restaurants"
          }
        />
      </Helmet>
      <div
        style={{
          display: "flex",
          flexGrow: "1",
          justifyContent: "center",
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
              height: "100%",
              maxWidth: "600px",
            }}
            mt="40px"
            rowGap="40px"
          >
            <Box>
              <Typography
                sx={{ fontSize: "24px", marginBottom: "12px", fontWeight: 700 }}
              >
                {t("PERSONAL_DETAILS")}
              </Typography>
              <Typography
                sx={{ fontSize: "13px", fontWeight: 400, color: "#3F526F" }}
              >
                {t("SIGNUP.CREATE_ACCOUNT.PERSONAL_DETAIL.MESSAGE")}
              </Typography>
            </Box>

            <Grid
              container
              flex={1}
              justifyContent="space-between"
              flexDirection="column"
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RHFTextField
                    required
                    label={t("NAME")}
                    name="name"
                    {...SHARED_CONTROL_PROPS}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SymbolsEditIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RHFAutocomplete
                    fullWidth
                    noOptionsText={t("PERSONAL_DETAILS.NO_CITIES")}
                    loadingText="Loading Area Name..."
                    sx={{ backgroundColor: "transparent" }}
                    name="area"
                    label={t("PERSONAL_DETAILS.AREA") || ""}
                    options={getCitiesOptions(cities, isArabicMode)}
                    getOptionLabel={(option: any) =>
                      typeof option === "string" ? option : option?.label ?? ""
                    }
                    isOptionEqualToValue={(option: any, value: any) => {
                      return option.label === value;
                    }}
                    onChange={(event: any, value: any) => {
                      if (!value) return;
                      methods.setValue(
                        "area",
                        typeof value === "object" ? value.value : value,
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                    value={
                      getCitiesOptions(cities, isArabicMode)?.find(
                        (city) => city.value === methods.watch("area")
                      )?.label || ""
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField
                    required
                    label={t("PERSONAL_DETAILS.STREET")}
                    name="street"
                    {...SHARED_CONTROL_PROPS}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  <RHFTextField
                    required
                    label={t("PERSONAL_DETAILS.BLOCK")}
                    name="block"
                    {...SHARED_CONTROL_PROPS}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={4}>
                  <RHFTextField
                    label={t("PERSONAL_DETAILS.AVENUE")}
                    name="avenue"
                    autoComplete="off"
                    {...SHARED_CONTROL_PROPS}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RHFTextField
                    required
                    label={t("PERSONAL_DETAILS.HOUSE")}
                    name="house"
                    autoComplete="off"
                    {...SHARED_CONTROL_PROPS}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <LoadingButton
                    type="submit"
                    loading={isLoading || isSubmitting}
                    disabled={!(isValid && isDirty)}
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={(theme) => ({
                      padding: "var(--1, 15px) var(--2, 0px)",
                      height: "var(--7, 56px)",
                      borderRadius: "var(--6, 48px)",
                      backgroundColor: `${theme.palette.primary.main}`,
                      fontSize: "17px",
                      fontWeight: 700,
                      letterSpacing: "0.2px",
                      marginBottom: "30px",
                    })}
                  >
                    {t("PERSONAL_DETAILS.NEXT_BUTTON")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </div>
    </Layout>
  );
};

export default DetailForm;
