import { LoadingButton } from "@mui/lab";
import { Alert, Box, Grid } from "@mui/material";
import {
  DateCalendar,
  DateRangeCalendar,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ar"; // Import the Arabic locale

import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsAuthenticated } from "../../hooks";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  pauseActiveSubscription,
  resumeActiveSubscription,
} from "../../store/slices/accounts";
import { actions } from "../../store/slices/subscriptions";
import { MySubscription } from "../../types/subscriptions";
import { todayDateInKuwait } from "../../utils";
import CustomSwipeableDrawer, {
  DrawerHeading,
  DrawerText,
} from "../CustomSwipeableDrawer";
// Load plugins
dayjs.extend(utc);
dayjs.extend(timezone);

interface SubscriptionActionProps {
  showCalendar: boolean;
  setShowCalendar: (value: boolean) => void;
  isShowPause: boolean;
  row: MySubscription;
}

interface ErrorState {
  detail?: string;
}

export default function SubscriptionAction({
  showCalendar,
  setShowCalendar,
  isShowPause,
  row,
}: SubscriptionActionProps) {
  const { t, i18n } = useTranslation();
  const today = dayjs(todayDateInKuwait);
  const isArabic = i18n.language === "ar";
  const user = useIsAuthenticated();
  const dispatch = useAppDispatch();
  const { isloading_action, error_action, payloadSubscriptionAction } =
    useAppSelector((state) => state.accounts);

  const isLoadingState = isloading_action;

  const isErrorState = error_action as ErrorState | null;

  const [value, setValue] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [dateToResume, setDateToResume] = useState<Dayjs | null>(null);
  const referenceStartDate = dayjs(row.start_date);

  const shouldDisableDate = (date: Dayjs) => {
    const formattedDate = dayjs(date).tz("Asia/Kuwait");

    if (isShowPause) {
      return (
        formattedDate.isBefore(row.start_date, "day") ||
        formattedDate.isAfter(row.end_date, "day") ||
        row.off_days.split(",").includes(formattedDate.format("ddd")) ||
        dayjs(formattedDate).isBefore(today, "day") ||
        dayjs(formattedDate).isSame(today, "day") ||
        dayjs(formattedDate).isSame(today.add(1, "day"), "day") ||
        dayjs(formattedDate).isSame(today.add(2, "day"), "day")
      );
    } else {
      if (dayjs(formattedDate).isSame(today, "day")) return true;
      if (dayjs(formattedDate).isSame(today.add(1, "day"), "day")) return true;
      if (dayjs(formattedDate).isSame(today.add(2, "day"), "day")) return true;

      // disable all dates except paused dates
      return !row.paused_dates?.includes(formattedDate.format("YYYY-MM-DD"));
    }
  };

  const handlePause = async () => {
    const token = user?.token?.access as string;

    if (row.id && value[0] && value[1]) {
      await dispatch(
        pauseActiveSubscription(
          {
            uuid: row.id,
            start_date: value[0].format("YYYY-MM-DD"),
            end_date: value[1].format("YYYY-MM-DD"),
          },
          token
        )
      );
    }
  };

  const handleResume = async () => {
    const token = user?.token?.access as string;
    if (row.id && dateToResume) {
      await dispatch(
        resumeActiveSubscription(
          {
            uuid: row.id,
            date_to_resume: dayjs(dateToResume).format("YYYY-MM-DD"),
          },
          token
        )
      );
    }
  };

  useEffect(() => {
    if (!isLoadingState && !isErrorState) {
      setShowCalendar(false);
      setValue([null, null]);
      setDateToResume(null);
      dispatch(actions.updateMySubscriptions(payloadSubscriptionAction));
    }
  }, [isLoadingState, isErrorState]);

  const handleCloseCalendar = () => {
    setShowCalendar(false);
    setValue([null, null]);
    setDateToResume(null);
  };

  return (
    <CustomSwipeableDrawer
      height={isErrorState ? "690px" : "650px"}
      open={showCalendar}
      onOpen={() => setShowCalendar(true)}
      onClose={handleCloseCalendar}
    >
      <DrawerHeading marginTop="48px" marginBottom="0px">
        {isShowPause ? t("SELECT_PAUSED_DAYS") : t("SELECT_RESUME_DAYS")}
      </DrawerHeading>

      <DrawerText marginTop="0px">
        {isShowPause ? t("SELECT_PAUSE_PERIODS") : t("SELECT_RESUME_PERIODS")}
      </DrawerText>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "24px",
        }}
      >
        {isShowPause && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangeCalendar
              dayOfWeekFormatter={(dayOfWeek, date) =>
                isArabic
                  ? dayjs(date).locale("ar").format("dddd")
                  : dayjs(date).format("dd")
              }
              sx={{
                backgroundColor: "#FFF",
                borderRadius: "12px",
                padding: "20px",
                paddingBottom: "0px",
                "& .MuiButtonBase-root .MuiPickersDay-root.Mui-selected": {
                  backgroundColor: "#F18F01 !important", // Golden color
                  color: "#FFF", // White text color
                },

                "& .MuiPickersArrowSwitcher-button": {
                  backgroundColor: "transparent", // Hide circle background
                  color: "#556885", // Set arrow color if needed
                },

                "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
                  backgroundColor: "#FEF6EB !important", // Lighter variant for the range
                  color: "#272D2D",
                },
              }}
              calendars={1}
              value={value}
              onChange={(newValue) => setValue(newValue)}
              shouldDisableDate={shouldDisableDate}
              referenceDate={referenceStartDate}
              slotProps={{
                day: {
                  color: "#F18F01",
                  sx: {
                    "& .MuiPickersDay-root.Mui-selected": {
                      backgroundColor: "#F18F01 !important", // Golden color
                      color: "#FFF", // White text color
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#F18F01 !important", // Consistency in other potential classes
                      color: "#FFF",
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        )}

        {!isShowPause && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              sx={{
                backgroundColor: "#FFF",
                borderRadius: "12px",
                padding: "20px",
                paddingBottom: "0px",
                "& .MuiPickersArrowSwitcher-button": {
                  backgroundColor: "transparent", // Hide circle background
                  color: "#556885", // Set arrow color if needed
                },
              }}
              dayOfWeekFormatter={(dayOfWeek, date) =>
                isArabic
                  ? dayjs(date).locale("ar").format("dddd")
                  : dayjs(date).format("dd")
              }
              value={dateToResume}
              onChange={(newValue) => setDateToResume(newValue)}
              shouldDisableDate={shouldDisableDate}
              referenceDate={referenceStartDate}
              slotProps={{
                day: {
                  color: "#F18F01",

                  sx: {
                    "&.MuiPickersDay-root.Mui-selected": {
                      backgroundColor: "#F18F01 !important",
                      color: "#FFF",
                    },
                    "&.MuiPickersDay-daySelected": {
                      backgroundColor: "#F18F01 !important",
                      color: "#FFF !important",
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        )}
      </Box>

      {isErrorState && isErrorState.detail && (
        <Alert
          severity="error"
          sx={{ margin: "0 auto", marginTop: "24px", width: "342px" }}
        >
          {isErrorState.detail || ""}
        </Alert>
      )}

      <Grid container justifyContent="center" my="24px" alignItems="center">
        <LoadingButton
          onClick={isShowPause ? handlePause : handleResume}
          fullWidth
          variant="contained"
          loading={isLoadingState}
          size="large"
          sx={(theme) => ({
            padding: "var(--1, 15px) var(--2, 0px)",
            height: "var(--7, 56px)",
            borderRadius: "var(--6, 48px)",
            backgroundColor: `${theme.palette.primary.main}`,
            fontSize: "17px",
            fontWeight: 700,
            letterSpacing: "0.2px",
            width: "342px",
          })}
        >
          {isShowPause
            ? t("PAUSE_SUBSCRIPTION_BTN")
            : t("RESUME_SUBSCRIPTION_BTN")}
        </LoadingButton>
      </Grid>
    </CustomSwipeableDrawer>
  );
}
