interface Props {
  isArabic: boolean
  restaurantName: string | undefined
  planTitle: string | undefined
  contactNumber: string | undefined
}

function getContactRestaurantLink({ isArabic, restaurantName, planTitle, contactNumber }: Props): string {
  const message = isArabic
    ? `السلام عليكم، أنا مشترك في مطعم ${restaurantName}. أحتاج إلى بعض المساعدة.`
    : `Hi, I am subscribed through ${restaurantName}. I need some help.`

  const encodedMessage = encodeURIComponent(message)
  const whatsappAPIURL = `https://wa.me/${contactNumber}/?text=${encodedMessage}`
  return whatsappAPIURL
}

export default getContactRestaurantLink
